import { del, get, post, put, postFile } from "./api_helper"
import * as url from "./url_helper"

// Auth
export const postAuth = () => post(url.POST_AUTH)
export const postAdminAuth = () => post(url.POST_ADMIN_AUTH)

// Login
export const postLogin = data => post(url.POST_LOGIN, data)
export const requestReset = data => post(url.REQUEST_RESET, data)
export const verifyResetLink = data => post(url.VERIFY_RESET_LINK, data)
export const resetPassword = data => post(url.RESET_PASSWORD, data)

// Account
export const getAccountData = () => get(url.ACCOUNT_DATA)
export const getAdminData = () => get(url.ADMIN_DATA)
export const getSettingsData = () => get(url.GET_SETTINGS_DATA)
export const getAdminSettingsData = () => get(url.GET_ADMIN_SETTINGS_DATA)
export const changePassword = data => post(url.CHANGE_PASSWORD, data)
export const updateNotifications = data => post(url.UPDATE_NOTIFICATIONS, data)
export const getBillingData = () => get(url.GET_BILLING_DATA)
export const cancelSubscription = () => get(url.CANCEL_SUBSCRIPTION)

// Customization
export const getCustomizationData = () => get(url.GET_CUSTOMIZATION)
export const postCustomizationData = data => {
  post(url.POST_CUSTOMIZATION, data)
}
export const postAddSection = data => post(url.POST_ADD_SECTION, data)
export const postSectionsData = data => post(url.POST_SECTIONS, data)
export const postAddPart = data => post(url.POST_ADD_PART, data)
export const postRemovePart = data => post(url.POST_REMOVE_PART, data)
export const postRemoveSection = data => post(url.POST_REMOVE_SECTION, data)
export const postFinishSubmission = () => post(url.POST_FINISH_SUBMISSION)
export const getTemplateDesigns = data => get(url.GET_TEMPLATE_DESIGNS, data)
export const selectTemplateDesign = data =>
  post(url.SELECT_TEMPLATE_DESIGN, data)
export const skipTemplate = () => post(url.SKIP_TEMPLATE)
export const removeTemplate = () => post(url.REMOVE_TEMPLATE)

// Tickets
export const getTicketList = data => get(url.GET_TICKET_LIST, data)
export const getAgentTicketList = data => get(url.GET_AGENT_TICKET_LIST, data)
export const getTicketDetail = data => get(url.GET_TICKET_DETAIL, data)
export const getAgentTicketDetail = data =>
  get(url.GET_AGENT_TICKET_DETAIL, data)
export const postSubmitMessage = data => post(url.POST_SUBMIT_MESSAGE, data)
export const postAgentSubmitMessage = data =>
  post(url.POST_AGENT_SUBMIT_MESSAGE, data)
export const postToggleStar = data => post(url.POST_TOGGLE_STAR, data)
export const postAgentToggleStar = data =>
  post(url.POST_AGENT_TOGGLE_STAR, data)
export const postDeleteTickets = data => post(url.POST_DELETE_TICKETS, data)
export const postAgentDeleteTickets = data =>
  post(url.POST_AGENT_DELETE_TICKETS, data)
export const getAllAccounts = () => get(url.GET_ALL_ACCOUNTS)
export const postCloseTicket = data => post(url.POST_CLOSE_TICKET, data)
export const postUpdateTicketLabel = data =>
  post(url.POST_CHANGE_TICKET_LABEL, data)
export const getUnreadCount = data => get(url.GET_UNREAD_COUNT, data)

// Admin
export const getPaymentRequestsProformaData = () =>
  get(url.GET_PAYMENT_REQUESTS_PROFORMA_DATA)
export const markProformaPaid = data => post(url.POST_MARK_PROFORMA_PAID, data)
export const createAccount = data => post(url.POST_CREATE_ACCOUNT, data)

// File Upload
export const postUploadFile = async data => {
  return postFile(url.POST_UPLOAD_FILE, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
}

export const postUploadFileWebflow = async data => {
  return postFile(url.POST_UPLOAD_FILE_WEBFLOW, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
}

// Templates
export const getTemplates = () => get(url.GET_TEMPLATES)
export const fetchTemplateData = data => get(url.GET_TEMPLATE_DATA, data)
export const updateTemplate = data => post(url.POST_UPDATE_TEMPLATE, data)
export const addTemplate = data => post(url.POST_ADD_TEMPLATE, data)
export const webflowSync = () => get(url.GET_WEBFLOW_SYNC)

// Requests
export const getRequests = () => get(url.GET_REQUESTS)
export const getRequestDetail = data => get(url.GET_REQUEST_DETAIL, data)
