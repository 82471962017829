export const GET_TICKET_LIST = "GET_TICKET_LIST"
export const GET_TICKET_LIST_SUCCESS = "GET_TICKET_LIST_SUCCESS"
export const GET_TICKET_LIST_FAILED = "GET_TICKET_LIST_FAILED"
export const CHANGE_TAB = "CHANGE_TAB"
export const CLOSE_TICKET_FORM = "CLOSE_TICKET_FORM"
export const GET_TICKET_DETAIL = "GET_TICKET_DETAIL"
export const GET_TICKET_DETAIL_SUCCESS = "GET_TICKET_DETAIL_SUCCESS"
export const GET_TICKET_DETAIL_FAILED = "GET_TICKET_DETAIL_FAILED"
export const CLOSE_TICKET_DETAIL = "CLOSE_TICKET_DETAIL"
export const OPEN_CREATE_TICKET_FORM = "OPEN_CREATE_TICKET_FORM"
export const UPLOAD_ATTACHMENT = "UPLOAD_ATTACHMENT"
export const UPLOAD_ATTACHMENT_SUCCESS = "UPLOAD_ATTACHMENT_SUCCESS"
export const UPLOAD_ATTACHMENT_FAILED = "UPLOAD_ATTACHMENT_FAILED"
export const REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT"
export const SUBMIT_MESSAGE = "SUBMIT_MESSAGE"
export const SUBMIT_MESSAGE_SUCCESS = "SUBMIT_MESSAGE_SUCCESS"
export const SUBMIT_MESSAGE_FAILED = "SUBMIT_MESSAGE_FAILED"
export const TOGGLE_STAR = "TOGGLE_STAR"
export const ADD_MESSAGE = "ADD_MESSAGE"
export const SELECT_LABEL = "SELECT_LABEL"
export const DELETE_TICKETS = "DELETE_TICKETS"
export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS"
export const GET_ALL_ACCOUNTS_SUCCESS = "GET_ALL_ACCOUNTS_SUCCESS"
export const CLOSE_TICKET = "CLOSE_TICKET"
export const CLOSE_TICKET_SUCCESS = "CLOSE_TICKET_SUCCESS"
export const CLOSE_TICKET_FAILED = "CLOSE_TICKET_FAILED"
export const CHANGE_TICKET_LABEL = "CHANGE_TICKET_LABEL"
export const GET_UNREAD_COUNT = "GET_UNREAD_COUNT"
export const GET_UNREAD_COUNT_SUCCESS = "GET_UNREAD_COUNT_SUCCESS"
