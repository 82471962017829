import {
  GET_REQUESTS,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FAILED,
  GET_REQUEST_DETAIL,
  GET_REQUEST_DETAIL_SUCCESS,
  GET_REQUEST_DETAIL_FAILED,
} from "./actionTypes"

export const getRequests = () => ({
  type: GET_REQUESTS,
})

export const getRequestsSuccess = data => ({
  type: GET_REQUESTS_SUCCESS,
  payload: data,
})

export const getRequestsFailed = () => ({
  type: GET_REQUESTS_FAILED,
})

export const getRequestDetail = id => ({
  type: GET_REQUEST_DETAIL,
  payload: { id },
})

export const getRequestDetailSuccess = data => ({
  type: GET_REQUEST_DETAIL_SUCCESS,
  payload: data,
})

export const getRequestDetailFailed = () => ({
  type: GET_REQUEST_DETAIL_FAILED,
})
