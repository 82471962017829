import React, { useEffect, useState } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
  UncontrolledTooltip,
  Badge,
  Button,
} from "reactstrap"

//Import functions
import { timeAMPM, dateShort, capitalizeFirstLetter } from "common/functions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

// Import Actions
import {
  getPaymentRequestsData,
  markProformaPaid,
  createAccount,
} from "store/actions"

//Import Images
import tickIcon from "assets/images/icons/icons8-tick.svg"
import cancelIcon from "assets/images/icons/icons8-cancel.svg"
import warningIcon from "assets/images/icons/icons8-warning.svg"

export const PaymentRequests = () => {
  const dispatch = useDispatch()

  const [markingAsPaid, setMarkingAsPaid] = useState(null)
  const [creatingAccount, setCreatingAccount] = useState(null)

  const adminData = createSelector(
    state => state.admin,
    adminData => {
      return {
        loadingPaymentRequests: adminData.loadingPaymentRequests,
        paymentRequests: adminData.paymentRequests,
        loadingMarkProformaPaid: adminData.loadingMarkProformaPaid,
        loadingCreateAccount: adminData.loadingCreateAccount,
      }
    }
  )

  const {
    loadingPaymentRequests,
    paymentRequests,
    loadingMarkProformaPaid,
    loadingCreateAccount,
  } = useSelector(adminData)

  // get Payment requests on load
  useEffect(() => {
    dispatch(getPaymentRequestsData())
  }, [])

  // refetch list on demand
  const refetchList = () => {
    dispatch(getPaymentRequestsData())
  }

  // handle mark proforma paid
  const proformaPaid = id => {
    if (loadingMarkProformaPaid) return

    setMarkingAsPaid(id)

    dispatch(markProformaPaid(id))
  }

  const onCreateAccount = id => {
    if (loadingCreateAccount) return

    setCreatingAccount(id)

    dispatch(createAccount(id))
  }

  const list = (paymentRequests || []).map(
    ({
      id,
      created_at,
      email,
      email_verified,
      plan,
      name,
      ip_address,
      country_code,
      street,
      city,
      zip,
      country,
      ico,
      dic,
      price_cz,
      proforma_number,
      idoklad_proforma_id,
      paid,
      account,
    }) => {
      const date = new Date(created_at)

      let price

      switch (plan) {
        case "starter":
          price = price_cz.starter_monthly
          break
        case "premium":
          price = price_cz.premium_monthly
          break
        case "ecommerce":
          price = price_cz.ecommerce_monthly
          break
        default:
          price = 0
      }

      let invoice_details = street
        ? street + ", " + city + ", " + zip + ", " + country
        : "-"

      let invoice_details_all =
        "Street: " +
        street +
        ", City: " +
        city +
        ", ZIP: " +
        zip +
        ", Country: " +
        country +
        ", ICO: " +
        ico +
        ", DIC: " +
        dic

      const downloadLink =
        process.env.REACT_APP_AWS_DOCUMENTS_FOLDER +
        "/" +
        "proforma-" +
        proforma_number +
        "-" +
        idoklad_proforma_id +
        ".pdf"

      return (
        <tr key={id}>
          <td>
            {dateShort(date)} - {timeAMPM(date)}
          </td>
          <td>
            {email}{" "}
            {!email_verified && (
              <img
                src={warningIcon}
                alt="Not verified"
                style={{ width: "18px" }}
              />
            )}
          </td>
          <td>
            {account?.id ? (
              account.username
            ) : (
              <Badge color="warning" className="me-1">
                No account
              </Badge>
            )}
          </td>
          <td>{name}</td>
          <td>{country_code}</td>
          <td>{capitalizeFirstLetter(plan)}</td>
          <td>{price} CZK</td>
          <td>
            <div
              id={"invoiceDetails" + id}
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {invoice_details}
              {street && (
                <UncontrolledTooltip
                  placement="top"
                  target={"invoiceDetails" + id}
                >
                  {invoice_details_all}
                </UncontrolledTooltip>
              )}
            </div>
          </td>
          <td>{proforma_number}</td>
          <td>
            {idoklad_proforma_id && (
              <a href={downloadLink} download>
                Download
              </a>
            )}
          </td>
          <td>
            <img
              src={paid ? tickIcon : cancelIcon}
              alt="Payment status"
              style={{ width: "18px" }}
            />
          </td>
          <td>
            {!paid && proforma_number && (
              <Button
                color="info"
                className="btn-sm"
                onClick={() => proformaPaid(id)}
                style={{ minWidth: "80px", padding: "2px 5px 1px" }}
              >
                {!(loadingMarkProformaPaid && id === markingAsPaid) &&
                  "Mark Paid"}
                {loadingMarkProformaPaid && id === markingAsPaid && (
                  <span
                    className="spinner-border spinner-border-sm"
                    style={{ marginBottom: "-3px" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </Button>
            )}
            {paid && !account?.id && (
              <Button
                color="success"
                className="btn-sm"
                onClick={() => onCreateAccount(id)}
                style={{ minWidth: "104px", padding: "2px 5px 1px" }}
              >
                {!(loadingCreateAccount && id === creatingAccount) &&
                  "Create Account"}
                {loadingCreateAccount && id === creatingAccount && (
                  <span
                    className="spinner-border spinner-border-sm"
                    style={{ marginBottom: "-3px" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </Button>
            )}
          </td>
        </tr>
      )
    }
  )

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="h4">Payment Requests</CardTitle>
          <div onClick={refetchList} className="icon-hover-wrap">
            <i
              className="bx bx-sync font-size-24 align-middle me-1"
              style={{ color: "#555b6d" }}
            ></i>
          </div>
        </div>
        {!loadingPaymentRequests && (
          <>
            <CardSubtitle className="card-title-desc"></CardSubtitle>
            <div className="table-responsive">
              <Table className="table mb-0 w-100 align-middle">
                <thead className="table-light">
                  <tr>
                    <th>Date</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>IP Country</th>
                    <th>Plan</th>
                    <th>Amount</th>
                    <th>Invoice Details</th>
                    <th>Proforma #</th>
                    <th>Proforma File</th>
                    <th>Paid</th>
                    <th style={{ minWidth: "130px" }}></th>
                  </tr>
                </thead>
                <tbody>{list}</tbody>
              </Table>
            </div>
          </>
        )}
        {loadingPaymentRequests && (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Spinner color="primary" />
          </div>
        )}
      </CardBody>
    </Card>
  )
}
