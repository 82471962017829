import { takeEvery, call, put } from "redux-saga/effects"

import {
  GET_ACCOUNT_DATA,
  GET_ADMIN_DATA,
  GET_SETTINGS_DATA,
  GET_ADMIN_SETTINGS_DATA,
  CHANGE_PASSWORD,
  UPDATE_NOTIFICATIONS,
  GET_BILLING_DATA,
  CANCEL_SUBSCRIPTION,
} from "./actionTypes"

import {
  getAccountData,
  getAdminData,
  getSettingsData,
  getAdminSettingsData,
  changePassword,
  updateNotifications,
  getBillingData,
  cancelSubscription,
} from "../../helpers/api_requests"

import {
  getAccountDataSuccess,
  getAccountDataFailed,
  getAdminDataSuccess,
  getAdminDataFailed,
  getSettingsDataSuccess,
  getSettingsDataFailed,
  getAdminSettingsDataSuccess,
  getAdminSettingsDataFailed,
  changePasswordFailed,
  changePasswordSuccess,
  updateNotificationsSuccess,
  updateNotificationsFailed,
  getBillingDataSuccess,
  getBillingDataFailed,
  cancelSubscriptionFailed,
  cancelSubscriptionSuccess,
} from "./actions"

function* onGetAccountData() {
  const response = yield call(getAccountData)

  if (response.error) {
    yield put(getAccountDataFailed())
  } else {
    yield put(getAccountDataSuccess(response))
  }
}

function* onGetAdminData() {
  const response = yield call(getAdminData)

  if (response.error) {
    yield put(getAdminDataFailed())
  } else {
    yield put(getAdminDataSuccess(response))
  }
}

function* onGetSettingsData() {
  try {
    const response = yield call(getSettingsData)

    if (response.error) {
      yield put(getSettingsDataFailed(response.error))
    } else {
      yield put(getSettingsDataSuccess(response.data))
    }
  } catch (err) {
    yield put(getSettingsDataFailed(err.message))
  }
}

function* onGetAdminSettingsData() {
  try {
    const response = yield call(getAdminSettingsData)

    if (response.error) {
      yield put(getAdminSettingsDataFailed(response.error))
    } else {
      yield put(getAdminSettingsDataSuccess(response.data))
    }
  } catch (err) {
    yield put(getAdminSettingsDataFailed(err.message))
  }
}

function* onChangePassword({ payload }) {
  try {
    const response = yield call(changePassword, payload)

    if (response.error) {
      yield put(changePasswordFailed(response.error))
    } else {
      yield put(changePasswordSuccess(response.data))
    }
  } catch (err) {
    yield put(changePasswordFailed(err.message))
  }
}

function* onUpdateNotifications({ payload }) {
  try {
    const response = yield call(updateNotifications, payload)

    if (response.error) {
      yield put(updateNotificationsFailed(response.error))
    } else {
      yield put(updateNotificationsSuccess())
    }
  } catch (err) {
    yield put(updateNotificationsFailed(err.message))
  }
}

function* onGetBillingData({ payload }) {
  try {
    const response = yield call(getBillingData, payload)

    if (response.error) {
      yield put(getBillingDataFailed(response.error))
    } else {
      yield put(getBillingDataSuccess(response))
    }
  } catch (err) {
    yield put(getBillingDataFailed(err.message))
  }
}

function* onCancelSubscription() {
  try {
    const response = yield call(cancelSubscription)

    if (response.error) {
      yield put(cancelSubscriptionFailed(response.error))
    } else {
      yield put(cancelSubscriptionSuccess())
    }
  } catch (err) {
    yield put(cancelSubscriptionFailed(err.message))
  }
}

function* accountSaga() {
  yield takeEvery(GET_ACCOUNT_DATA, onGetAccountData)
  yield takeEvery(GET_ADMIN_DATA, onGetAdminData)
  yield takeEvery(GET_SETTINGS_DATA, onGetSettingsData)
  yield takeEvery(GET_ADMIN_SETTINGS_DATA, onGetAdminSettingsData)
  yield takeEvery(CHANGE_PASSWORD, onChangePassword)
  yield takeEvery(UPDATE_NOTIFICATIONS, onUpdateNotifications)
  yield takeEvery(GET_BILLING_DATA, onGetBillingData)
  yield takeEvery(CANCEL_SUBSCRIPTION, onCancelSubscription)
}

export default accountSaga
