import React from "react"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

import { postAuth, postAdminAuth } from "helpers/api_requests"

export const AuthMiddleware = props => {
  const [authenticated, setAuthenticated] = useState(false)

  const navigate = useNavigate()

  const authUser = async () => {
    try {
      const authUserData = localStorage.getItem("authUser")

      if (!authUserData) {
        navigate("/login")
        return
      }

      const authResult = await postAuth()

      if (!authResult.authenticated) {
        navigate("/login")
        return
      }
    } catch (err) {
      console.log("Auth error", err)
      navigate("/login")
      return
    }

    setAuthenticated(true)
  }

  useEffect(() => {
    authUser()
  }, [])

  return authenticated ? <>{props.children}</> : <></>
}

export const AuthAdminMiddleware = props => {
  const [authenticated, setAuthenticated] = useState(false)

  const navigate = useNavigate()

  const authUser = async () => {
    try {
      const authUserData = localStorage.getItem("authUser")

      if (!authUserData) {
        console.log("!authUserData", authUserData)

        navigate("/login")
        return
      }

      const authResult = await postAdminAuth()

      if (!authResult.authenticated) {
        navigate("/login")
        return
      }
    } catch (err) {
      console.log("Auth admin error", err)
      navigate("/login")
      return
    }

    setAuthenticated(true)
  }

  useEffect(() => {
    authUser()
  }, [])

  return authenticated ? <>{props.children}</> : <></>
}
