import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPwd from "../pages/Authentication/ResetPassword"
import VerifyEmail from "../pages/Authentication/VerifyEmail"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import DashboardAdmin from "../pages/DashboardAdmin/index"

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Customization
import SelectDesign from "../pages/CustomizationForm/select-design"
import CustomizationForm from "../pages/CustomizationForm/index"

//Tickets
import Tickets from "../pages/Tickets/index"
import CreateTicket from "../pages/Tickets/create-ticket"

//Management
import Templates from "../pages/Templates/index"
import EditTemplate from "../pages/Templates/edit-template"
import AddTemplate from "../pages/Templates/add-template"

//Settings
import Settings from "../pages/Settings/index"
import Billing from "../pages/Settings/billing"

//Requests
import Requests from "../pages/Requests/index"

//Other
import DocumentDownload from "../pages/Utility/document-download"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },

  //Customization
  { path: "/select-design", component: <SelectDesign /> },
  { path: "/submit-data", component: <CustomizationForm /> },

  //Tickets
  { path: "/tickets", component: <Tickets /> },
  { path: "/create-ticket", component: <CreateTicket /> },

  //Settings
  { path: "/settings", component: <Settings /> },
  { path: "/billing", component: <Billing /> },

  //Requets
  { path: "/requests", component: <Requests /> },
]

const authAdminProtectedRoutes = [
  { path: "/dashboard-admin", component: <DashboardAdmin /> },

  //Tickets
  { path: "/tickets-admin", component: <Tickets /> },

  //Settings
  { path: "/settings-admin", component: <Settings /> },

  //Management
  { path: "/templates", component: <Templates /> },
  { path: "/edit-template", component: <EditTemplate /> },
  { path: "/add-template", component: <AddTemplate /> },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/reset-password", component: <ResetPwd /> },
  { path: "/verify-email", component: <VerifyEmail /> },
  { path: "/document", component: <DocumentDownload /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]

export { authProtectedRoutes, authAdminProtectedRoutes, publicRoutes }
