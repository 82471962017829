import {
  GET_ACCOUNT_DATA,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAILED,
  GET_ADMIN_DATA,
  GET_ADMIN_DATA_SUCCESS,
  GET_ADMIN_DATA_FAILED,
  GET_SETTINGS_DATA,
  GET_SETTINGS_DATA_SUCCESS,
  GET_SETTINGS_DATA_FAILED,
  GET_ADMIN_SETTINGS_DATA,
  GET_ADMIN_SETTINGS_DATA_SUCCESS,
  GET_ADMIN_SETTINGS_DATA_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAILED,
  GET_BILLING_DATA,
  GET_BILLING_DATA_SUCCESS,
  GET_BILLING_DATA_FAILED,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  UPDATE_ACCOUNT_DATA_AFTER_SKIP,
} from "./actionTypes"

export const getAccountData = () => ({
  type: GET_ACCOUNT_DATA,
})

export const getAccountDataSuccess = data => ({
  type: GET_ACCOUNT_DATA_SUCCESS,
  payload: data,
})

export const getAccountDataFailed = data => ({
  type: GET_ACCOUNT_DATA_FAILED,
})

export const getAdminData = admin => ({
  type: GET_ADMIN_DATA,
  payload: { admin },
})

export const getAdminDataSuccess = data => ({
  type: GET_ADMIN_DATA_SUCCESS,
  payload: data,
})

export const getAdminDataFailed = data => ({
  type: GET_ADMIN_DATA_FAILED,
})

export const getSettingsData = () => ({
  type: GET_SETTINGS_DATA,
})

export const getSettingsDataSuccess = data => ({
  type: GET_SETTINGS_DATA_SUCCESS,
  payload: data,
})

export const getSettingsDataFailed = () => ({
  type: GET_SETTINGS_DATA_FAILED,
})

export const getAdminSettingsData = () => ({
  type: GET_ADMIN_SETTINGS_DATA,
})

export const getAdminSettingsDataSuccess = data => ({
  type: GET_ADMIN_SETTINGS_DATA_SUCCESS,
  payload: data,
})

export const getAdminSettingsDataFailed = () => ({
  type: GET_ADMIN_SETTINGS_DATA_FAILED,
})

export const changePassword = data => ({
  type: CHANGE_PASSWORD,
  payload: data,
})

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
})

export const changePasswordFailed = data => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: data,
})

export const updateNotifications = data => ({
  type: UPDATE_NOTIFICATIONS,
  payload: data,
})

export const updateNotificationsSuccess = () => ({
  type: UPDATE_NOTIFICATIONS_SUCCESS,
})

export const updateNotificationsFailed = () => ({
  type: UPDATE_NOTIFICATIONS_FAILED,
})

export const getBillingData = () => ({
  type: GET_BILLING_DATA,
})

export const getBillingDataSuccess = data => ({
  type: GET_BILLING_DATA_SUCCESS,
  payload: data,
})

export const getBillingDataFailed = () => ({
  type: GET_BILLING_DATA_FAILED,
})

export const cancelSubscription = () => ({
  type: CANCEL_SUBSCRIPTION,
})

export const cancelSubscriptionFailed = () => ({
  type: CANCEL_SUBSCRIPTION_FAILED,
})

export const cancelSubscriptionSuccess = () => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
})

export const updateAccountDataAfterSkip = () => ({
  type: UPDATE_ACCOUNT_DATA_AFTER_SKIP,
})
