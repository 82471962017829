import React, { useEffect } from "react"
import axios from "axios"

//Import Images
import loadingIcon from "../../assets/images/icons8-loading.gif"

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const VerifyEmail = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const x = urlParams.get("x")
    const y = urlParams.get("y")

    const postVerify = async function post() {
      return axiosApi
        .post("verify-email-cz-proforma", { x, y })
        .then(response => {
          console.log("response.data", response.data)

          if (response.data.result === "Verified") {
            window.location.href =
              process.env.REACT_APP_FRONT_END_DOMAIN +
              "/objednavka?emailVerified=t"
          } else {
            window.location.href =
              process.env.REACT_APP_FRONT_END_DOMAIN +
              "/objednavka?emailVerified=f"
          }
        })
    }

    postVerify()
  }, [])

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img alt="Loading" src={loadingIcon} width={36} />
    </div>
  )
}

export default VerifyEmail
