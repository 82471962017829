import React from "react"
import styled from "styled-components"

const InputWrap = styled.div`
  margin: ${props => (props.margin ? props.margin : "0")};
  padding: ${props => (props.padding ? props.padding : "0")};
  width: ${props => props.width};
  max-width: ${props => (props.maxWidth ? props.maxWidth : "auto")};

  display: flex;
  align-items: center;
`

const Input = styled.input`
  padding: 0px 8px 0px 12px;
  background: rgba(255, 255, 255, 0.14);

  width: calc(100% - 38px);
  height: 38px;

  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #495057;

  border-top: 1px solid #ced4da;
  border-right: 0;
  border-bottom: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-radius: 4px 0 0 4px;

  text-align: left;
  box-sizing: border-box;
`

const InputIcon = styled.div`
  padding: 9px 8px 9px 12px;
  background: rgba(255, 255, 255, 0.14);
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-left: 0;
  border-radius: 0 4px 4px 0;

  &:hover {
    cursor: pointer;
  }
`

const Icon = styled.div`
  width: 18px;
  height: 18px;
`

export default function InputSearch(props) {
  const {
    type,
    name,
    value,
    onChange,
    placeholder,
    width,
    maxWidth,
    background,
    margin,
    warning,
    maxLength,
    onKeyDown,
    textAlign,
    iconSize,
    onIconClick,
    autoFocus,
    padding,
    readOnly,
    icon,
  } = props

  return (
    <InputWrap
      width={width}
      maxWidth={maxWidth}
      margin={margin}
      padding={padding}
    >
      <Input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus ? 1 : 0}
        readOnly={readOnly ? 1 : 0}
      />

      <InputIcon onClick={() => onIconClick()}>
        <Icon>
          <img src={icon} width={iconSize ? iconSize : "100%"} alt="Search" />{" "}
        </Icon>
      </InputIcon>
    </InputWrap>
  )
}
