import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

// Import file extension images
import otherFile from "../assets/images/file-extensions/icons8-file.svg"
import jpg from "../assets/images/file-extensions/icons8-jpg-file-64.png"
import png from "../assets/images/file-extensions/icons8-png-file-64.png"
import pdf from "../assets/images/file-extensions/icons8-pdf-file-64.png"

export const timeAMPM = date => {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  var strTime = hours + ":" + minutes + " " + ampm
  return strTime
}

export const time24 = date => {
  var hours = date.getHours()
  var minutes = date.getMinutes()

  return hours + ":" + minutes
}

export const dateShort = date => {
  const lang = localStorage.getItem("I18N_LANGUAGE") || "en"

  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  if (lang === "cz") {
    monthNames = [
      "Led",
      "Úno",
      "Bře",
      "Dub",
      "Kvě",
      "Čvn",
      "Čvc",
      "Srp",
      "Zář",
      "Říj",
      "Lis",
      "Pro",
    ]
  }

  return monthNames[date.getMonth()] + " " + date.getDate()
}

export const dateLong = (date, incYear = false) => {
  const lang = localStorage.getItem("I18N_LANGUAGE") || "en"

  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "Dececember",
  ]

  if (lang === "cz") {
    monthNames = [
      "Leden",
      "Únor",
      "Březen",
      "Duben",
      "Květen",
      "Červen",
      "Červenec",
      "Srped",
      "Září",
      "Říjen",
      "Listopad",
      "Prosinec",
    ]
  }

  let dateText = monthNames[date.getMonth()] + " " + date.getDate()

  if (incYear) dateText = dateText + " " + date.getFullYear()

  return dateText
}

export const fileTypeIcon = file => {
  const extension = file.split(".")[1]

  switch (extension) {
    case "jpg" || "jpeg":
      return jpg
    case "png":
      return png
    case "pdf":
      return pdf
    default:
      return otherFile
  }
}

function isObject(object) {
  return object != null && typeof object === "object"
}

export const compareObjects = (object1, object2) => {
  if (!object1 || !object2) return false

  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !compareObjects(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }

  return true
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
