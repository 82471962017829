import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { REQUEST_RESET, VERIFY_RESET_LINK, RESET_PASSWORD } from "./actionTypes"

import {
  requestReset,
  verifyResetLink,
  resetPassword,
} from "../../../helpers/api_requests"

import {
  requestResetSuccess,
  requestResetFailed,
  verifyRequestLinkFailed,
  verifyRequestLinkSuccess,
  resetPasswordSuccess,
  resetPasswordFailed,
} from "./actions"

function* onRequestReset({ payload }) {
  try {
    const response = yield call(requestReset, { email: payload.user.email })

    if (response.error) {
      yield put(requestResetFailed(response.error))
    } else {
      yield put(requestResetSuccess())
    }
  } catch (err) {
    yield put(requestResetFailed(err.message))
  }
}

function* onVerifyResetLink({ payload }) {
  try {
    const response = yield call(verifyResetLink, { x: payload.x, y: payload.y })

    if (response.error) {
      yield put(verifyRequestLinkFailed(response.error))
    } else {
      yield put(verifyRequestLinkSuccess())
    }
  } catch (err) {
    yield put(verifyRequestLinkFailed(err.message))
  }
}

function* onResetPassword({ payload }) {
  try {
    const response = yield call(resetPassword, {
      password: payload.password,
      x: payload.codes.x,
      y: payload.codes.y,
    })

    if (response.error) {
      yield put(resetPasswordFailed(response.error))
    } else {
      yield put(resetPasswordSuccess())
    }
  } catch (err) {
    yield put(resetPasswordFailed(err.message))
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(REQUEST_RESET, onRequestReset)
  yield takeEvery(VERIFY_RESET_LINK, onVerifyResetLink)
  yield takeEvery(RESET_PASSWORD, onResetPassword)
}

export default forgetPasswordSaga
