import { takeEvery, call, put } from "redux-saga/effects"

import {
  GET_CUSTOMIZATION_DATA,
  SAVE_STEP_ONE_DATA,
  SAVE_STEP_TWO_DATA,
  SAVE_STEP_FOUR_DATA,
  UPLOAD_LOGO,
  UPLOAD_HOME_IMAGE,
  ADD_SECTION,
  SAVE_SECTIONS_DATA,
  UPLOAD_PART_IMAGE,
  ADD_PART,
  REMOVE_PART,
  REMOVE_SECTION,
  FINISH_SUBMISSION,
  GET_TEMPLATE_DESIGNS,
  SELECT_TEMPLATE_DESIGN,
  SKIP_TEMPLATE,
  REMOVE_TEMPLATE,
} from "./actionTypes"

import {
  getCustomizationData,
  postCustomizationData,
  postUploadFile,
  postAddSection,
  postSectionsData,
  postAddPart,
  postRemovePart,
  postRemoveSection,
  postFinishSubmission,
  getTemplateDesigns,
  selectTemplateDesign,
  skipTemplate,
  removeTemplate,
} from "../../helpers/api_requests"

import {
  getCustomizationDataSuccess,
  getCustomizationDataFailed,
  saveCustomizationDataSuccess,
  uploadLogoFailed,
  uploadLogoSuccess,
  uploadHomeImageSuccess,
  uploadHomeImageFailed,
  addSectionFailed,
  addSectionSuccess,
  uploadPartImageFailed,
  uploadPartImageSuccess,
  addPartSuccess,
  addPartFailed,
  finishSubmissionSuccess,
  getTemplateDesignsFailed,
  getTemplateDesignsSuccess,
  selectTemplateDesignSuccess,
  selectTemplateDesignFailed,
  skipTemplateSuccess,
  skipTemplateFailed,
  removeTemplateSuccess,
  removeTemplateFailed,
} from "./actions"
import { getAccountData } from "store/actions"

function* onGetCustomizationData() {
  const response = yield call(getCustomizationData)
  if (response.error) {
    yield put(getCustomizationDataFailed())
  } else {
    yield put(getCustomizationDataSuccess(response))
  }
}

function* onSaveStepOneData({
  payload: {
    companyName,
    companyDescription,
    domain,
    oldWebsite,
    stepOneComments,
  },
}) {
  const response = yield call(postCustomizationData, {
    step: 1,
    companyName,
    companyDescription,
    domain,
    oldWebsite,
    stepOneComments,
  })
  yield put(saveCustomizationDataSuccess())
}

function* onSaveStepTwoData({
  payload: { logo, title, subTitle, mainText, homeImages, comments },
}) {
  const response = yield call(postCustomizationData, {
    step: 2,
    logo,
    title,
    subTitle,
    mainText,
    homeImages,
    comments,
  })
  yield put(saveCustomizationDataSuccess())
}

function* onSaveStepFourData({ payload: { likedWebsites, finalComments } }) {
  const response = yield call(postCustomizationData, {
    step: 4,
    likedWebsites,
    finalComments,
  })
  yield put(saveCustomizationDataSuccess())
}

function* onUploadLogo({ payload }) {
  const response = yield call(postUploadFile, payload)

  if (response.error) {
    yield put(uploadLogoFailed(response.error))
  } else if (response.fileName) {
    yield put(uploadLogoSuccess(response.fileName))
  }
}

function* onUploadHomeImage({ payload }) {
  const response = yield call(postUploadFile, payload)

  if (response.error) {
    yield put(uploadHomeImageFailed(response.error))
  } else if (response.fileName) {
    yield put(uploadHomeImageSuccess(response.fileName))
  }
}

function* onAddSection({ payload }) {
  const response = yield call(postAddSection, { section: payload })

  if (response.error) {
    yield put(addSectionFailed(response.error))
  } else if (response.sectionId) {
    yield put(addSectionSuccess(response))
  }
}

function* onSaveSections({ payload }) {
  const response = yield call(postSectionsData, { sections: payload })
}

function* onUploadPartImage({ payload: { file, sectionId, partId } }) {
  const response = yield call(postUploadFile, file)

  if (response.error) {
    yield put(uploadPartImageFailed(response.error, partId))
  } else if (response.fileName) {
    yield put(uploadPartImageSuccess(response.fileName, sectionId, partId))
  }
}

function* onAddPart({ payload: { sectionId } }) {
  const response = yield call(postAddPart, { sectionId })

  if (response.partId) {
    yield put(addPartSuccess(response.partId, sectionId))
  } else {
    yield put(addPartFailed())
  }
}

function* onRemovePart({ payload: { partId } }) {
  const response = yield call(postRemovePart, { partId })
}

function* onRemoveSection({ payload: { sectionId } }) {
  const response = yield call(postRemoveSection, { sectionId })
}

function* onFinishSubmission() {
  yield call(postFinishSubmission)

  yield put(finishSubmissionSuccess())
}

function* onGetTemplateDesigns({ payload: { category, search, offset } }) {
  try {
    const response = yield call(getTemplateDesigns, {
      category,
      search,
      offset,
    })

    if (response.error) {
      yield put(getTemplateDesignsFailed(response.error))
    } else {
      yield put(getTemplateDesignsSuccess(response))
    }
  } catch (err) {
    yield put(getTemplateDesignsFailed(err.message))
  }
}

function* onSelectTemplateDesign({ payload }) {
  try {
    const response = yield call(selectTemplateDesign, { payload })

    if (response.error) {
      yield put(selectTemplateDesignFailed(response.error))
    } else {
      yield put(selectTemplateDesignSuccess(response.data))
      yield put(getAccountData())
    }
  } catch (err) {
    yield put(selectTemplateDesignFailed(err.message))
  }
}

function* onSkipTemplate() {
  try {
    const response = yield call(skipTemplate)

    if (response.error) {
      yield put(skipTemplateFailed(response.error))
    } else {
      yield put(skipTemplateSuccess())
    }
  } catch (err) {
    yield put(skipTemplateFailed(err.message))
  }
}

function* onRemoveTemplate() {
  try {
    const response = yield call(removeTemplate)

    if (response.error) {
      yield put(removeTemplateFailed(response.error))
    } else {
      yield put(removeTemplateSuccess())
    }
  } catch (err) {
    yield put(removeTemplateFailed(err.message))
  }
}

function* customizationSaga() {
  yield takeEvery(GET_CUSTOMIZATION_DATA, onGetCustomizationData)
  yield takeEvery(SAVE_STEP_ONE_DATA, onSaveStepOneData)
  yield takeEvery(SAVE_STEP_TWO_DATA, onSaveStepTwoData)
  yield takeEvery(SAVE_STEP_FOUR_DATA, onSaveStepFourData)
  yield takeEvery(UPLOAD_LOGO, onUploadLogo)
  yield takeEvery(UPLOAD_HOME_IMAGE, onUploadHomeImage)
  yield takeEvery(ADD_SECTION, onAddSection)
  yield takeEvery(SAVE_SECTIONS_DATA, onSaveSections)
  yield takeEvery(UPLOAD_PART_IMAGE, onUploadPartImage)
  yield takeEvery(ADD_PART, onAddPart)
  yield takeEvery(REMOVE_PART, onRemovePart)
  yield takeEvery(REMOVE_SECTION, onRemoveSection)
  yield takeEvery(FINISH_SUBMISSION, onFinishSubmission)
  yield takeEvery(GET_TEMPLATE_DESIGNS, onGetTemplateDesigns)
  yield takeEvery(SELECT_TEMPLATE_DESIGN, onSelectTemplateDesign)
  yield takeEvery(SKIP_TEMPLATE, onSkipTemplate)
  yield takeEvery(REMOVE_TEMPLATE, onRemoveTemplate)
}

export default customizationSaga
