import React from "react"
import { Row, Col, Card } from "reactstrap"
import { Link } from "react-router-dom"

//Import Images
import cardImg from "../../assets/images/icons/icons8-laptop-metrics.svg"

//i18n
import { withTranslation } from "react-i18next"

const CardWelcome = ({ projectStatus, unreadMessages, t }) => {
  let status

  switch (projectStatus) {
    case "waiting":
      status = t("Pending - Select design template")
      break
    case "new":
      status = t("Pending - Submit project data")
      break
    case "building":
      status = t("Website is in development")
      break
    case "finished":
      status = t("Website is ready")
      break
    default:
      status = ""
  }

  return (
    <React.Fragment>
      <Card
        className="bg-primary-subtle"
        style={{ height: "calc(100% - 24px)" }}
      >
        <div className="h-100">
          <Row className="flex-column flex-sm-row justify-content-between h-100">
            <Col xs="12" sm="7">
              <div className="text-primary" style={{ padding: "20px" }}>
                <h5 className="text-primary">{t("Welcome Back")}</h5>
                <p>{t("Certified Pixel Back Office")}</p>

                <ul className="ps-3 mb-0">
                  <li className="py-1">{status}</li>
                  <Link to="/tickets">
                    <li className="py-1">
                      {unreadMessages} {t("unread messages")}
                    </li>
                  </Link>
                </ul>
              </div>
            </Col>
            <Col
              xs="12"
              sm="5"
              className="d-none d-sm-flex align-self-center justify-content-end"
              style={{ maxWidth: "230px", padding: "0 22px" }}
            >
              <img
                src={cardImg}
                alt=""
                className="img-fluid"
                style={{ maxHeight: "140px" }}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default withTranslation("dashboard")(CardWelcome)
