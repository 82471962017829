export const GET_CUSTOMIZATION_DATA = "GET_CUSTOMIZATION_DATA"
export const GET_CUSTOMIZATION_DATA_SUCCESS = "GET_CUSTOMIZATION_DATA_SUCCESS"
export const GET_CUSTOMIZATION_DATA_FAILED = "GET_CUSTOMIZATION_DATA_FAILED"
export const SAVE_STEP_ONE_DATA = "SAVE_STEP_ONE_DATA"
export const SAVE_STEP_TWO_DATA = "SAVE_STEP_TWO_DATA"
export const SAVE_STEP_FOUR_DATA = "SAVE_STEP_FOUR_DATA"
export const SAVE_CUSTOMIZATION_DATA_SUCCESS = "SAVE_CUSTOMIZATION_DATA_SUCCESS"
export const UPLOAD_LOGO = "UPLOAD_LOGO"
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS"
export const UPLOAD_LOGO_FAILED = "UPLOAD_LOGO_FAILED"
export const REMOVE_LOGO = "REMOVE_LOGO"
export const DISMISS_LOGO_ERROR = "DISMISS_LOGO_ERROR"
export const UPLOAD_HOME_IMAGE = "UPLOAD_HOME_IMAGE"
export const UPLOAD_HOME_IMAGE_SUCCESS = "UPLOAD_HOME_IMAGE_SUCCESS"
export const UPLOAD_HOME_IMAGE_FAILED = "UPLOAD_HOME_IMAGE_FAILED"
export const DISMISS_HOME_IMAGE_ERROR = "DISMISS_HOME_IMAGE_ERROR"
export const REMOVE_HOME_IMAGE = "REMOVE_HOME_IMAGE"
export const PART_TEXT_CHANGE = "PART_TEXT_CHANGE"
export const SECTION_COMMENTS_CHANGE = "SECTION_COMMENTS_CHANGE"
export const ADD_SECTION = "ADD_SECTION"
export const ADD_SECTION_FAILED = "ADD_SECTION_FAILED"
export const ADD_SECTION_SUCCESS = "ADD_SECTION_SUCCESS"
export const SAVE_SECTIONS_DATA = "SAVE_SECTIONS_DATA"
export const UPLOAD_PART_IMAGE = "UPLOAD_PART_IMAGE"
export const UPLOAD_PART_IMAGE_SUCCESS = "UPLOAD_PART_SUCCESS"
export const UPLOAD_PART_IMAGE_FAILED = "UPLOAD_PART_FAILED"
export const REMOVE_PART_IMAGE = "REMOVE_PART_IMAGE"
export const ADD_PART = "ADD_PART"
export const ADD_PART_SUCCESS = "ADD_PART_SUCCESS"
export const ADD_PART_FAILED = "ADD_PART_FAILED"
export const REMOVE_PART = "REMOVE_PART"
export const REMOVE_SECTION = "REMOVE_SECTION"
export const STEP_ONE_CHANGE = "STEP_ONE_CHANGE"
export const STEP_TWO_CHANGE = "STEP_TWO_CHANGE"
export const STEP_FOUR_CHANGE = "STEP_FOUR_CHANGE"
export const FINISH_SUBMISSION = "FINISH_SUBMISSION"
export const FINISH_SUBMISSION_SUCCESS = "FINISH_SUBMISSION_SUCCESS"
export const GET_TEMPLATE_DESIGNS = "GET_TEMPLATE_DESIGNS"
export const GET_TEMPLATE_DESIGNS_SUCCESS = "GET_TEMPLATE_DESIGNS_SUCCESS"
export const GET_TEMPLATE_DESIGNS_FAILED = "GET_TEMPLATE_DESIGNS_FAILED"
export const SELECT_TEMPLATE_DESIGN = "SELECT_TEMPLATE_DESIGN"
export const SELECT_TEMPLATE_DESIGN_SUCCESS = "SELECT_TEMPLATE_DESIGN_SUCCESS"
export const SELECT_TEMPLATE_DESIGN_FAILED = "SELECT_TEMPLATE_DESIGN_FAILED"
export const SKIP_TEMPLATE = "SKIP_TEMPLATE"
export const SKIP_TEMPLATE_SUCCESS = "SKIP_TEMPLATE_SUCCESS"
export const SKIP_TEMPLATE_FAILED = "SKIP_TEMPLATE_FAILED"
export const RESET_SKIPPED_TEMPLATE = "RESET_SKIPPED_TEMPLATE"
export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE"
export const REMOVE_TEMPLATE_SUCCESS = "REMOVE_TEMPLATE_SUCCESS"
export const REMOVE_TEMPLATE_FAILED = "REMOVE_TEMPLATE_FAILED"
