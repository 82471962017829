import {
  GET_TICKET_LIST,
  GET_TICKET_LIST_FAILED,
  GET_TICKET_LIST_SUCCESS,
  CHANGE_TAB,
  GET_TICKET_DETAIL,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_DETAIL_FAILED,
  CLOSE_TICKET_DETAIL,
  OPEN_CREATE_TICKET_FORM,
  UPLOAD_ATTACHMENT,
  UPLOAD_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_FAILED,
  REMOVE_ATTACHMENT,
  SUBMIT_MESSAGE,
  SUBMIT_MESSAGE_SUCCESS,
  SUBMIT_MESSAGE_FAILED,
  TOGGLE_STAR,
  ADD_MESSAGE,
  SELECT_LABEL,
  DELETE_TICKETS,
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_SUCCESS,
  CLOSE_TICKET,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_FAILED,
  CHANGE_TICKET_LABEL,
  CLOSE_TICKET_FORM,
  GET_UNREAD_COUNT,
  GET_UNREAD_COUNT_SUCCESS,
} from "./actionTypes"

export const fetchTicketList = (tab, label) => ({
  type: GET_TICKET_LIST,
  payload: { tab, label },
})

export const getTicketListSuccess = data => ({
  type: GET_TICKET_LIST_SUCCESS,
  payload: data,
})

export const getTicketListFailed = () => ({
  type: GET_TICKET_LIST_FAILED,
})

export const changeTab = tab => ({
  type: CHANGE_TAB,
  payload: tab,
})

export const closeTicketForm = () => ({
  type: CLOSE_TICKET_FORM,
})

export const getTicketDetail = id => ({
  type: GET_TICKET_DETAIL,
  payload: { id },
})

export const getTicketDetailSuccess = data => ({
  type: GET_TICKET_DETAIL_SUCCESS,
  payload: data,
})

export const getTicketDetailFailed = () => ({
  type: GET_TICKET_DETAIL_FAILED,
})

export const closeTicketDetail = () => ({
  type: CLOSE_TICKET_DETAIL,
})

export const openCreateTicketForm = () => ({
  type: OPEN_CREATE_TICKET_FORM,
})

export const uploadAttachment = file => {
  return {
    type: UPLOAD_ATTACHMENT,
    payload: { file },
  }
}

export const uploadAttachmentSuccess = (fileName, originalName) => ({
  type: UPLOAD_ATTACHMENT_SUCCESS,
  payload: { fileName, originalName },
})

export const uploadAttachmentFailed = error => ({
  type: UPLOAD_ATTACHMENT_FAILED,
  payload: { error },
})

export const removeAttachment = attachment => ({
  type: REMOVE_ATTACHMENT,
  payload: attachment,
})

export const submitMessage = (
  account,
  type,
  subject,
  message,
  attachments,
  ticketId,
  close = false
) => ({
  type: SUBMIT_MESSAGE,
  payload: { account, type, subject, message, attachments, ticketId, close },
})

export const submitMessageSuccess = close => ({
  type: SUBMIT_MESSAGE_SUCCESS,
  payload: { close },
})

export const submitMessageFailed = error => ({
  type: SUBMIT_MESSAGE_FAILED,
  payload: { error },
})

export const toggleStar = (id, star, agent) => ({
  type: TOGGLE_STAR,
  payload: { id, star, agent },
})

export const addMessage = (ticketId, message) => ({
  type: ADD_MESSAGE,
  payload: { ticketId, message },
})

export const selectLabel = label => ({
  type: SELECT_LABEL,
  payload: label,
})

export const deleteTickets = tickets => ({
  type: DELETE_TICKETS,
  payload: tickets,
})

export const getAllAccounts = () => ({
  type: GET_ALL_ACCOUNTS,
})

export const getAllAccountsSuccess = accounts => ({
  type: GET_ALL_ACCOUNTS_SUCCESS,
  payload: accounts,
})

export const closeTicket = ticketId => ({
  type: CLOSE_TICKET,
  payload: ticketId,
})

export const closeTicketSuccess = () => ({
  type: CLOSE_TICKET_SUCCESS,
})

export const closeTicketFailed = () => ({
  type: CLOSE_TICKET_FAILED,
})

export const changeTicketLabel = (ticketId, label) => ({
  type: CHANGE_TICKET_LABEL,
  payload: { ticketId, label },
})

export const getUnreadCount = () => ({
  type: GET_UNREAD_COUNT,
})

export const getUnreadCountSuccess = data => ({
  type: GET_UNREAD_COUNT_SUCCESS,
  payload: data,
})
