export const GET_PAYMENT_REQUESTS_DATA = "GET_PAYMENT_REQUESTS_DATA"
export const GET_PAYMENT_REQUESTS_DATA_SUCCESS =
  "GET_PAYMENT_REQUESTS_DATA_SUCCESS"
export const GET_PAYMENT_REQUESTS_DATA_FAILED =
  "GET_PAYMENT_REQUESTS_DATA_FAILED"
export const MARK_PROFORMA_PAID = "MARK_PROFORMA_PAID"
export const MARK_PROFORMA_PAID_SUCCESS = "MARK_PROFORMA_PAID_SUCCESS"
export const MARK_PROFORMA_PAID_FAILED = "MARK_PROFORMA_PAID_FAILED"
export const CREATE_ACCOUNT = "CREATE_ACCOUNT"
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS"
export const CREATE_ACCOUNT_FAILED = "CREATE_ACCOUNT_FAILED"
