import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { logoutUser } from "../../store/actions"

//redux
import { useDispatch } from "react-redux"

const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutUser())
  }, [dispatch, history])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
