import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardTitle,
  Spinner,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
//import { useFormik } from "formik"
import { Formik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// actions
import {
  getSettingsData,
  getAdminSettingsData,
  changePassword,
  updateNotifications,
} from "../../store/actions"

//i18n
import { withTranslation } from "react-i18next"

const Settings = ({ t }) => {
  //meta title
  document.title = t("Title Settings")

  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const { admin } = authUser
  console.log({ authUser, admin })

  const dispatch = useDispatch()

  const settings = createSelector(
    state => state.account,
    settingsData => {
      return {
        settingsDataLoading: settingsData.settingsDataLoading,
        settingsData: settingsData.settingsData,
        adminSettingsDataLoading: settingsData.adminSettingsDataLoading,
        adminSettingsData: settingsData.adminSettingsData,
        changePasswordLoading: settingsData.changePasswordLoading,
        changePasswordError: settingsData.changePasswordError,
        changePasswordSuccess: settingsData.changePasswordSuccess,
        updateNotificationsLoading: settingsData.updateNotificationsLoading,
        updateNotificationsError: settingsData.updateNotificationsError,
        updateNotificationsSuccess: settingsData.updateNotificationsSuccess,
      }
    }
  )

  const {
    settingsDataLoading,
    settingsData,
    adminSettingsDataLoading,
    adminSettingsData,
    changePasswordLoading,
    changePasswordError,
    changePasswordSuccess,
    updateNotificationsLoading,
    updateNotificationsError,
    updateNotificationsSuccess,
  } = useSelector(settings)

  const [passwordFormOpened, setPasswordFormOpened] = useState(false)
  const [passwordAlertVisible, setPasswordAlertVisible] = useState(true)
  const [notificationsAlertVisible, setNotificationsAlertVisible] =
    useState(true)
  const [notifications, setNotifications] = useState({
    messages: false,
    newsletter: false,
  })

  const getSettingsDataAction = admin ? getAdminSettingsData : getSettingsData
  const loading = admin ? adminSettingsDataLoading : settingsDataLoading
  const data = admin ? adminSettingsData : settingsData

  // load settings data
  useEffect(() => {
    dispatch(getSettingsDataAction())
  }, [])

  // populate notifications form state
  useEffect(() => {
    if (data)
      setNotifications({
        messages: data.notifications_messages,
        newsletter: data.notifications_newsletter,
      })
  }, [data])

  // close password form on success change
  useEffect(() => {
    if (changePasswordSuccess) setPasswordFormOpened(false)
  }, [changePasswordSuccess])

  // handle Notifications settings
  const onNotificationsChange = e => {
    if (updateNotificationsLoading) return

    setNotifications({
      ...notifications,
      [e.target.name]: !notifications[e.target.name],
    })
  }

  const onNotificationsUpdate = () => {
    if (updateNotificationsLoading) return

    setNotificationsAlertVisible(true)
    dispatch(updateNotifications(notifications))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("Dashboard")}
            titleLink="/dashboard"
            breadcrumbItems={[{ link: "", item: t("Settings") }]}
          />
          <Card>
            <CardBody>
              <CardTitle className="h4 pb-4">
                {t("Account information")}
              </CardTitle>
              {loading && (
                <div
                  className="w-100 d-flex justify-content-center align-items-center"
                  style={{ height: "400px" }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              )}
              {!loading && (
                <>
                  <Row className="mb-1">
                    <label className="col-md-2 col-form-label">
                      {t("Email address")}
                    </label>
                    <div className="col-md-10 text-instead-input">
                      {data.email}
                    </div>
                  </Row>
                  <Row className="mb-1">
                    <label className="col-md-2 col-form-label">
                      {t("Username")}
                    </label>
                    <div className="col-md-10 text-instead-input">
                      {data.username}
                    </div>
                  </Row>
                  <Row className="mb-1">
                    <label className="col-md-2 col-form-label">
                      {t("Password")}
                    </label>
                    <div className="col-md-10 d-flex align-items-center">
                      <div className="text-instead-input">********</div>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light ms-3 btn-sm"
                        onClick={() =>
                          setPasswordFormOpened(!passwordFormOpened)
                        }
                      >
                        {passwordFormOpened ? t("Close") : t("Change Password")}
                      </Button>
                    </div>
                  </Row>
                  {passwordFormOpened && (
                    <Formik
                      initialValues={{
                        oldPassword: "",
                        newPassword: "",
                        confirmNewPassword: "",
                      }}
                      validationSchema={Yup.object({
                        oldPassword: Yup.string().required(
                          t("Please enter your current password")
                        ),
                        newPassword: Yup.string()
                          .required(t("Please enter new password"))
                          .min(
                            8,
                            t("Password must be at least 8 characters long")
                          )
                          .matches(
                            /[a-z]+/,
                            t("Password must include at least one lowercase")
                          )
                          .matches(
                            /[A-Z]+/,
                            t("Password must include at least one uppercase")
                          )
                          .matches(
                            /^\S*$/,
                            t("Whitespace in password is not allowed")
                          )
                          .matches(
                            /[0123456789@$!%*#?&_-]+/,
                            t("Password must include at least")
                          ),
                        confirmNewPassword: Yup.string()
                          .required(t("Please enter the new password again"))
                          .oneOf(
                            [Yup.ref("newPassword"), null],
                            t("Passwords do not match")
                          ),
                      })}
                      onSubmit={(values, actions) => {
                        setPasswordAlertVisible(true)
                        dispatch(changePassword(values))
                      }}
                    >
                      {props => (
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            props.handleSubmit()
                            return false
                          }}
                        >
                          <Row className="mb-3 mt-4">
                            <Label
                              htmlFor="oldPassword"
                              className="col-md-2 col-form-label"
                            >
                              {t("Current password")}
                            </Label>
                            <div className="col-md-10">
                              <Input
                                type="password"
                                name="oldPassword"
                                id="oldPassword"
                                value={props.values.oldPassword}
                                onChange={props.handleChange}
                                style={{ maxWidth: "300px" }}
                                invalid={
                                  props.touched.oldPassword &&
                                  props.errors.oldPassword
                                    ? true
                                    : false
                                }
                              />
                              {props.touched.oldPassword &&
                              props.errors.oldPassword ? (
                                <FormFeedback type="invalid">
                                  {props.errors.oldPassword}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="newPassword"
                              className="col-md-2 col-form-label"
                            >
                              {t("New password")}
                            </label>
                            <div className="col-md-10">
                              <Input
                                type="password"
                                name="newPassword"
                                id="newPassword"
                                value={props.values.newPassword}
                                onChange={props.handleChange}
                                style={{ maxWidth: "300px" }}
                                invalid={
                                  props.touched.newPassword &&
                                  props.errors.newPassword
                                    ? true
                                    : false
                                }
                              />
                              {props.touched.newPassword &&
                              props.errors.newPassword ? (
                                <FormFeedback type="invalid">
                                  {props.errors.newPassword}
                                </FormFeedback>
                              ) : (
                                <span className="text-muted">
                                  {t("The password must contain at least")}
                                </span>
                              )}
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="confirmNewPassword"
                              className="col-md-2 col-form-label"
                            >
                              {t("Confirm new password")}
                            </label>
                            <div className="col-md-10">
                              <Input
                                type="password"
                                name="confirmNewPassword"
                                id="confirmNewPassword"
                                value={props.values.confirmNewPassword}
                                onChange={props.handleChange}
                                style={{ maxWidth: "300px" }}
                                invalid={
                                  props.touched.confirmNewPassword &&
                                  props.errors.confirmNewPassword
                                    ? true
                                    : false
                                }
                              />
                              {props.touched.confirmNewPassword &&
                              props.errors.confirmNewPassword ? (
                                <FormFeedback type="invalid">
                                  {props.errors.confirmNewPassword}
                                </FormFeedback>
                              ) : (
                                <span className="text-muted">
                                  {t("Enter the new password again")}
                                </span>
                              )}
                            </div>
                          </Row>
                          <Row className="mb-1">
                            <label className="col-md-2 col-form-label"></label>
                            <div className="col-md-10 d-flex align-items-center">
                              <button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                style={{ minWidth: "60px" }}
                              >
                                {changePasswordLoading && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    style={{ marginBottom: "-2px" }}
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                                {!changePasswordLoading && t("Save Password")}
                              </button>
                            </div>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  )}
                  <Row>
                    <div className="mt-3">
                      {changePasswordError && (
                        <Alert
                          color="danger"
                          className="d-inline-block"
                          isOpen={passwordAlertVisible}
                          toggle={() => setPasswordAlertVisible(false)}
                        >
                          {t(changePasswordError)}
                        </Alert>
                      )}
                      {changePasswordSuccess && (
                        <Alert
                          color="success"
                          className="d-inline-block"
                          isOpen={passwordAlertVisible}
                          toggle={() => setPasswordAlertVisible(false)}
                        >
                          {t("Password changed successfully")}
                        </Alert>
                      )}
                    </div>
                  </Row>
                  {!admin && (
                    <>
                      <CardTitle className="h4 pb-4 mt-4">
                        {t("Notifications")}
                      </CardTitle>
                      <Row className="mb-3">
                        <div className="position-relative">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="messages"
                            name="messages"
                            checked={notifications.messages}
                            onChange={onNotificationsChange}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="messages"
                          >
                            {t("Message notifications")}
                          </label>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="position-relative">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="newsletter"
                            name="newsletter"
                            checked={notifications.newsletter}
                            onChange={onNotificationsChange}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="newsletter"
                          >
                            {t("Newsletter")}
                          </label>
                        </div>
                      </Row>
                      <Row className="mb-1">
                        <label className="col-md-2 col-form-label">
                          <button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            style={{ minWidth: "160px" }}
                            onClick={onNotificationsUpdate}
                          >
                            {updateNotificationsLoading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                style={{ marginBottom: "-2px" }}
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {!updateNotificationsLoading &&
                              t("Update Notifications")}
                          </button>
                        </label>
                        <div className="col-md-10 d-flex align-items-center"></div>
                      </Row>
                      <Row>
                        <div className="mt-3">
                          {updateNotificationsError && (
                            <Alert
                              color="danger"
                              className="d-inline-block"
                              isOpen={notificationsAlertVisible}
                              toggle={() => setNotificationsAlertVisible(false)}
                            >
                              {updateNotificationsError}
                            </Alert>
                          )}
                          {updateNotificationsSuccess && (
                            <Alert
                              color="success"
                              className="d-inline-block"
                              isOpen={notificationsAlertVisible}
                              toggle={() => setNotificationsAlertVisible(false)}
                            >
                              {t("Notification settings changed successfully")}
                            </Alert>
                          )}
                        </div>
                      </Row>
                    </>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation("settings")(withRouter(Settings))
