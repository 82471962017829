import {
  GET_CUSTOMIZATION_DATA,
  GET_CUSTOMIZATION_DATA_SUCCESS,
  GET_CUSTOMIZATION_DATA_FAILED,
  STEP_ONE_CHANGE,
  SAVE_STEP_ONE_DATA,
  SAVE_STEP_TWO_DATA,
  SAVE_STEP_FOUR_DATA,
  SAVE_CUSTOMIZATION_DATA_SUCCESS,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_FAILED,
  REMOVE_LOGO,
  DISMISS_LOGO_ERROR,
  UPLOAD_HOME_IMAGE,
  UPLOAD_HOME_IMAGE_SUCCESS,
  UPLOAD_HOME_IMAGE_FAILED,
  DISMISS_HOME_IMAGE_ERROR,
  REMOVE_HOME_IMAGE,
  PART_TEXT_CHANGE,
  ADD_SECTION,
  ADD_SECTION_FAILED,
  ADD_SECTION_SUCCESS,
  SAVE_SECTIONS_DATA,
  SECTION_COMMENTS_CHANGE,
  UPLOAD_PART_IMAGE,
  UPLOAD_PART_IMAGE_SUCCESS,
  UPLOAD_PART_IMAGE_FAILED,
  REMOVE_PART_IMAGE,
  ADD_PART,
  ADD_PART_SUCCESS,
  ADD_PART_FAILED,
  REMOVE_PART,
  REMOVE_SECTION,
  STEP_TWO_CHANGE,
  STEP_FOUR_CHANGE,
  FINISH_SUBMISSION,
  FINISH_SUBMISSION_SUCCESS,
  GET_TEMPLATE_DESIGNS,
  GET_TEMPLATE_DESIGNS_SUCCESS,
  GET_TEMPLATE_DESIGNS_FAILED,
  SELECT_TEMPLATE_DESIGN,
  SELECT_TEMPLATE_DESIGN_SUCCESS,
  SELECT_TEMPLATE_DESIGN_FAILED,
  SELECT_TEMPLATE_CATEGORY,
  SKIP_TEMPLATE,
  SKIP_TEMPLATE_SUCCESS,
  SKIP_TEMPLATE_FAILED,
  RESET_SKIPPED_TEMPLATE,
  REMOVE_TEMPLATE,
  REMOVE_TEMPLATE_SUCCESS,
  REMOVE_TEMPLATE_FAILED,
} from "./actionTypes"

export const getCustomizationData = () => ({
  type: GET_CUSTOMIZATION_DATA,
})

export const getCustomizationDataSuccess = data => ({
  type: GET_CUSTOMIZATION_DATA_SUCCESS,
  payload: data,
})

export const getCustomizationDataFailed = () => ({
  type: GET_CUSTOMIZATION_DATA_FAILED,
})

export const stepOneChange = (input, value) => ({
  type: STEP_ONE_CHANGE,
  payload: { input, value },
})

export const saveStepOneData = (
  companyName,
  companyDescription,
  domain,
  oldWebsite,
  stepOneComments
) => ({
  type: SAVE_STEP_ONE_DATA,
  payload: {
    companyName,
    companyDescription,
    domain,
    oldWebsite,
    stepOneComments,
  },
})

export const stepTwoChange = (input, value) => ({
  type: STEP_TWO_CHANGE,
  payload: { input, value },
})

export const saveStepTwoData = (
  logo,
  title,
  subTitle,
  mainText,
  homeImages,
  comments
) => ({
  type: SAVE_STEP_TWO_DATA,
  payload: { logo, title, subTitle, mainText, homeImages, comments },
})

export const stepFourChange = (input, value) => ({
  type: STEP_FOUR_CHANGE,
  payload: { input, value },
})

export const saveStepFourData = (likedWebsites, finalComments) => ({
  type: SAVE_STEP_FOUR_DATA,
  payload: { likedWebsites, finalComments },
})

export const saveCustomizationDataSuccess = () => ({
  type: SAVE_CUSTOMIZATION_DATA_SUCCESS,
})

export const uploadLogo = file => ({
  type: UPLOAD_LOGO,
  payload: file,
})

export const uploadLogoSuccess = fileName => ({
  type: UPLOAD_LOGO_SUCCESS,
  payload: fileName,
})

export const uploadLogoFailed = error => ({
  type: UPLOAD_LOGO_FAILED,
  payload: error,
})

export const dismissLogoError = () => ({
  type: DISMISS_LOGO_ERROR,
})

export const removeLogo = () => ({
  type: REMOVE_LOGO,
})

export const uploadHomeImage = file => ({
  type: UPLOAD_HOME_IMAGE,
  payload: file,
})

export const uploadHomeImageSuccess = fileName => ({
  type: UPLOAD_HOME_IMAGE_SUCCESS,
  payload: fileName,
})

export const uploadHomeImageFailed = error => ({
  type: UPLOAD_HOME_IMAGE_FAILED,
  payload: error,
})

export const dismissHomeImageError = () => ({
  type: DISMISS_HOME_IMAGE_ERROR,
})

export const removeHomeImage = image => ({
  type: REMOVE_HOME_IMAGE,
  payload: image,
})

export const partTextChange = (sectionId, partId, text) => ({
  type: PART_TEXT_CHANGE,
  payload: { sectionId, partId, text },
})

export const sectionCommentsChange = (sectionId, comments) => ({
  type: SECTION_COMMENTS_CHANGE,
  payload: { sectionId, comments },
})

export const addSection = section => ({
  type: ADD_SECTION,
  payload: section.value,
})

export const addSectionFailed = error => ({
  type: ADD_SECTION_FAILED,
  payload: error,
})

export const addSectionSuccess = section => ({
  type: ADD_SECTION_SUCCESS,
  payload: section,
})

export const saveSectionsData = data => ({
  type: SAVE_SECTIONS_DATA,
  payload: data,
})

export const uploadPartImage = (file, sectionId, partId) => ({
  type: UPLOAD_PART_IMAGE,
  payload: { file, sectionId, partId },
})

export const uploadPartImageSuccess = (fileName, sectionId, partId) => ({
  type: UPLOAD_PART_IMAGE_SUCCESS,
  payload: { fileName, sectionId, partId },
})

export const uploadPartImageFailed = (error, partId) => ({
  type: UPLOAD_PART_IMAGE_FAILED,
  payload: { error, partId },
})

export const removePartImage = (image, sectionId, partId) => ({
  type: REMOVE_PART_IMAGE,
  payload: { image, sectionId, partId },
})

export const addPart = sectionId => ({
  type: ADD_PART,
  payload: { sectionId },
})

export const addPartSuccess = (partId, sectionId) => ({
  type: ADD_PART_SUCCESS,
  payload: { partId, sectionId },
})

export const addPartFailed = () => ({
  type: ADD_PART_FAILED,
})

export const removePart = (partId, sectionId) => ({
  type: REMOVE_PART,
  payload: { partId, sectionId },
})

export const removeSection = sectionId => ({
  type: REMOVE_SECTION,
  payload: { sectionId },
})

export const finishSubmission = () => ({
  type: FINISH_SUBMISSION,
})

export const finishSubmissionSuccess = () => ({
  type: FINISH_SUBMISSION_SUCCESS,
})

export const getTemplateDesigns = data => {
  return {
    type: GET_TEMPLATE_DESIGNS,
    payload: data,
  }
}

export const getTemplateDesignsSuccess = data => ({
  type: GET_TEMPLATE_DESIGNS_SUCCESS,
  payload: data,
})

export const getTemplateDesignsFailed = () => ({
  type: GET_TEMPLATE_DESIGNS_FAILED,
})

export const selectTemplateDesign = id => ({
  type: SELECT_TEMPLATE_DESIGN,
  payload: id,
})

export const selectTemplateDesignSuccess = () => ({
  type: SELECT_TEMPLATE_DESIGN_SUCCESS,
})

export const selectTemplateDesignFailed = error => ({
  type: SELECT_TEMPLATE_DESIGN_FAILED,
  payload: error,
})

export const skipTemplate = () => ({
  type: SKIP_TEMPLATE,
})

export const skipTemplateSuccess = () => ({
  type: SKIP_TEMPLATE_SUCCESS,
})

export const skipTemplateFailed = () => ({
  type: SKIP_TEMPLATE_FAILED,
})

export const resetSkippedTemplate = () => ({
  type: RESET_SKIPPED_TEMPLATE,
})

export const removeTemplate = () => ({
  type: REMOVE_TEMPLATE,
})

export const removeTemplateSuccess = () => ({
  type: REMOVE_TEMPLATE_SUCCESS,
})

export const removeTemplateFailed = () => ({
  type: REMOVE_TEMPLATE_FAILED,
})
