import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Badge,
  Modal,
  Table,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//Import Constants
import { awsS3SiteScreenshotsBucketURL } from "../../constants/constants"

//i18n
import { withTranslation } from "react-i18next"

//Import functions
import { dateShort } from "../../common/functions"

const CardRequestsSmall = ({ requests, t }) => {
  let navigate = useNavigate()

  const openRequests = request => {
    navigate(`/requests?request=${request}`)
  }

  const list = (requests || [])
    .filter(({ status }) => {
      return status !== "finished"
    })
    .map(({ id, created_at, name, status }) => {
      let statusText
      let statusType

      switch (status) {
        case "waiting":
          statusText = t("Waiting for payment")
          statusType = "badge-soft-warning"
          break
        case "inprogress":
          statusText = t("In progress")
          statusType = "badge-soft-primary"
          break
      }

      return (
        <tr
          key={id}
          onClick={() => openRequests(id)}
          className="requests-small-table-row"
        >
          <td
            className="requests-small-table-cell"
            style={{ minWidth: "52px" }}
          >
            {dateShort(new Date(created_at))}
          </td>
          <td className="requests-small-table-cell">{name}</td>
          <td
            className="requests-small-table-cell"
            style={{ textAlign: "right" }}
          >
            <Badge style={{ fontSize: "12px" }} className={`${statusType} p-2`}>
              {statusText}
            </Badge>
          </td>
        </tr>
      )
    })

  console.log({ requests, list })

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody style={{ minHeight: "349px", height: "349px" }}>
            <div className="h-100 d-flex flex-column justify-content-between">
              <div>
                <h4 className="card-title mb-3">{t("Active requests")}</h4>
                {list.length !== 0 && (
                  <Table className="table mb-0 table-hover">
                    <tbody>{list}</tbody>
                  </Table>
                )}
                {list.length === 0 && (
                  <h5 className="text-center mt-5">
                    {t("You have no active requests")}
                  </h5>
                )}
              </div>
              <div className="w-100 text-center mt-4">
                <Link to="/requests" className="btn btn-primary">
                  {t("Show all")}
                </Link>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default withTranslation("requests")(CardRequestsSmall)
