import PropTypes from "prop-types"
import React from "react"
import { useEffect } from "react"
import { createSelector } from "reselect"
import { Routes, Route, Navigate } from "react-router-dom"
//import { layoutTypes } from "./constants/layout"
// Import Routes all
import {
  authProtectedRoutes,
  authAdminProtectedRoutes,
  publicRoutes,
} from "./routes"

// Import all middleware
import { AuthMiddleware, AuthAdminMiddleware } from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
//import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

//Import Actions
import { getAccountData, getAdminData } from "store/actions"

//Redux
import { useDispatch, useSelector } from "react-redux"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

//import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
//fakeBackend()

const getLayout = layoutType => {
  let Layout = VerticalLayout
  // switch (layoutType) {
  //   case layoutTypes.VERTICAL:
  //     Layout = VerticalLayout
  //     break
  //   case layoutTypes.HORIZONTAL:
  //     Layout = HorizontalLayout
  //     break
  //   default:
  //     break
  // }
  return Layout
}

const App = () => {
  const dispatch = useDispatch()

  const LayoutProperties = createSelector(
    state => state.Layout,
    layout => ({
      layoutType: layout.layoutType,
    })
  )

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)

  const user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    if (user) {
      if (user.admin) {
        dispatch(getAdminData())
      } else {
        dispatch(getAccountData())
      }
    }
  }, [])

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthMiddleware>
                <Layout>{route.component}</Layout>
              </AuthMiddleware>
            }
            key={idx}
          />
        ))}

        {authAdminProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthAdminMiddleware>
                <Layout type="admin">{route.component}</Layout>
              </AuthAdminMiddleware>
            }
            key={idx}
          />
        ))}
        <Route
          path="/"
          element={
            <Navigate
              to={user?.admin ? "/dashboard-admin" : "/dashboard"}
              replace
            />
          }
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
