import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

//redux
import { useDispatch } from "react-redux"

// Import Actions
import { openCreateTicketForm } from "store/actions"

export default () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(openCreateTicketForm())
    navigate("/tickets?form=open")
  }, [])
}
