import { takeEvery, call, put } from "redux-saga/effects"

import {
  GET_TEMPLATES,
  GET_TEMPLATE_DATA,
  UPLOAD_IMAGE,
  UPDATE_TEMPLATE,
  ADD_TEMPLATE,
  WEBFLOW_SYNC,
} from "./actionTypes"

import {
  getTemplates,
  fetchTemplateData,
  postUploadFile,
  postUploadFileWebflow,
  updateTemplate,
  addTemplate,
  webflowSync,
} from "../../helpers/api_requests"

import {
  getTemplatesFailed,
  getTemplatesSuccess,
  getTemplateData,
  getTemplateDataFailed,
  getTemplateDataSuccess,
  uploadImageFailed,
  uploadImageSuccess,
  removeImage,
  updateTemplateFailed,
  updateTemplateSuccess,
  addTemplateFailed,
  addTemplateSuccess,
  webflowSyncSuccess,
  webflowSyncFailed,
} from "./actions"

function* onGetTemplates() {
  const response = yield call(getTemplates)

  if (response.error) {
    yield put(getTemplatesFailed())
  } else {
    yield put(getTemplatesSuccess(response))
  }
}

function* onGetTemplateData({ payload: { id } }) {
  const response = yield call(fetchTemplateData, { id })

  if (response.error) {
    yield put(getTemplateDataFailed())
  } else {
    yield put(getTemplateDataSuccess(response))
  }
}

function* onUploadImage({ payload }) {
  const response = yield call(postUploadFile, payload)

  if (response.error) {
    yield put(uploadImageFailed(response.error))
  } else if (response.fileName) {
    yield put(uploadImageSuccess(response.fileName))
  }
}

function* onUpdateTemplate({ payload }) {
  try {
    const response = yield call(updateTemplate, payload)

    if (response.error) {
      yield put(updateTemplateFailed(response.error))
    } else {
      yield put(updateTemplateSuccess())
      yield put(getTemplateData(payload.inputs.id))
      yield put(removeImage())
    }
  } catch (err) {
    yield put(updateTemplateFailed(err.message))
  }
}

function* onAddTemplate({ payload }) {
  try {
    const response = yield call(addTemplate, payload)

    if (response.error) {
      yield put(addTemplateFailed(response.error))
    } else {
      yield put(addTemplateSuccess())
      yield put(removeImage())
    }
  } catch (err) {
    yield put(addTemplateFailed(err.message))
  }
}

function* onWebflowSync() {
  try {
    const response = yield call(webflowSync)

    if (response.error) {
      yield put(webflowSyncFailed(response.error))
    } else {
      yield put(webflowSyncSuccess())
    }
  } catch (err) {
    yield put(webflowSyncFailed(err.message))
  }
}

function* templatesSaga() {
  yield takeEvery(GET_TEMPLATES, onGetTemplates)
  yield takeEvery(GET_TEMPLATE_DATA, onGetTemplateData)
  yield takeEvery(UPLOAD_IMAGE, onUploadImage)
  yield takeEvery(UPDATE_TEMPLATE, onUpdateTemplate)
  yield takeEvery(ADD_TEMPLATE, onAddTemplate)
  yield takeEvery(WEBFLOW_SYNC, onWebflowSync)
}

export default templatesSaga
