import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = ({ titleLink, title, breadcrumbItems, breadcrumbItem }) => {
  const breadcrumbData = breadcrumbItems ? breadcrumbItems : [breadcrumbItem]

  const items = breadcrumbData.map((item, index) => {
    if (index === breadcrumbData.length - 1)
      return (
        <BreadcrumbItem key={item.item} active>
          {item.item}
        </BreadcrumbItem>
      )
    return (
      <BreadcrumbItem key={item.item}>
        <Link to={item.link}>{item.item}</Link>
      </BreadcrumbItem>
    )
  })

  return (
    <Row>
      <Col xs={12}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">
            {breadcrumbData[breadcrumbData.length - 1].item}
          </h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to={titleLink}>{title}</Link>
              </BreadcrumbItem>
              {items}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
