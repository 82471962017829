import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import LayoutSaga from "./layout/saga"
import customizationSaga from "./customization/saga"
import accountSaga from "./account/saga"
import ticketsSaga from "./tickets/saga"
import templatesSaga from "./templates/saga"
import adminSaga from "./admin/saga"
import requestsSaga from "./requests/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(customizationSaga),
    fork(accountSaga),
    fork(ticketsSaga),
    fork(templatesSaga),
    fork(adminSaga),
    fork(requestsSaga),
  ])
}
