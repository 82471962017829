import {
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILED,
  GET_TEMPLATE_DATA,
  GET_TEMPLATE_DATA_SUCCESS,
  GET_TEMPLATE_DATA_FAILED,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILED,
  REMOVE_IMAGE,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILED,
  ADD_TEMPLATE,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAILED,
  RESET_TEMPLATE_ADDED,
  WEBFLOW_SYNC,
  WEBFLOW_SYNC_SUCCESS,
  WEBFLOW_SYNC_FAILED,
} from "./actionTypes"

const initialState = {
  templates: [],
  loadingTemplates: false,
  template: null,
  loadingTemplateData: false,
  tagOptions: [],
  imageUploadLoading: false,
  imageError: null,
  image: null,
  updateTemplateLoading: false,
  updateError: null,
  addTemplateLoading: false,
  addError: null,
  templateAdded: false,
  loadingWebflowSync: false,
  webflowSyncError: null,
}

const templates = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES:
      return {
        ...state,
        loadingTemplates: true,
      }

    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loadingTemplates: false,
        templates: action.payload.data,
      }

    case GET_TEMPLATES_FAILED:
      return {
        ...state,
        loadingList: false,
      }

    case GET_TEMPLATE_DATA:
      return {
        ...state,
        loadingTemplateData: true,
      }

    case GET_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        loadingTemplateData: false,
        template: action.payload.data,
        tagOptions: action.payload.tagOptions,
      }

    case GET_TEMPLATE_DATA_FAILED:
      return {
        ...state,
        loadingTemplateData: false,
      }

    case UPLOAD_IMAGE:
      return {
        ...state,
        imageUploadLoading: true,
        imageError: null,
      }

    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imageUploadLoading: false,
        image: action.payload,
      }

    case UPLOAD_IMAGE_FAILED:
      return {
        ...state,
        imageUploadLoading: false,
        imageError: action.payload,
      }

    case REMOVE_IMAGE:
      return {
        ...state,
        image: "",
      }

    case UPDATE_TEMPLATE:
      return {
        ...state,
        updateTemplateLoading: true,
        updateError: null,
      }

    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        updateTemplateLoading: false,
      }

    case UPDATE_TEMPLATE_FAILED:
      return {
        ...state,
        updateTemplateLoading: false,
        updateError: action.payload,
      }

    case ADD_TEMPLATE:
      return {
        ...state,
        addTemplateLoading: true,
        addError: null,
      }

    case ADD_TEMPLATE_SUCCESS:
      return {
        ...state,
        addTemplateLoading: false,
        templateAdded: true,
      }

    case ADD_TEMPLATE_FAILED:
      return {
        ...state,
        addTemplateLoading: false,
        addError: action.payload,
      }

    case RESET_TEMPLATE_ADDED:
      return {
        ...state,
        templateAdded: false,
      }

    case WEBFLOW_SYNC:
      return {
        ...state,
        loadingWebflowSync: true,
      }

    case WEBFLOW_SYNC_SUCCESS:
      return {
        ...state,
        loadingWebflowSync: false,
      }

    case WEBFLOW_SYNC_FAILED:
      return {
        ...state,
        loadingWebflowSync: false,
        webflowSyncError: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}

export default templates
