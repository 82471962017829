import React, { useState, useEffect, useRef } from "react"
import { Link, useSearchParams, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  Spinner,
  CardBody,
  CardImg,
  CardText,
  Badge,
} from "reactstrap"
import { map } from "lodash"
import Select from "react-select"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

// Import Actions
import {
  fetchTicketList,
  changeTab,
  closeTicketForm,
  getTicketDetail,
  closeTicketDetail,
  submitMessage,
  openCreateTicketForm,
  toggleStar,
  selectLabel,
  deleteTickets,
  changeTicketLabel,
} from "store/actions"

//Import Components
import Toolbar from "./Toolbar"
import TicketForm from "./TicketForm"

//Import functions
import { timeAMPM, dateShort, fileTypeIcon } from "common/functions"
import { selectColourStyles } from "common/selectHelpers"

//Import Image
import agentTomas from "../../assets/images/users/agent-tomas.jpg"
import user1 from "../../assets/images/icons/profile.png"

//Import Constants
import { awsS3UserUploadBucketURL } from "../../constants/constants"

//i18n
import { withTranslation } from "react-i18next"

const Inbox = ({ t }) => {
  //meta title
  document.title = t("Title Inbox")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const authUser = JSON.parse(localStorage.getItem("authUser"))

  const agent = authUser?.agent

  const lastMessageRef = useRef()
  let [searchParams] = useSearchParams()

  if (searchParams.get("form") === "open") {
    document.title = t("Title Create Ticket")
  }

  const ticketsData = createSelector(
    state => state.tickets,
    ticketsData => ({
      loadingList: ticketsData.loadingList,
      tickets: ticketsData.tickets,
      tab: ticketsData.tab,
      ticketDetailLoading: ticketsData.ticketDetailLoading,
      ticketDetail: ticketsData.ticketDetail,
      activeUnread: ticketsData.activeUnread,
      closedUnread: ticketsData.closedUnread,
      createTicketForm: ticketsData.createTicketForm,
      labelFilter: ticketsData.labelFilter,
    })
  )

  const {
    loadingList,
    tickets,
    tab,
    ticketDetailLoading,
    ticketDetail,
    activeUnread,
    closedUnread,
    createTicketForm,
    labelFilter,
  } = useSelector(ticketsData)

  const [selected, setSelected] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [ticketLabel, setTicketLabel] = useState([])

  // load ticket list on render and tab change
  useEffect(() => {
    dispatch(fetchTicketList(tab, labelFilter))
  }, [tab, labelFilter])

  // refetch list on demand
  const refetchList = () => {
    dispatch(fetchTicketList(tab, labelFilter))
  }

  // useEffect(() => {
  //   setMailsList(mailslists)
  //   const read = mailslists.filter(item => !item.read).length
  //   setBadgeLength(read)
  // }, [mailslists])

  const handleSelect = id => {
    if (selected.indexOf(id) !== -1) {
      setSelected(selected.filter(ticket => ticket !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  //const [displayCategory, setDisplayCategory] = useState("inbox")

  // Starred ticket
  const starredBtn = (id, star, agent) => {
    dispatch(toggleStar(id, star, agent))
  }

  const listTicketDate = date => {
    const now = new Date()
    const ticketDate = new Date(date)

    if (
      now.getDate() === ticketDate.getDate() &&
      now.getMonth() === ticketDate.getMonth() &&
      now.getFullYear() === ticketDate.getFullYear()
    ) {
      return timeAMPM(ticketDate)
    } else {
      return dateShort(ticketDate)
    }
  }

  // Change tab
  const onTabClick = tab => {
    dispatch(changeTab(tab))
    setSelected([])
  }

  // Ticket navigation
  const openTicket = id => {
    dispatch(getTicketDetail(id))
  }

  const backToTickets = () => {
    dispatch(closeTicketDetail())
  }

  const refetchTicket = () => {
    openTicket(ticketDetail.id)
  }

  // Open Ticket form
  const onCreateTicket = () => {
    dispatch(openCreateTicketForm())
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const form = urlParams.get("form")

    if (form) {
      onCreateTicket()
      return
    }

    const ticket = urlParams.get("ticket")

    if (ticket) {
      openTicket(ticket)
      return
    }
  }, [])

  const submitReply = (
    account,
    type,
    subject,
    message,
    attachments,
    ticketId,
    close
  ) => {
    dispatch(
      submitMessage(
        account.value,
        type,
        subject,
        message,
        attachments,
        ticketId,
        close
      )
    )
  }

  // Label
  const onLabelSelect = label => {
    dispatch(selectLabel(label))
    setSelected([])

    if (ticketDetail) {
      backToTickets()
    }
  }

  // Handle Ticket delete
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal)
  }

  const deleteSelected = () => {
    dispatch(deleteTickets(selected))
    setDeleteModal(false)
    setSelected([])
  }

  // Handle Label change
  const labelOptions = [
    {
      label: t("No Label"),
      value: "none",
      color: "#000000",
    },
    {
      label: t("Update Request"),
      value: "update-request",
      color: "#50a5f1",
    },
    {
      label: t("General"),
      value: "general",
      color: "#34c38f",
    },
    {
      label: t("Incident"),
      value: "incident",
      color: "#f46a6a",
    },
    {
      label: t("Project Development"),
      value: "project-development",
      color: "#f1b44c",
    },
  ]

  // set default Ticket Label
  useEffect(() => {
    if (ticketDetail) {
      const defaultLabel = labelOptions.find(
        label => label.value === ticketDetail.label
      )

      setTicketLabel(defaultLabel)
    }
  }, [ticketDetail])

  // update Ticket Label
  const onLabelChange = selected => {
    setTicketLabel(selected)
    dispatch(changeTicketLabel(ticketDetail?.id, selected.value))
  }

  // Scroll to last message in Ticket detail
  useEffect(() => {
    if (ticketDetail && ticketDetailLoading === false) {
      lastMessageRef.current.scrollIntoView({ block: "center" })
    }
  }, [ticketDetailLoading])

  // Close Ticket Form on load (if not from Create Ticket)
  useEffect(() => {
    if (searchParams.get("form") !== "open") {
      dispatch(closeTicketForm())
    }
  }, [searchParams.get("form")])

  // Ticket labels definition
  const labels = {
    "project-development": {
      color: "warning",
      text: t("Development"),
    },
    "update-request": {
      color: "info",
      text: t("Update"),
    },
    incident: {
      color: "danger",
      text: t("Incident"),
    },
    general: {
      color: "success",
      text: t("General"),
    },
  }

  useEffect(() => {
    if (!authUser) {
      navigate("/logout")
    }
  }, [authUser])

  if (!authUser) return null

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={t("Tickets")}
            titleLink="/tickets"
            breadcrumbItems={[
              {
                link: "",
                item: createTicketForm ? t("Create Ticket") : t("Inbox"),
              },
            ]}
          />

          <Row>
            <Col xs="12">
              <Card className="email-leftbar">
                <Button
                  type="button"
                  color="danger"
                  className="btn waves-effect waves-light"
                  block
                  onClick={onCreateTicket}
                >
                  {t("Create New Ticket")}
                </Button>
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <a
                        href="#!"
                        className={classnames({ active: tab === 1 })}
                        onClick={() => {
                          onTabClick(1)
                        }}
                      >
                        <i className="mdi mdi-email-outline me-2"></i>
                        {t("Active Tickets")}
                        {activeUnread > 0 && (
                          <span className="ml-1 float-end">{activeUnread}</span>
                        )}
                      </a>
                    </NavItem>

                    <NavItem>
                      <a
                        href="#!"
                        className={classnames({ active: tab === 2 })}
                        onClick={() => {
                          onTabClick(2)
                        }}
                      >
                        <i className="mdi mdi-email-check-outline me-2"></i>
                        {t("Closed Tickets")}
                        {closedUnread > 0 && (
                          <span className="ml-1 float-end">{closedUnread}</span>
                        )}
                      </a>
                    </NavItem>

                    <NavItem>
                      <a
                        href="#!"
                        className={classnames({ active: tab === 3 })}
                        onClick={() => {
                          onTabClick(3)
                        }}
                      >
                        <i className="mdi mdi-star-outline me-2"></i>
                        {t("Starred")}
                      </a>
                    </NavItem>
                  </Nav>
                </div>

                <h6 className="mt-4">{t("Labels")}</h6>

                <div className="mail-list mt-1">
                  <div
                    className="hover-cursor py-2 px-1"
                    onClick={() => onLabelSelect("update-request")}
                  >
                    <span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span>
                    {t("Update Request")}
                  </div>
                  <div
                    className="hover-cursor py-2 px-1"
                    onClick={() => onLabelSelect("general")}
                  >
                    <span className="mdi mdi-arrow-right-drop-circle text-success float-end"></span>
                    {t("General")}
                  </div>
                  <div
                    className="hover-cursor py-2 px-1"
                    onClick={() => onLabelSelect("incident")}
                  >
                    <span className="mdi mdi-arrow-right-drop-circle text-danger float-end"></span>
                    {t("Incident")}
                  </div>
                  <div
                    className="hover-cursor py-2 px-1"
                    onClick={() => onLabelSelect("project-development")}
                  >
                    <span className="mdi mdi-arrow-right-drop-circle text-warning float-end"></span>
                    {t("Project Development")}
                  </div>
                </div>
              </Card>
              {!loadingList &&
                !ticketDetail &&
                !ticketDetailLoading &&
                !createTicketForm && (
                  <div className="email-rightbar mb-3">
                    <Card>
                      <Toolbar
                        show={selected.length > 0}
                        activeTab={tab}
                        refetch={refetchList}
                        deleteModal={deleteModal}
                        toggleDeleteModal={toggleDeleteModal}
                        deleteSelected={deleteSelected}
                      />
                      {labelFilter !== "none" && (
                        <div
                          className="hover-cursor ms-3 mb-2"
                          style={{ marginTop: "-6px" }}
                          onClick={() => onLabelSelect("none")}
                        >
                          <Badge
                            color={labels[labelFilter].color}
                            className="d-inline-flex align-items-center"
                          >
                            <div>{labels[labelFilter].text}</div>
                            <i className="bx bx-x-circle ms-1 font-size-12"></i>
                          </Badge>
                        </div>
                      )}
                      {tickets.length > 0 ? (
                        <>
                          <ul className="message-list">
                            {(tickets || [])?.map(ticket => {
                              const read = agent
                                ? ticket.read || !ticket.last_sent_user
                                  ? ""
                                  : "unread"
                                : ticket.read || ticket.last_sent_user
                                ? ""
                                : "unread"

                              const name = agent
                                ? ticket.account.username
                                : ticket.agent.username +
                                  " " +
                                  t("from") +
                                  " CP"

                              const starred = agent
                                ? ticket.starred_agent
                                : ticket.starred_user

                              return (
                                <li
                                  key={ticket.id}
                                  className={read}
                                  onClick={() => openTicket(ticket.id)}
                                >
                                  <div className="col-mail col-mail-1">
                                    <div
                                      className="checkbox-wrapper-mail"
                                      onClick={e => e.stopPropagation()}
                                    >
                                      <Input
                                        type="checkbox"
                                        value={ticket.id}
                                        id={ticket.id}
                                        name="emailcheckbox"
                                        onClick={() => {
                                          handleSelect(ticket.id)
                                        }}
                                        checked={
                                          selected.indexOf(ticket.id) !== -1
                                        }
                                        onChange={() => {
                                          return null
                                        }}
                                      />
                                      <Label
                                        htmlFor={ticket.id}
                                        className="toggle"
                                      />
                                    </div>
                                    <div className="title">{name}</div>
                                    <span
                                      className={
                                        starred
                                          ? "star-toggle fas fa-star text-warning"
                                          : "star-toggle far fa-star"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={e => {
                                        e.stopPropagation()
                                        starredBtn(
                                          ticket.id,
                                          agent
                                            ? !ticket.starred_agent
                                            : !ticket.starred_user,
                                          agent
                                        )
                                      }}
                                    />
                                  </div>
                                  <div className="col-mail col-mail-2">
                                    <div className="subject d-flex align-items-center">
                                      {ticket.label !== "none" && (
                                        <Badge
                                          color={labels[ticket.label].color}
                                          className="me-1"
                                        >
                                          {labels[ticket.label].text}
                                        </Badge>
                                      )}
                                      <div
                                        className="subject-text"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ticket.subject +
                                            " - " +
                                            ticket.messages[
                                              ticket.messages.length - 1
                                            ].text,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="date">
                                      {listTicketDate(ticket.last_updated)}
                                    </div>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </>
                      ) : (
                        <div className="align-items-center text-center p-5">
                          {" "}
                          <i className="mdi mdi-email-outline me-2 font-size-24"></i>{" "}
                          <h5>{t("No tickets found")}</h5>
                        </div>
                      )}
                    </Card>

                    {/* {tickets.length > 0 && (
                  <Row>
                    <Col xs="7">Showing 1 - 20 of 1,524</Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        <Button type="button" color="success" size="sm">
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button type="button" color="success" size="sm">
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )} */}
                  </div>
                )}
              {ticketDetail && (
                <div className="email-rightbar mb-3">
                  <Card>
                    <CardBody className="list-message">
                      <div className="d-flex flex-column mb-4">
                        <div className="flex-grow-1">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div
                                className="d-flex align-items-center icon-hover-wrap"
                                onClick={backToTickets}
                              >
                                <i className="bx bx-left-arrow-alt font-size-24 me-2"></i>
                              </div>
                              <div className="d-flex flex-column">
                                <div className="d-flex align-items-center">
                                  <h5 className="font-size-14 mt-1 mb-1">
                                    {ticketDetail.subject}
                                    {ticketDetail.account &&
                                      " - " + ticketDetail.account.username}
                                  </h5>
                                </div>
                                <small className="text-muted">
                                  {ticketDetail.status === "active" &&
                                    t("Active ticket")}
                                  {ticketDetail.status === "closed" &&
                                    t("Closed ticket")}
                                </small>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <span
                                className={
                                  (
                                    agent
                                      ? ticketDetail.starred_agent
                                      : ticketDetail.starred_user
                                  )
                                    ? "star-toggle fas fa-star text-warning"
                                    : "star-toggle far fa-star"
                                }
                                style={{ cursor: "pointer" }}
                                onClick={e => {
                                  starredBtn(
                                    ticketDetail.id,
                                    agent
                                      ? !ticketDetail.starred_agent
                                      : !ticketDetail.starred_user,
                                    agent
                                  )
                                }}
                              />
                              <div
                                onClick={refetchTicket}
                                className="icon-hover-wrap ms-2"
                              >
                                <i
                                  className="bx bx-sync font-size-24 align-middle me-1"
                                  style={{
                                    color: "#555b6d",
                                    paddingTop: "1.5px",
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        {ticketDetail.label !== "none" && !agent && (
                          <div className="mt-3 d-flex">
                            <Badge
                              color={labels[ticketDetail.label].color}
                              className="me-1"
                            >
                              {labels[ticketDetail.label].text}
                            </Badge>
                          </div>
                        )}
                        {agent && (
                          <div className="mt-2">
                            <div style={{ maxWidth: "220px" }}>
                              <Select
                                options={labelOptions}
                                styles={selectColourStyles}
                                value={ticketLabel}
                                onChange={onLabelChange}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {(ticketDetail.messages || []).map((message, i) => {
                        const time = new Date(message.time)

                        const name = agent
                          ? message.sender === "agent"
                            ? "You"
                            : ticketDetail.account.username
                          : message.sender === "agent"
                          ? t("Tomas from Certified Pixel")
                          : t("You")

                        return (
                          <div
                            className="d-flex"
                            key={message.id}
                            style={{ marginBottom: "32px" }}
                          >
                            <div className="flex-shrink-0 me-3 d-none d-sm-block">
                              <img
                                src={
                                  message.sender === "agent"
                                    ? agentTomas
                                    : user1
                                }
                                alt=""
                                className="avatar-sm rounded-circle img-thumbnail"
                              />
                            </div>
                            <div className="flex-grow-1">
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div className="text-muted">
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                      <div className="col-8">
                                        <h5 className="mb-2">{name}</h5>
                                      </div>
                                      <div
                                        className="col-4"
                                        style={{ textAlign: "right" }}
                                      >
                                        <small className="text-muted">
                                          <span
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            {dateShort(time)}
                                          </span>{" "}
                                          <span
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            {timeAMPM(time)}
                                          </span>
                                        </small>
                                      </div>
                                    </div>
                                    <p
                                      className="mb-0"
                                      style={{ whiteSpace: "pre-wrap" }}
                                      // dangerouslySetInnerHTML={{
                                      //   __html: message.text,
                                      // }}
                                    >
                                      {message.text}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {message.attachments.length > 0 && (
                                <>
                                  <Row>
                                    <div className="attachments-list-wrap mt-2 mt-md-3">
                                      {map(message.attachments, attachment => (
                                        <Link
                                          to={
                                            awsS3UserUploadBucketURL +
                                            attachment.file
                                          }
                                          target="_blank"
                                          download
                                        >
                                          <Card
                                            key={attachment}
                                            className="me-2 mb-2 attachment-card"
                                          >
                                            <div className="d-flex justify-content-center align-items-center p-3">
                                              <CardImg
                                                className="img-fluid"
                                                src={fileTypeIcon(
                                                  attachment.file
                                                )}
                                                alt="Certified Pixel"
                                                style={{ maxWidth: "36px" }}
                                              />
                                            </div>
                                            <CardBody
                                              className="p-2"
                                              style={{ background: "#ededed" }}
                                            >
                                              <CardText className="text-center">
                                                <small className="d-block text-truncate">
                                                  {attachment.name}
                                                </small>
                                              </CardText>
                                            </CardBody>
                                          </Card>
                                        </Link>
                                      ))}
                                    </div>
                                  </Row>
                                </>
                              )}
                            </div>
                          </div>
                        )
                      })}
                      <hr style={{ marginTop: "34px" }} ref={lastMessageRef} />
                      <div className="mt-2 mt-md-4 mb-4">
                        <TicketForm
                          type="reply"
                          submitReply={submitReply}
                          ticketId={ticketDetail.id}
                          status={ticketDetail.status}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              )}
              {(ticketDetailLoading || loadingList) && !createTicketForm && (
                <div className="email-rightbar mb-3 position-relative h-100">
                  <Spinner
                    color="primary"
                    className="position-absolute top-50 start-50"
                  />
                </div>
              )}
              {createTicketForm && (
                <div className="email-rightbar mb-3">
                  <Card>
                    <CardBody>
                      <TicketForm type="newTicket" submitReply={submitReply} />
                    </CardBody>
                  </Card>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation("tickets")(withRouter(Inbox))
