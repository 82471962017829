import { takeEvery, call, put } from "redux-saga/effects"

import { GET_REQUESTS, GET_REQUEST_DETAIL } from "./actionTypes"

import { getRequests, getRequestDetail } from "../../helpers/api_requests"

import {
  getRequestsSuccess,
  getRequestsFailed,
  getRequestDetailSuccess,
  getRequestDetailFailed,
} from "./actions"

function* onGetRequests() {
  try {
    const response = yield call(getRequests)

    if (response.error) {
      yield put(getRequestsFailed())
    } else {
      yield put(getRequestsSuccess(response))
    }
  } catch (err) {
    yield put(getRequestsFailed(err.message))
  }
}

function* onGetRequestDetail({ payload: { id } }) {
  try {
    const response = yield call(getRequestDetail, { id })

    if (response.error) {
      yield put(getRequestDetailFailed())
    } else {
      yield put(getRequestDetailSuccess(response))
    }
  } catch (err) {
    yield put(getRequestDetailFailed(err.message))
  }
}

function* requestsSaga() {
  yield takeEvery(GET_REQUESTS, onGetRequests)
  yield takeEvery(GET_REQUEST_DETAIL, onGetRequestDetail)
}

export default requestsSaga
