import {
  GET_ACCOUNT_DATA,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAILED,
  GET_ADMIN_DATA,
  GET_ADMIN_DATA_SUCCESS,
  GET_ADMIN_DATA_FAILED,
  GET_SETTINGS_DATA,
  GET_SETTINGS_DATA_SUCCESS,
  GET_SETTINGS_DATA_FAILED,
  GET_ADMIN_SETTINGS_DATA,
  GET_ADMIN_SETTINGS_DATA_SUCCESS,
  GET_ADMIN_SETTINGS_DATA_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAILED,
  GET_BILLING_DATA,
  GET_BILLING_DATA_SUCCESS,
  GET_BILLING_DATA_FAILED,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  UPDATE_ACCOUNT_DATA_AFTER_SKIP,
} from "./actionTypes"

const initialState = {
  loading: false,
  project: null,
  customizationStatus: undefined,
  customizationSubmitted: undefined,
  settingsDataLoading: true,
  settingsData: null,
  adminSettingsDataLoading: true,
  adminSettingsData: null,
  changePasswordLoading: false,
  changePasswordError: null,
  changePasswordSuccess: false,
  updateNotificationsLoading: false,
  updateNotificationsError: null,
  updateNotificationsSuccess: false,
  billingDataLoading: true,
  billingData: null,
  cancelLoading: false,
  cancelError: false,
  cancelSuccess: false,
  subscription: null,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_DATA:
      return {
        ...state,
        loading: true,
      }

    case GET_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.payload.data.projects[0],
        customizationStatus:
          action.payload.data.projects[0].customization.status,
        customizationSubmitted:
          action.payload.data.projects[0].customization.submitted,
        subscription: action.payload.data.subscriptions_cz[0],
      }

    case GET_ACCOUNT_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }

    case GET_ADMIN_DATA:
      return {
        ...state,
        loading: true,
      }

    case GET_ADMIN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case GET_ADMIN_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }

    case GET_SETTINGS_DATA:
      return {
        ...state,
        settingsDataLoading: true,
      }

    case GET_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        settingsDataLoading: false,
        settingsData: action.payload,
      }

    case GET_SETTINGS_DATA_FAILED:
      return {
        ...state,
        settingsDataLoading: false,
      }

    case GET_ADMIN_SETTINGS_DATA:
      return {
        ...state,
        adminSettingsDataLoading: true,
      }

    case GET_ADMIN_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        adminSettingsDataLoading: false,
        adminSettingsData: action.payload,
      }

    case GET_ADMIN_SETTINGS_DATA_FAILED:
      return {
        ...state,
        adminSettingsDataLoading: false,
      }

    case CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordLoading: true,
        changePasswordError: null,
        changePasswordSuccess: false,
      }

    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordError: action.payload.message
          ? action.payload.message
          : "There was an error changing your password. Please try again or contact us if the problem persists.",
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordSuccess: true,
      }

    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        updateNotificationsLoading: true,
        updateNotificationsError: null,
        updateNotificationsSuccess: false,
      }

    case UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        updateNotificationsLoading: false,
        updateNotificationsSuccess: true,
      }

    case UPDATE_NOTIFICATIONS_FAILED:
      return {
        ...state,
        updateNotificationsLoading: false,
        updateNotificationsError:
          "There was an error updating your notification settings. Please try again or contact us if the problem persists.",
      }

    case GET_BILLING_DATA:
      return {
        ...state,
        billingDataLoading: true,
      }

    case GET_BILLING_DATA_SUCCESS:
      return {
        ...state,
        billingDataLoading: false,
        billingData: action.payload,
      }

    case GET_BILLING_DATA_FAILED:
      return {
        ...state,
        billingDataLoading: false,
      }

    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelLoading: true,
        cancelError: false,
        cancelSuccess: false,
      }

    case CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        cancelLoading: false,
        cancelError: true,
      }

    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelLoading: false,
        cancelSuccess: true,
      }

    case UPDATE_ACCOUNT_DATA_AFTER_SKIP:
      return {
        ...state,
        project: { ...state.project, status: "new", using_template: false },
      }

    default:
      return {
        ...state,
      }
  }
}

export default account
