import { takeEvery, call, put } from "redux-saga/effects"

import {
  GET_PAYMENT_REQUESTS_DATA,
  MARK_PROFORMA_PAID,
  CREATE_ACCOUNT,
} from "./actionTypes"

import {
  getPaymentRequestsProformaData,
  markProformaPaid,
  createAccount,
} from "../../helpers/api_requests"

import {
  getPaymentRequestsDataSuccess,
  getPaymentRequestsDataFailed,
  markProformaPaidSuccess,
  markProformaPaidFailed,
  createAccountSuccess,
  createAccountFailed,
} from "./actions"

function* onGetPaymentRequestsData() {
  try {
    const response = yield call(getPaymentRequestsProformaData)

    if (response.error) {
      yield put(getPaymentRequestsDataFailed(response.error))
    } else {
      yield put(getPaymentRequestsDataSuccess(response.data))
    }
  } catch (err) {
    yield put(getPaymentRequestsDataFailed(err.message))
  }
}

function* onMarkProformaPaid({ payload }) {
  try {
    console.log("onMarkProformaPaid", payload)
    const response = yield call(markProformaPaid, { id: payload })
    console.log("response", response)

    if (response.error) {
      yield put(markProformaPaidFailed(response.error))
    } else {
      yield put(markProformaPaidSuccess(response.id))
    }
  } catch (err) {
    yield put(markProformaPaidFailed(err.message))
  }
}

function* onCreateAccount({ payload }) {
  try {
    console.log("onCreateAccount", payload)
    const response = yield call(createAccount, { id: payload })
    console.log("response", response)

    if (response.error) {
      yield put(createAccountFailed(response.error))
    } else {
      yield put(
        createAccountSuccess({
          id: payload,
          accountId: response.accountId,
          username: response.username,
        })
      )
    }
  } catch (err) {
    yield put(createAccountFailed(err.message))
  }
}

function* accountSaga() {
  yield takeEvery(GET_PAYMENT_REQUESTS_DATA, onGetPaymentRequestsData)
  yield takeEvery(MARK_PROFORMA_PAID, onMarkProformaPaid)
  yield takeEvery(CREATE_ACCOUNT, onCreateAccount)
}

export default accountSaga
