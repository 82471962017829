export const GET_ACCOUNT_DATA = "GET_ACCOUNT_DATA"
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS"
export const GET_ACCOUNT_DATA_FAILED = "GET_ACCOUNT_DATA_FAILED"
export const GET_ADMIN_DATA = "GET_ADMIN_DATA"
export const GET_ADMIN_DATA_SUCCESS = "GET_ADMIN_DATA_SUCCESS"
export const GET_ADMIN_DATA_FAILED = "GET_ADMIN_DATA_FAILED"
export const GET_SETTINGS_DATA = "GET_SETTINGS_DATA"
export const GET_SETTINGS_DATA_SUCCESS = "GET_SETTINGS_DATA_SUCCESS"
export const GET_SETTINGS_DATA_FAILED = "GET_SETTINGS_DATA_FAILED"
export const GET_ADMIN_SETTINGS_DATA = "GET_ADMIN_SETTINGS_DATA"
export const GET_ADMIN_SETTINGS_DATA_SUCCESS = "GET_ADMIN_SETTINGS_DATA_SUCCESS"
export const GET_ADMIN_SETTINGS_DATA_FAILED = "GET_ADMIN_SETTINGS_DATA_FAILED"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED"
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS"
export const UPDATE_NOTIFICATIONS_FAILED = "UPDATE_NOTIFICATIONS_FAILED"
export const GET_BILLING_DATA = "GET_BILLING_DATA"
export const GET_BILLING_DATA_SUCCESS = "GET_BILLING_DATA_SUCCESS"
export const GET_BILLING_DATA_FAILED = "GET_BILLING_DATA_FAILED"
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"
export const CANCEL_SUBSCRIPTION_FAILED = "CANCEL_SUBSCRIPTION_FAILED"
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS"
export const UPDATE_ACCOUNT_DATA_AFTER_SKIP = "UPDATE_ACCOUNT_DATA_AFTER_SKIP"
