import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  Button,
  Alert,
  Table,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

//Import Components
import CardWelcome from "./card-welcome"
import CustomizationFormProgress from "components/Customization/CustomizationFormProgress"
import CardProject from "./card-project"
import CardRequestsSmall from "./card-requests-small"

// import images
import doMore from "../../assets/images/do-more.jpg"
import newTab from "../../assets/images/icons/icons8-external-link.svg"
import workCoding from "../../assets/images/work-coding.jpg"

//Import functions
import { dateShort } from "../../common/functions"

// Import Actions
import {
  getUnreadCount,
  skipTemplate,
  updateAccountDataAfterSkip,
  resetSkippedTemplate,
} from "store/actions"

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = ({ t }) => {
  //meta title
  document.title = t("title")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [barProgress, setBarProgress] = useState(0)

  const accountData = createSelector(
    state => state.account,
    accountData => {
      return {
        loading: accountData.loading,
        project: accountData.project,
        customizationStatus: accountData.customizationStatus,
        customizationSubmitted: accountData.customizationSubmitted,
        subscription: accountData.subscription,
      }
    }
  )

  const {
    loading,
    project,
    customizationStatus,
    customizationSubmitted,
    subscription,
  } = useSelector(accountData)

  const ticketsData = createSelector(
    state => state.tickets,
    ticketsData => {
      return {
        activeUnread: ticketsData.activeUnread,
        closedUnread: ticketsData.closedUnread,
      }
    }
  )

  const { activeUnread, closedUnread } = useSelector(ticketsData)

  const customizationData = createSelector(
    state => state.customization,
    customizationData => {
      return {
        skipTemplateLoading: customizationData.skipTemplateLoading,
        skippedTemplate: customizationData.skippedTemplate,
      }
    }
  )

  const { skipTemplateLoading, skippedTemplate } =
    useSelector(customizationData)

  useEffect(() => {
    if (!loading && project?.status !== "live") {
      const progressPoint2 = document.getElementById("progress-point-2")
      const progressPoint3 = document.getElementById("progress-point-3")
      const progressPoint4 = document.getElementById("progress-point-4")

      if (project?.status === "new") {
        setBarProgress(33)

        progressPoint2.classList.replace("btn-secondary", "btn-primary")
      }

      if (project?.status === "building") {
        setBarProgress(66)

        progressPoint2.classList.replace("btn-secondary", "btn-primary")
        progressPoint3.classList.replace("btn-secondary", "btn-primary")
      }

      if (project?.status === "finished") {
        setBarProgress(100)

        progressPoint2.classList.replace("btn-secondary", "btn-primary")
        progressPoint3.classList.replace("btn-secondary", "btn-primary")
        progressPoint4.classList.replace("btn-secondary", "btn-primary")
      }
    }
  }, [loading])

  const dateOfCustomizationSubmitted = customizationSubmitted
    ? new Date(customizationSubmitted)
    : new Date()

  useEffect(() => {
    dispatch(getUnreadCount())
  }, [])

  // handle skipping template
  const onSkipTemplate = () => {
    if (!skipTemplateLoading) {
      dispatch(skipTemplate())
    }
  }

  useEffect(() => {
    if (!skipTemplateLoading && skippedTemplate) {
      dispatch(updateAccountDataAfterSkip())
      dispatch(resetSkippedTemplate())
      navigate("/submit-data")
    }
  }, [skipTemplateLoading])

  return (
    <React.Fragment>
      {!loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("Dashboard")}
              breadcrumbItems={[{ link: "/", item: t("Dashboard") }]}
            />
            <Row>
              <Col xl={project?.status !== "live" ? "4" : "12"}>
                <CardWelcome
                  projectStatus={project?.status}
                  unreadMessages={activeUnread + closedUnread}
                />
              </Col>
              {project?.status !== "live" && (
                <Col sl="8">
                  <Card>
                    <CardBody>
                      <CardTitle className="h5">
                        {t("Website Development Progress")}
                      </CardTitle>
                      {project?.status === "waiting" && (
                        <p className="card-title-desc mb-2">
                          {t(
                            "We are awaiting your selection of the template design"
                          )}
                        </p>
                      )}
                      {project?.status === "new" &&
                        customizationStatus === "not-started" && (
                          <p className="card-title-desc mb-2">
                            {t(
                              "We are waiting for the data - start submitting data"
                            )}
                          </p>
                        )}
                      {project?.status === "new" &&
                        customizationStatus === "started" && (
                          <p className="card-title-desc mb-2">
                            {t(
                              "We are waiting for the data - continue submitting data"
                            )}
                          </p>
                        )}
                      {project?.status === "building" && (
                        <p className="card-title-desc mb-2">
                          {t("We have received the necessary data")}
                        </p>
                      )}
                      {project?.status === "finished" && (
                        <p className="card-title-desc mb-2">
                          {t("Your website is ready to launch")}
                        </p>
                      )}
                      <div className="d-flex justify-content-center mt-3 mb-2 ps-sm-3 pe-sm-3">
                        <div
                          className="position-relative m-4 pb-4"
                          style={{ width: "80%" }}
                        >
                          <Progress
                            value={barProgress}
                            color="primary"
                            style={{ height: "2px" }}
                          ></Progress>
                          <button
                            className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill"
                            style={{ width: "1.6rem", height: "1.6rem" }}
                          >
                            1
                          </button>
                          <button
                            className="position-absolute top-0 translate-middle btn btn-sm btn-secondary rounded-pill"
                            style={{
                              width: "1.6rem",
                              height: "1.6rem",
                              left: "33%",
                            }}
                            id="progress-point-2"
                          >
                            2
                          </button>
                          <button
                            className="position-absolute top-0 translate-middle btn btn-sm btn-secondary rounded-pill"
                            style={{
                              width: "1.6rem",
                              height: "1.6rem",
                              left: "66%",
                            }}
                            id="progress-point-3"
                          >
                            3
                          </button>
                          <button
                            className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-secondary rounded-pill"
                            style={{ width: "1.6rem", height: "1.6rem" }}
                            id="progress-point-4"
                          >
                            4
                          </button>
                          <div className="development-progress-text-box">
                            <p>{t("Select design template")}</p>
                          </div>
                          <div
                            className="development-progress-text-box"
                            style={{
                              left: "33%",
                            }}
                          >
                            <p>{t("Submit project information")}</p>
                          </div>
                          <div
                            className="development-progress-text-box"
                            style={{
                              left: "66%",
                            }}
                          >
                            <p>{t("Website development")}</p>
                          </div>
                          <div
                            className="development-progress-text-box"
                            style={{
                              left: "100%",
                            }}
                          >
                            <p>{t("Project ready")}</p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
            {project?.status === "waiting" && (
              <Row>
                <Col xl={8}>
                  <Card
                    className="flex-column flex-xl-row"
                    style={{ height: "calc(100% - 24px)" }}
                  >
                    <Row className="no-gutters align-items-strech">
                      <Col xl={6} className="align-self-center">
                        <CardImg
                          className="img-fluid"
                          src={doMore}
                          alt="Certified Pixel"
                        />
                      </Col>
                      <Col xl={6}>
                        <CardBody className="h-100">
                          <div className="d-flex flex-column justify-content-between h-100">
                            <div className="d-flex flex-column">
                              <CardTitle>
                                {t("Select Website Design")}
                              </CardTitle>
                              <CardText>{t("Congratulations")}</CardText>
                              <Alert color="warning" role="alert">
                                <i className="mdi mdi-alert-outline me-2"></i>
                                {t("Action required - select design")}
                              </Alert>
                              <CardText>{t("Select Design Info 1")}</CardText>
                              <CardText>{t("Select Design Info 2")}</CardText>
                            </div>
                            <CardText className="pt-3">
                              <Link to="/select-design">
                                <Button
                                  color="secondary"
                                  className="btn btn-primary waves-effect waves-light mt-1"
                                >
                                  {t("Select Design")}
                                </Button>
                              </Link>
                              <Button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light mt-1  ms-2"
                                onClick={() => onSkipTemplate()}
                                style={{ width: "165px" }}
                              >
                                {skipTemplateLoading && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    style={{ marginBottom: "-2px" }}
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                                {!skipTemplateLoading &&
                                  t("Dont use a template")}
                              </Button>
                            </CardText>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xl={4}>
                  <CustomizationFormProgress />
                </Col>
              </Row>
            )}
            {project?.status === "new" && (
              <Row>
                <Col xl={8}>
                  <Card style={{ height: "calc(100% - 24px)" }}>
                    <Row className="no-gutters align-items-strech h-100">
                      <Col xl={6} className="overflow-hidden">
                        <CardImg
                          className="img-fluid cardimg-template-preview"
                          src={
                            project.using_template
                              ? project.template.image
                              : doMore
                          }
                          alt="Certified Pixel"
                        />
                      </Col>
                      <Col xl={6}>
                        <CardBody className="h-100">
                          <div className="d-flex flex-column justify-content-between h-100">
                            <div className="d-flex flex-column">
                              <CardTitle>{t("Submit Website Data")}</CardTitle>
                              {project.using_template && (
                                <>
                                  <CardText>
                                    {t("You have chosen the design")}
                                  </CardText>
                                  <CardText>
                                    {t("Your currently selected design")}:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {project.template.name}
                                    </span>
                                  </CardText>
                                  <CardText>
                                    <a
                                      href={project.template.preview_url}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="text-primary"
                                    >
                                      {t(
                                        "Open a preview of selected design here"
                                      )}{" "}
                                      <img
                                        src={newTab}
                                        alt="Open new tab"
                                        style={{ width: "15px" }}
                                      />
                                    </a>
                                  </CardText>
                                </>
                              )}
                              {!project.using_template && (
                                <>
                                  <CardText>
                                    {t(
                                      "We will create a custom design for your project"
                                    )}
                                  </CardText>
                                  <CardText>
                                    {t(
                                      "If you decide to use a design template instead"
                                    )}
                                  </CardText>
                                </>
                              )}
                              <Alert color="warning" role="alert">
                                <i className="mdi mdi-alert-outline me-2"></i>
                                {t("Action required submit data")}
                              </Alert>
                              <CardText>
                                {t("Submit data paragraph 1")}
                              </CardText>
                              <CardText>
                                {t("Submit data paragraph 2")}
                              </CardText>
                            </div>
                            <CardText className="pt-3">
                              <Link to="/select-design">
                                <Button
                                  color="secondary"
                                  className="btn btn-primary waves-effect waves-light mt-1"
                                >
                                  {t("Change Design")}
                                </Button>
                              </Link>
                              <Link to="/submit-data">
                                <Button
                                  color="primary"
                                  className="btn btn-primary waves-effect waves-light mt-1  ms-2"
                                >
                                  {t("Submit Project Data")}
                                </Button>
                              </Link>
                            </CardText>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xl={4}>
                  <CustomizationFormProgress />
                </Col>
              </Row>
            )}
            {project?.status === "building" && (
              <Row>
                <Card
                  className="flex-column flex-xl-row"
                  style={{ height: "calc(100% - 24px)" }}
                >
                  <Row className="no-gutters align-items-strech h-100">
                    <Col xl={4} className="overflow-hidden">
                      <CardImg
                        className="img-fluid cardimg-template-preview"
                        src={
                          project.using_template
                            ? project.template.image
                            : workCoding
                        }
                        alt={
                          project.using_template
                            ? project.template.name
                            : "Work in progress"
                        }
                      />
                    </Col>
                    <Col xl={8}>
                      <CardBody className="h-100">
                        <div className="d-flex flex-column justify-content-between h-100">
                          <div className="d-flex flex-column">
                            <CardTitle>
                              {t("We Are Working on Your Website")}
                            </CardTitle>
                            <div
                              className="d-flex flex-column flex-lg-row"
                              style={{ maxWidth: "640px" }}
                            >
                              <Col sm="4">
                                <div className="d-flex mt-3">
                                  <div className="avatar-xs me-3">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18 mt-1">
                                      <i className="mdi mdi-monitor-dashboard" />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ">
                                    <p className="text-muted mb-1">
                                      {t("Design")}
                                    </p>
                                    <h6>
                                      {project.using_template
                                        ? project.template.name
                                        : t("Custom design")}
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                              <Col sm="4">
                                <div className="d-flex  mt-3">
                                  <div className="avatar-xs me-3">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18 mt-1">
                                      <i className="mdi mdi-calendar-text" />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="text-muted mb-1">
                                      {t("Data Received")}
                                    </p>
                                    <h6>
                                      {dateShort(dateOfCustomizationSubmitted)},{" "}
                                      {dateOfCustomizationSubmitted.getFullYear()}
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                              <Col sm="4">
                                <div className="d-flex  mt-3">
                                  <div className="avatar-xs me-3">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18 mt-1">
                                      <i className="mdi mdi-list-status" />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="text-muted mb-1">
                                      {t("Status")}
                                    </p>
                                    <h6>{t("In development")}</h6>
                                  </div>
                                </div>
                              </Col>
                            </div>
                            <CardText className="mt-4">
                              {t("Great news! Work on your website has begun")}
                            </CardText>
                            <Alert color="primary" role="alert">
                              <i className="mdi mdi-alert-outline me-2"></i>
                              {t("Please check this page regularly")}
                            </Alert>
                          </div>
                          <CardText className="pt-3">
                            <Link to="/tickets">
                              <Button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light mt-1"
                              >
                                {t("Go to Inbox")}
                              </Button>
                            </Link>
                          </CardText>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Row>
            )}
            <Row>
              <Col xl="8">
                {project?.status === "finished" && (
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <h4 className="card-title mb-3">
                            Webové stránky připraveny ke spuštění
                          </h4>
                          <div>
                            <p>
                              Váš projekt byl dokončen. Webové stránky můžete
                              navštívit na adrese{" "}
                              <a
                                href={project.staging_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {project.staging_url}
                              </a>
                            </p>
                            <p>
                              Pro spuštění webu na vaší doméně proveďte
                              nastavení DNS u vašeho registrátora. Po několika
                              hodinách od správného nastavení DNS záznamů budou
                              stránky přístupné na této doméně.
                            </p>
                            <div className="d-flex align-items-center mb-3 mt-4">
                              <div className="avatar-xs me-3">
                                <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                  <i className="mdi mdi-dns-outline" />
                                </span>
                              </div>
                              <h5 className="font-size-14 mb-0">
                                Nastavení DNS
                              </h5>
                            </div>
                            <p>
                              Pro nasměrování vaší domény na nový web proveďte
                              následující kroky.
                            </p>
                            <ul>
                              <li>
                                Přihlaste se do svého účtu u registrátora vaší
                                domény ({project.domain}).
                              </li>
                              <li>Přejděte do sekce pro správu DNS záznamů.</li>
                              <li>
                                Vytvořte A záznam s názvem hostitele '@' a
                                hodnotou '75.2.70.75'.
                              </li>
                              <li>
                                Vytvořte A záznam s názvem hostitele '@' a
                                hodnotou '99.83.190.102'.
                              </li>
                              <li>
                                Vytvořte CNAME záznam s názvem hostitele 'www' a
                                hodnotou 'proxy-ssl.webflow.com'.
                              </li>
                              <li>
                                Vytvořte TXT záznam s názvem hostitele
                                '_webflow' a hodnotou '
                                {project.domain_one_time_verification}
                                '.
                              </li>
                            </ul>
                            <p>
                              Pokud budete využívat email hosting od nás,
                              přidejte ještě tyto záznamy.
                            </p>
                            <ul>
                              <li>
                                Vytvořte MX záznam s názvem hostitele '@',
                                prioritou 10 a hodnotou 'everest.mxrouting.net'.
                              </li>
                              <li>
                                Vytvořte MX záznam s názvem hostitele '@',
                                prioritou 20 a hodnotou
                                'everest-relay.mxrouting.net'.
                              </li>
                            </ul>
                            <p>
                              Budete-li potřebovat s nastavením DNS pomoci,{" "}
                              <Link to="/tickets">
                                neváhejte nás kontaktovat
                              </Link>
                              .
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {project?.status === ("finished" || "live") && (
                  <Row>
                    <CardProject
                      project={project}
                      subscription={subscription}
                    />
                  </Row>
                )}
              </Col>
              {project?.status === ("finished" || "live") && (
                <Col xl="4">
                  <CardRequestsSmall requests={project?.paid_requests} />
                </Col>
              )}
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default withTranslation("dashboard")(Dashboard)
