import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Import Language dropdown
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { requestReset } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo/logo-symbol-black-space.png"

//i18n
import { withTranslation } from "react-i18next"

const ForgetPasswordPage = ({ t }) => {
  //meta title
  document.title = t("Title Forgot Password")

  const dispatch = useDispatch()

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("Invalid email"))
        .required(t("Please enter your email address")),
    }),
    onSubmit: values => {
      dispatch(requestReset(values))
    },
  })

  const forgotPasswordData = createSelector(
    state => state.ForgetPassword,
    forgotPassword => ({
      requestLoading: forgotPassword.requestLoading,
      requestError: forgotPassword.requestError,
      requestSuccess: forgotPassword.requestSuccess,
    })
  )

  const { requestLoading, requestError, requestSuccess } =
    useSelector(forgotPasswordData)

  // logout on load
  useEffect(() => {
    localStorage.removeItem("authUser")
  }, [])

  useEffect(() => {
    if (requestSuccess) form.resetForm()
  }, [requestSuccess])

  return (
    <React.Fragment>
      <div className="home-btn">
        <LanguageDropdown />
      </div>
      <div className="account-pages my-5 pt-4 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t("Forgot Password")}</h5>
                        <p>
                          {t("Fill in the form to recover your credentials")}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <p>{t("Enter your email address and we")}</p>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        form.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{t("Email")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={t("Enter email")}
                          type="email"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.email || ""}
                          invalid={
                            form.touched.email && form.errors.email
                              ? true
                              : false
                          }
                        />
                        {form.touched.email && form.errors.email ? (
                          <FormFeedback type="invalid">
                            {form.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {requestLoading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                style={{ marginBottom: "-2px" }}
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {!requestLoading && t("Send")}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                    {requestError && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(requestError)}
                      </Alert>
                    )}
                    {requestSuccess && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {t("We have sent you instructions")}
                      </Alert>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("Go back to")}{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {t("Login")}
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Certified Pixel</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withTranslation("authentication")(withRouter(ForgetPasswordPage))
