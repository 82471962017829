import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  Button,
  Alert,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

// Import Actions
import { getPaymentRequestsData } from "store/actions"

//Import Components
import { PaymentRequests } from "../../components/Admin/PaymentRequests"

const DashboardAdmin = () => {
  //meta title
  document.title = "Dashboard | Admin Back-office | Certified Pixel"

  // useEffect(() => {
  //   if (!loading) {
  //     const progressPoint2 = document.getElementById("progress-point-2")
  //     const progressPoint3 = document.getElementById("progress-point-3")

  //     if (project?.status === "building") {
  //       setBarProgress(50)

  //       progressPoint2.classList.replace("btn-secondary", "btn-primary")
  //     } else if (project?.status === "finished") {
  //       setBarProgress(100)

  //       progressPoint2.classList.replace("btn-secondary", "btn-primary")
  //       progressPoint3.classList.replace("btn-secondary", "btn-primary")
  //     }
  //   }
  // }, [loading])

  const loading = false

  return (
    <React.Fragment>
      {!loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboards"
              breadcrumbItems={[{ link: "/", item: "Dashboard" }]}
            />
            <Row>
              <Col xl="12">
                <PaymentRequests />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default DashboardAdmin
