import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Certified Pixel</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                <a
                  href="certifiedpixel.com"
                  target="_blank"
                  style={{ color: "var(--bs-footer-color)" }}
                >
                  certifiedpixel.com
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
