import {
  GET_PAYMENT_REQUESTS_DATA,
  GET_PAYMENT_REQUESTS_DATA_SUCCESS,
  GET_PAYMENT_REQUESTS_DATA_FAILED,
  MARK_PROFORMA_PAID,
  MARK_PROFORMA_PAID_SUCCESS,
  MARK_PROFORMA_PAID_FAILED,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED,
} from "./actionTypes"

const initialState = {
  loadingPaymentRequests: false,
  paymentRequests: null,
  loadingMarkProformaPaid: false,
  loadingCreateAccount: false,
}

const admin = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_REQUESTS_DATA:
      return {
        ...state,
        loadingPaymentRequests: true,
      }

    case GET_PAYMENT_REQUESTS_DATA_SUCCESS:
      return {
        ...state,
        loadingPaymentRequests: false,
        paymentRequests: action.payload,
      }

    case GET_PAYMENT_REQUESTS_DATA_FAILED:
      return {
        ...state,
        loadingPaymentRequests: false,
      }

    case MARK_PROFORMA_PAID:
      return {
        ...state,
        loadingMarkProformaPaid: true,
      }

    case MARK_PROFORMA_PAID_SUCCESS:
      let updatedPaymentRequests = state.paymentRequests.map(pr => {
        if (pr.id === action.payload) {
          return {
            ...pr,
            paid: true,
          }
        } else return pr
      })

      return {
        ...state,
        loadingMarkProformaPaid: false,
        paymentRequests: updatedPaymentRequests,
      }

    case MARK_PROFORMA_PAID_FAILED:
      return {
        ...state,
        loadingMarkProformaPaid: false,
      }

    case CREATE_ACCOUNT:
      return {
        ...state,
        loadingCreateAccount: true,
      }

    case CREATE_ACCOUNT_SUCCESS:
      if (true) {
        console.log("CREATE_ACCOUNT_SUCCESS action.payload", action.payload)

        let updatedPaymentRequests = state.paymentRequests.map(pr => {
          if (pr.id === action.payload.id) {
            return {
              ...pr,
              account: {
                id: action.payload.accountId,
                username: action.payload.username,
              },
            }
          } else return pr
        })

        console.log({ updatedPaymentRequests })

        return {
          ...state,
          loadingCreateAccount: false,
          paymentRequests: updatedPaymentRequests,
        }
      }

    case CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        loadingCreateAccount: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default admin
