import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Import Language dropdown
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser } from "../../store/actions"

// import images
import profile from "assets/images/icons/icons8-one-time-password.svg"
import logo from "../../assets/images/logo/logo-symbol-black-space.png"

//i18n
import { withTranslation } from "react-i18next"

const Login = ({ t }) => {
  //meta title
  document.title = t("Title Login")

  const dispatch = useDispatch()

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      error: login.error,
      loading: login.loading,
    })
  )

  const { error, loading } = useSelector(LoginProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t("Please enter username")),
      password: Yup.string().required(t("Please enter your password")),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, loading))
    },
  })

  // logout on load
  useEffect(() => {
    localStorage.removeItem("authUser")
  }, [])

  return (
    <React.Fragment>
      <div className="home-btn">
        <LanguageDropdown />
      </div>
      <div className="account-pages my-5 pt-4 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Certified Pixel</h5>
                        <p>{t("Sign in to continue to the back office")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 d-flex align-self-center justify-content-end">
                      <img
                        src={profile}
                        alt=""
                        className="img-fluid"
                        style={{ maxHeight: "110px" }}
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{t(error)}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">{t("Username")}</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder={t("Enter username")}
                          type="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{t("Password")}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder={t("Enter Password")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {t("Remember me")}
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              style={{ marginBottom: "-2px" }}
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          {!loading && t("Log In")}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {t("Forgot your password or username")}
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                <p>© {new Date().getFullYear()} Certified Pixel</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation("authentication")(withRouter(Login))

Login.propTypes = {
  history: PropTypes.object,
}
