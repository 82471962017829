import React, { useState, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
  CardBody,
  CardTitle,
  Table,
  Button,
  UncontrolledAlert,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Import Actions
import { getTemplates, webflowSync } from "store/actions"

const Templates = props => {
  //meta title
  document.title = "Templates | Back-office | Certified Pixel"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const templatesData = createSelector(
    state => state.templates,
    templatesData => ({
      loadingTemplates: templatesData.loadingTemplates,
      templates: templatesData.templates,
      loadingWebflowSync: templatesData.loadingWebflowSync,
      webflowSyncError: templatesData.webflowSyncError,
    })
  )

  const { loadingTemplates, templates, loadingWebflowSync, webflowSyncError } =
    useSelector(templatesData)

  const onAddTemplate = () => {
    navigate("/add-template")
  }

  const onWebflowSync = () => {
    if (loadingWebflowSync) return

    dispatch(webflowSync())
  }

  // get templates data on load
  useEffect(() => {
    dispatch(getTemplates())
  }, [])

  const list = templates.map(template => {
    const imageFilename = template.image.split("/").pop()

    const tags = template.template_tags
      .filter(tag => tag.tag.tag !== "CMS" && tag.tag.tag !== "Ecommerce")
      .map(tag => {
        return (
          <div className="templates-tag" key={tag.tag.tag}>
            {tag.tag.tag}
          </div>
        )
      })

    const editLink = `/edit-template?id=${template.id}`

    return (
      <tr key={template.id}>
        <td>
          <a href={template.preview_url} target="_blank" rel="noreferrer">
            <img
              src={template.image}
              alt={template.name}
              style={{ width: "150px" }}
            />
          </a>
        </td>
        <td>{imageFilename}</td>
        <th scope="row">{template.name}</th>
        <td>{template.category}</td>
        <td>
          <div className="templates-tag-list">{tags}</div>
        </td>
        <td>{template.ecommerce ? "Yes" : "No"}</td>
        <td>${template.marketplace_price}</td>
        <td>
          <div className="templates-table-controls">
            <Link to={editLink} className="templates-link">
              <i className="bx bx-edit-alt bx-sm"></i>
            </Link>
            <a
              href={template.marketplace_url}
              target="_blank"
              rel="noreferrer"
              className="templates-link"
            >
              <i className="bx bx-store-alt bx-sm"></i>
            </a>
          </div>
        </td>
      </tr>
    )
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Management"
            titleLink="/management"
            breadcrumbItems={[{ link: "", item: "Templates" }]}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="card-split-heading">
                    <CardTitle className="h4">Design templates</CardTitle>
                    <div className="card-split-heading-content">
                      <Button
                        color="success"
                        className="btn btn-primary waves-effect waves-light mt-1 mb-3"
                        onClick={() => onWebflowSync()}
                        style={{ minWidth: "120px" }}
                      >
                        {loadingWebflowSync && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {!loadingWebflowSync && "Webflow Sync"}
                      </Button>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light mt-1 mb-3 ms-2"
                        onClick={() => onAddTemplate()}
                      >
                        + Add template
                      </Button>
                    </div>
                  </div>
                  {webflowSyncError && (
                    <Row className="mb-3">
                      <div style={{ maxWidth: "500px" }}>
                        <UncontrolledAlert
                          color="danger"
                          role="alert"
                          style={{
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          {webflowSyncError}
                        </UncontrolledAlert>
                      </div>
                    </Row>
                  )}
                  <div className="table-responsive">
                    <Table className="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th style={{ width: "200px" }}></th>
                          <th>Image file</th>
                          <th>Name</th>
                          <th>Category</th>
                          <th>Tags</th>
                          <th>Ecommerce</th>
                          <th>Webflow price</th>
                          <th></th>
                        </tr>
                      </thead>
                      {!loadingTemplates && (
                        <tbody style={{ verticalAlign: "middle" }}>
                          {list}
                        </tbody>
                      )}
                      {loadingTemplates && (
                        <tbody>
                          <tr>
                            <td>
                              <div
                                className="w-100 mt-5"
                                style={{ height: "300px" }}
                              >
                                <Spinner
                                  color="primary"
                                  className="position-absolute top-50 start-50"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Templates
