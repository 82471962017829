import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

//Account
import account from "./account/reducer"

//Customization
import customization from "./customization/reducer"

//Tickets
import tickets from "./tickets/reducer"

//Templates
import templates from "./templates/reducer"

//Admin
import admin from "./admin/reducer"

//Requests
import requests from "./requests/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  customization,
  account,
  tickets,
  templates,
  admin,
  requests,
})

export default rootReducer
