import engFlag from "../assets/images/icons/icons8-great-britain.svg"
import czechFlag from "../assets/images/icons/icons8-czech-republic.svg"

const languages = {
  cz: {
    label: "Czech",
    flag: czechFlag,
  },
  en: {
    label: "English",
    flag: engFlag,
  },
}

export default languages
