import {
  GET_REQUESTS,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FAILED,
  GET_REQUEST_DETAIL,
  GET_REQUEST_DETAIL_SUCCESS,
  GET_REQUEST_DETAIL_FAILED,
} from "./actionTypes"

const initialState = {
  requests: [],
  loadingRequests: false,
  requestDetail: null,
  loadingRequestDetail: false,
}

const requests = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUESTS:
      return {
        ...state,
        loadingRequests: true,
      }

    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingRequests: false,
        requests: action.payload.data,
      }

    case GET_REQUESTS_FAILED:
      return {
        ...state,
        loadingRequests: false,
      }

    case GET_REQUEST_DETAIL:
      return {
        ...state,
        loadingRequestDetail: true,
      }

    case GET_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        loadingRequestDetail: false,
        requestDetail: action.payload.data,
      }

    case GET_REQUEST_DETAIL_FAILED:
      return {
        ...state,
        loadingRequestDetail: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default requests
