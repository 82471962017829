import {
  GET_TICKET_LIST,
  GET_TICKET_LIST_SUCCESS,
  GET_TICKET_LIST_FAILED,
  CHANGE_TAB,
  CLOSE_TICKET_FORM,
  GET_TICKET_DETAIL,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_DETAIL_FAILED,
  CLOSE_TICKET_DETAIL,
  OPEN_CREATE_TICKET_FORM,
  UPLOAD_ATTACHMENT,
  UPLOAD_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_FAILED,
  REMOVE_ATTACHMENT,
  SUBMIT_MESSAGE,
  SUBMIT_MESSAGE_SUCCESS,
  SUBMIT_MESSAGE_FAILED,
  TOGGLE_STAR,
  ADD_MESSAGE,
  SELECT_LABEL,
  DELETE_TICKETS,
  GET_ALL_ACCOUNTS_SUCCESS,
  CLOSE_TICKET,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_FAILED,
  GET_UNREAD_COUNT_SUCCESS,
} from "./actionTypes"

import { compareObjects } from "../../common/functions"

const initialState = {
  tickets: [],
  loadingList: false,
  tab: 1,
  ticketDetail: null,
  ticketDetailLoading: false,
  activeUnread: 0,
  closedUnread: 0,
  createTicketForm: false,
  attachmentUploadLoading: false,
  attachmentError: null,
  attachments: [],
  submitTicketLoading: false,
  submitTickerError: null,
  labelFilter: "none",
  accountSelection: [],
  closeTicketLoading: false,
}

const tickets = (state = initialState, action) => {
  switch (action.type) {
    case GET_TICKET_LIST:
      return {
        ...state,
        loadingList: true,
      }

    case GET_TICKET_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        tickets: action.payload.data,
        activeUnread: action.payload.activeUnread,
        closedUnread: action.payload.closedUnread,
      }

    case GET_TICKET_LIST_FAILED:
      return {
        ...state,
        loadingList: false,
      }

    case CHANGE_TAB:
      return {
        ...state,
        tab: action.payload,
        ticketDetail: null,
        createTicketForm: false,
        attachments: [],
      }

    case CLOSE_TICKET_FORM:
      return {
        ...state,
        tab: 1,
        createTicketForm: false,
        attachments: [],
      }

    case GET_TICKET_DETAIL:
      return {
        ...state,
        ticketDetailLoading: true,
        submitTickerError: null,
      }

    case GET_TICKET_DETAIL_SUCCESS:
      if (true) {
        const updatedTickets = state.tickets.map(ticket => {
          if (ticket.id === action.payload.data.id) {
            return { ...ticket, read: true }
          } else return ticket
        })

        return {
          ...state,
          ticketDetailLoading: false,
          ticketDetail: action.payload.data,
          tickets: updatedTickets,
          activeUnread: action.payload.activeUnread,
          closedUnread: action.payload.closedUnread,
        }
      }

    case GET_TICKET_DETAIL_FAILED:
      return {
        ...state,
        ticketDetailLoading: false,
      }

    case CLOSE_TICKET_DETAIL:
      return {
        ...state,
        ticketDetail: null,
      }

    case OPEN_CREATE_TICKET_FORM:
      return {
        ...state,
        createTicketForm: true,
        ticketDetail: null,
        attachmentError: null,
        attachments: [],
        submitTickerError: null,
      }

    case UPLOAD_ATTACHMENT:
      return {
        ...state,
        attachmentUploadLoading: true,
        attachmentError: null,
      }

    case UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachmentUploadLoading: false,
        attachments: [
          ...state.attachments,
          { file: action.payload.fileName, name: action.payload.originalName },
        ],
      }

    case UPLOAD_ATTACHMENT_FAILED:
      return {
        ...state,
        attachmentUploadLoading: false,
        attachmentError: action.payload,
      }

    case REMOVE_ATTACHMENT:
      const newAttachments = state.attachments.filter(attachment => {
        return !compareObjects(attachment, action.payload)
      })

      return {
        ...state,
        attachments: newAttachments,
      }

    case SUBMIT_MESSAGE:
      return {
        ...state,
        submitTicketLoading: true,
      }

    case SUBMIT_MESSAGE_SUCCESS:
      return {
        ...state,
        submitTicketLoading: false,
        submitTickerError: null,
        createTicketForm: false,
        ticketDetail: action.payload.close ? null : state.ticketDetail,
        attachments: [],
        tab: 1,
        labelFilter: "none",
      }

    case SUBMIT_MESSAGE_FAILED:
      return {
        ...state,
        submitTicketLoading: false,
        submitTickerError: action.payload,
      }

    case TOGGLE_STAR:
      if (true) {
        const property = action.payload.agent ? "starred_agent" : "starred_user"

        const updatedTickets = state.tickets.map(ticket => {
          if (ticket.id === action.payload.id) {
            return { ...ticket, [property]: action.payload.star }
          } else return ticket
        })

        let updatedTicketDetail = state.ticketDetail

        if (state.ticketDetail) {
          updatedTicketDetail = {
            ...updatedTicketDetail,
            [property]: action.payload.star,
          }
        }

        return {
          ...state,
          tickets: updatedTickets,
          ticketDetail: updatedTicketDetail,
        }
      }

    case ADD_MESSAGE:
      if (state.ticketDetail) {
        if (state.ticketDetail.id === action.payload.ticketId) {
          const newMessages = [
            ...state.ticketDetail.messages,
            action.payload.message,
          ]

          return {
            ...state,
            ticketDetail: { ...state.ticketDetail, messages: newMessages },
          }
        }
      }

      return {
        ...state,
      }

    case SELECT_LABEL:
      return {
        ...state,
        labelFilter: action.payload,
      }

    case DELETE_TICKETS:
      const newTickets = state.tickets.filter(
        ticket => !action.payload.includes(ticket.id)
      )

      return {
        ...state,
        tickets: newTickets,
      }

    case GET_ALL_ACCOUNTS_SUCCESS:
      const selection = action.payload.map(account => {
        return { label: account.username, value: account.id }
      })

      return {
        ...state,
        accountSelection: [{ options: selection }],
      }

    case CLOSE_TICKET:
      return {
        ...state,
        closeTicketLoading: true,
      }

    case CLOSE_TICKET_SUCCESS:
      return {
        ...state,
        closeTicketLoading: false,
        attachments: [],
        ticketDetail: null,
        tab: 1,
        labelFilter: "none",
      }

    case CLOSE_TICKET_FAILED:
      return {
        ...state,
        closeTicketLoading: false,
      }

    case GET_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        activeUnread: action.payload.active,
        closedUnread: action.payload.closed,
      }

    default:
      return {
        ...state,
      }
  }
}

export default tickets
