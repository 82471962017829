import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardSubtitle,
  CardTitle,
  Spinner,
  Badge,
  Table,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// actions
import { getBillingData, cancelSubscription } from "../../store/actions"

//Import images
import amexCardIcon from "../../assets/images/icons/icons8-amex.svg"
import masterCardIcon from "../../assets/images/icons/icons8-mastercard.svg"
import visaCardIcon from "../../assets/images/icons/icons8-visa.svg"
import creditCardIcon from "../../assets/images/icons/icons8-magnetic-card.svg"
import pdfIcon from "../../assets/images/icons/icons8-pdf.svg"

//Import functions
import { dateShort, timeAMPM, time24 } from "../../common/functions"

//i18n
import { withTranslation } from "react-i18next"

const Billing = ({ t }) => {
  //meta title
  document.title = "Billing | Back-office | Certified Pixel"

  const dispatch = useDispatch()

  const billing = createSelector(
    state => state.account,
    settingsData => {
      return {
        billingDataLoading: settingsData.billingDataLoading,
        billingData: settingsData.billingData,
        cancelLoading: settingsData.cancelLoading,
        cancelError: settingsData.cancelError,
        cancelSuccess: settingsData.cancelSuccess,
      }
    }
  )

  const {
    billingDataLoading,
    billingData,
    cancelLoading,
    cancelError,
    cancelSuccess,
  } = useSelector(billing)

  const [cancelForm, setCancelForm] = useState(false)
  const [cancelAlertVisible, setCancelAlertVisible] = useState(true)

  // load settings data
  useEffect(() => {
    dispatch(getBillingData())
  }, [])

  // handle subscription cancelation
  const onCancel = () => {
    if (cancelLoading) return

    setCancelAlertVisible(true)
    dispatch(cancelSubscription())
  }

  // close password form on success change
  useEffect(() => {
    if (cancelSuccess) setCancelForm(false)
    dispatch(getBillingData())
  }, [cancelSuccess])

  let subscriptionText = ""
  let cardIcon = creditCardIcon
  let cardDescription = ""
  let cardExpiry = ""
  let subscriptionExpires = ""

  if (billingData) {
    if (billingData.subscribed) {
      if (billingData.plan === "starter") {
        subscriptionText = `${t("Webdesign Starter")} ${
          billingData.starter_monthly
        } Kč ${t("Per month")} + ${t("VAT")}`
      } else if (billingData.plan === "premium") {
        subscriptionText = `${t("Webdesign Premium")} ${
          billingData.premium_monthly
        } Kč ${t("Per month")} + ${t("VAT")}`
      } else if (billingData.plan === "ecommerce") {
        subscriptionText = `${t("Webdesign eCommerce")} ${
          billingData.ecommerce_monthly
        } Kč ${t("Per month")} + ${t("VAT")}`
      }

      switch (billingData.card_brand) {
        case "visa":
          cardIcon = visaCardIcon
          cardDescription = "Visa ending in " + billingData.card_ending
          break
        case "mastercard":
          cardIcon = masterCardIcon
          cardDescription = "MasterCard ending in " + billingData.card_ending

          break
        case "amex":
          cardIcon = amexCardIcon
          cardDescription =
            "American Express ending in " + billingData.card_ending

          break
      }

      cardExpiry = `Expiry ${billingData.card_exp_month}/${billingData.card_exp_year}`
    } else {
      const now = new Date()
      const subscriptionExpired = new Date(billingData.expires)

      if (subscriptionExpired >= now) {
        subscriptionExpires =
          dateShort(subscriptionExpired) +
          ", " +
          subscriptionExpired.getFullYear()

        subscriptionText =
          t("Your subscription will expire on") +
          " " +
          subscriptionExpires +
          "."
      } else {
        subscriptionText = t("You currently do not have an active subscription")
      }
    }
  }

  const list = (billingData?.payments || []).map(
    ({
      id,
      created_at,
      item,
      amount,
      tax,
      payment_request_proforma,
      idoklad_invoice_id,
      invoice_number,
    }) => {
      const lang = localStorage.getItem("I18N_LANGUAGE") || "en"

      const date = new Date(created_at)

      const paymentTime =
        lang === "en"
          ? dateShort(date) + " - " + timeAMPM(date)
          : dateShort(date) + " - " + time24(date)

      let itemText

      switch (item) {
        case "membership-starter":
          itemText = t("Webdesign Starter")
          break
        case "membership-premium":
          itemText = t("Webdesign Premium")
          break
        case "membership-ecommerce":
          itemText = t("Webdesign eCommerce")
          break
      }

      const proformaLink =
        process.env.REACT_APP_AWS_DOCUMENTS_FOLDER +
        "/" +
        "proforma-" +
        payment_request_proforma.proforma_number +
        "-" +
        payment_request_proforma.idoklad_proforma_id +
        ".pdf"

      const invoiceLink =
        process.env.REACT_APP_AWS_DOCUMENTS_FOLDER +
        "/" +
        "invoice-" +
        invoice_number +
        "-" +
        idoklad_invoice_id +
        ".pdf"

      return (
        <tr key={id}>
          <td>{paymentTime}</td>
          <td>{itemText}</td>
          <td>{(amount + tax).toFixed(2)} Kč</td>
          <td>
            <a href={proformaLink} download>
              <img
                src={pdfIcon}
                alt="Download PDF"
                style={{ width: "24px", minWidth: "24px" }}
              />
            </a>
          </td>
          <td>
            <a href={invoiceLink} download>
              <img
                src={pdfIcon}
                alt="Download PDF"
                style={{ width: "24px", minWidth: "24px" }}
              />
            </a>
          </td>
        </tr>
      )
    }
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={t("Dashboard")}
            titleLink="/dashboard"
            breadcrumbItems={[{ link: "", item: t("Billing") }]}
          />
          <Card>
            <CardBody>
              <CardTitle className="h4 pb-4">
                {t("Your subscription")}
              </CardTitle>
              {billingDataLoading && (
                <div
                  className="w-100 d-flex justify-content-center"
                  style={{ height: "110px", paddingTop: "15px" }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              )}
              {!billingDataLoading && (
                <>
                  <Row className="mb-1">
                    <div className="d-flex align-items-center">
                      {billingData?.subscribed && (
                        <>
                          <Badge className="badge-soft-success p-2">
                            {t("Active")}
                          </Badge>
                          <p className="mb-0 ms-2">{subscriptionText}</p>
                        </>
                      )}
                      {!billingData?.subscribed && (
                        <>
                          <Badge className="badge-soft-warning p-2">
                            {t("Canceled")}
                          </Badge>
                          <p className="mb-0 ms-2">{subscriptionText}</p>
                        </>
                      )}
                    </div>
                    {billingData?.subscribed && !cancelForm && (
                      <>
                        {billingData.method === "creditcard" && (
                          <div>
                            <div
                              className="d-inline-flex mt-3"
                              style={{
                                padding: "10px 12px",
                                backgroundColor: "rgb(80, 165, 241, 0.16)",
                                borderRadius: "4px",
                                width: "fit-content",
                              }}
                            >
                              <div>
                                <img
                                  src={cardIcon}
                                  alt={billingData?.card_brand}
                                />
                              </div>
                              <div
                                className="d-flex flex-column ps-2 pe-2"
                                style={{
                                  paddingTop: "8px",
                                  paddingBottom: "8px",
                                }}
                              >
                                <div>{cardDescription}</div>
                                <div style={{ color: "#7a7a7a" }}>
                                  <small>{cardExpiry}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="mt-4">
                          <button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            style={{ minWidth: "60px" }}
                            onClick={() => setCancelForm(!cancelForm)}
                          >
                            {t("Cancel Subscription")}
                          </button>
                        </div>
                      </>
                    )}
                  </Row>
                  {cancelForm && (
                    <Row>
                      <p className="mt-3 mb-2">
                        {t("Do you really want to cancel")}
                      </p>
                      <div>
                        <Alert
                          color="warning"
                          role="alert"
                          style={{ width: "fit-content" }}
                        >
                          <i className="mdi mdi-alert-outline me-2"></i>
                          {t("Your website will become unavailable")}
                        </Alert>
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-secondary waves-effect waves-light"
                          type="submit"
                          onClick={() => setCancelForm(!cancelForm)}
                        >
                          {t("Keep Plan")}
                        </button>
                        <button
                          className="btn btn-danger waves-effect waves-light ms-2"
                          type="submit"
                          style={{ minWidth: "160px" }}
                          onClick={onCancel}
                        >
                          {cancelLoading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              style={{ marginBottom: "-2px" }}
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          {!cancelLoading && t("Cancel Subscription")}
                        </button>
                      </div>
                    </Row>
                  )}
                  <Row>
                    <div className="mt-3">
                      {cancelError && (
                        <Alert
                          color="danger"
                          className="d-inline-block"
                          isOpen={cancelAlertVisible}
                          toggle={() => setCancelAlertVisible(false)}
                        >
                          There was an error canceling your subscription. Please
                          contact us as soon as possible.
                        </Alert>
                      )}
                      {cancelSuccess && (
                        <Alert
                          color="success"
                          className="d-inline-block"
                          isOpen={cancelAlertVisible}
                          toggle={() => setCancelAlertVisible(false)}
                        >
                          {t(
                            "Your subscription has been successfully canceled."
                          )}
                        </Alert>
                      )}
                    </div>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
          <Card style={{ maxWidth: "700px" }}>
            <CardBody>
              <div className="d-flex justify-content-between">
                <CardTitle className="h4">{t("Billing history")}</CardTitle>
              </div>
              {!billingDataLoading && (
                <>
                  <CardSubtitle className="card-title-desc"></CardSubtitle>
                  <div className="table-responsive">
                    <Table className="table mb-0 align-middle">
                      <thead className="table-light">
                        <tr>
                          <th>{t("Date")}</th>
                          <th>{t("Item")}</th>
                          <th>{t("Amount")}</th>
                          <th>{t("Proforma")}</th>
                          <th>{t("Invoice")}</th>
                        </tr>
                      </thead>
                      <tbody>{list}</tbody>
                    </Table>
                    {list.length === 0 && (
                      <h5 className="text-center mt-5 mb-4">
                        {t("No payments")}
                      </h5>
                    )}
                  </div>
                </>
              )}
              {billingDataLoading && (
                <div
                  className="w-100 d-flex justify-content-center align-items-center"
                  style={{ height: "300px" }}
                >
                  <Spinner color="primary" />
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation("settings")(withRouter(Billing))
