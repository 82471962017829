import React, { useState, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
  CardBody,
  CardTitle,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
  Button,
} from "reactstrap"
import Select from "react-select"
import { useNavigate } from "react-router-dom"
//import { crypto } from "crypto"
//import { md5File } from "md5-file"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Import Actions
import {
  getTemplateData,
  uploadImage,
  removeImage,
  addTemplate,
  resetTemplateAdded,
} from "store/actions"

//Import Constants
import { awsS3UserUploadBucketURL } from "../../constants/constants"

//Import Images
import removeIconRed from "../../assets/images/icons8-waste-24-red.png"
import removeIconGrey from "../../assets/images/icons/icons8-waste.svg"
import loadingIcon from "../../assets/images/icons8-loading.gif"

const AddTemplate = props => {
  //meta title
  document.title = "Add Template | Back-office | Certified Pixel"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const templateData = createSelector(
    state => state.templates,
    templateData => ({
      tagOptions: templateData.tagOptions,
      imageUploadLoading: templateData.imageUploadLoading,
      imageError: templateData.imageError,
      image: templateData.image,
      addTemplateLoading: templateData.addTemplateLoading,
      addError: templateData.addError,
      templateAdded: templateData.templateAdded,
    })
  )

  const {
    tagOptions,
    imageUploadLoading,
    imageError,
    image,
    addTemplateLoading,
    addError,
    templateAdded,
  } = useSelector(templateData)

  const initialInputs = {
    name: "",
    category: {},
    previewUrl: "",
    price: 0,
    marketplaceUrl: "",
    image: "",
    ecommerce: false,
  }

  const [inputs, setInputs] = useState(initialInputs)
  const [tags, setTags] = useState([])
  const [tagSelectOptions, setTagSelectOptions] = useState([])
  const [errors, setErrors] = useState([])
  const [addAnother, setAddAnother] = useState(false)

  const categoryOptions = [
    {
      label: "Categories",
      options: [
        { label: "Accounting", value: "Accounting" },
        { label: "Agency", value: "Agency" },
        { label: "Agriculture", value: "Agriculture" },
        { label: "App", value: "App" },
        { label: "Automotive", value: "Automotive" },
        { label: "Blog", value: "Blog" },
        { label: "Book", value: "Book" },
        { label: "Business", value: "Business" },
        { label: "Charity", value: "Charity" },
        { label: "Construction", value: "Construction" },
        { label: "Consulting", value: "Consulting" },
        { label: "Creative", value: "Creative" },
        { label: "Designer", value: "Designer" },
        { label: "Education", value: "Education" },
        { label: "Finance", value: "Finance" },
        { label: "Health", value: "Health" },
        { label: "Help Center", value: "Help Center" },
        { label: "Hotel", value: "Hotel" },
        { label: "Gym", value: "Gym" },
        { label: "Interior design", value: "Interior design" },
        { label: "Investment", value: "Investment" },
        { label: "Landing page", value: "Landing page" },
        { label: "Logistics", value: "Logistics" },
        { label: "Marketing", value: "Marketing" },
        { label: "News", value: "News" },
        { label: "Nonprofit", value: "Nonprofit" },
        { label: "Photography", value: "Photography" },
        { label: "Podcast", value: "Podcast" },
        { label: "Portfolio", value: "Portfolio" },
        { label: "Real Estate", value: "Real Estate" },
        { label: "Restaurant", value: "Restaurant" },
        { label: "SaaS", value: "SaaS" },
        { label: "School", value: "School" },
        { label: "Shop", value: "Shop" },
        { label: "Therapy", value: "Therapy" },
        { label: "Travel", value: "Travel" },
        { label: "Video", value: "Video" },
      ],
    },
  ]

  const onInputChange = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const onCategoryChange = category => {
    setInputs({ ...inputs, category })
  }

  const handleTagsSelection = selected => {
    setTags(selected)
  }

  const onImageInputChange = async e => {
    if (e.target.files[0]) {
      const formData = new FormData()
      formData.append("file", e.target.files[0])

      //dispatch(dismissLogoError())
      dispatch(uploadImage(formData))
    }
  }

  const onRemoveImage = () => {
    dispatch(removeImage())
  }

  const onCancel = () => {
    navigate("/templates")
  }

  const onSave = addAnother => {
    if (addTemplateLoading) return

    let warnings = []

    const { name, category, previewUrl, marketplaceUrl } = inputs

    if (name.length === 0) warnings.push("name")
    if (Object.keys(category).length === 0) warnings.push("category")
    if (previewUrl.length === 0) warnings.push("previewUrl")
    if (marketplaceUrl.length === 0) warnings.push("marketplaceUrl")
    if (!image) warnings.push("image")
    if (tags.length === 0) warnings.push("tags")

    if (warnings.length > 0) {
      setErrors(warnings)
      return
    }

    setErrors([])
    if (addAnother) setAddAnother(true)

    const uploadedImage = awsS3UserUploadBucketURL + image

    const selectedTags = tags.map(tag => tag.value)

    // add CMS tag to every template
    selectedTags.push("54842eca-3b33-49e8-92d4-0e4943660b34")

    // add Ecommerce tag
    if (inputs.ecommerce)
      selectedTags.push("ab7ee340-2ce2-4eea-ae91-3e87d23da7ca")

    dispatch(addTemplate({ inputs, selectedTags, uploadedImage }))
  }

  // get tags data on load
  useEffect(() => {
    dispatch(getTemplateData())
  }, [])

  // update tag options when data received
  useEffect(() => {
    if (tagOptions) {
      const options = tagOptions.map(tag => {
        return { label: tag.tag, value: tag.id }
      })

      setTagSelectOptions([
        {
          label: "Tags",
          options: options,
        },
      ])
    }
  }, [tagOptions])

  // redirect when template added
  useEffect(() => {
    if (templateAdded) {
      dispatch(resetTemplateAdded())
      if (!addAnother) navigate("/templates")
      setInputs({ ...initialInputs })
      setTags([])
      setAddAnother(false)
    }
  }, [templateAdded])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Management"
            breadcrumbItems={[
              { link: "/templates", item: "Templates" },
              { link: "", item: "Add Template" },
            ]}
          />
          <Row>
            <Col lg={12}>
              <Card style={{ paddingBottom: "200px" }}>
                <CardBody>
                  <CardTitle className="h4 mb-md-4">Add Template</CardTitle>
                  <Row className="mb-3" style={{ minHeight: "181px" }}>
                    <label className="col-md-2 col-xxl-1 col-form-label">
                      Image
                    </label>
                    <div className="col-md-10 col-xxl-11">
                      <Input
                        className="form-control"
                        type="file"
                        accept="image/png, image/gif, image/jpeg, image/svg+xml"
                        onChange={onImageInputChange}
                        disabled={imageUploadLoading}
                      />
                      {imageError && (
                        <UncontrolledAlert
                          color="danger"
                          role="alert"
                          style={{
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          {imageError}
                        </UncontrolledAlert>
                      )}
                      {image && !imageUploadLoading && (
                        <div
                          className="logo-thumbnail-wrap"
                          onClick={() => onRemoveImage()}
                        >
                          <img
                            className="img-thumbnail logo-thumbnail"
                            alt="Logo"
                            src={awsS3UserUploadBucketURL + image}
                            data-holder-rendered="true"
                          />
                          <div className="thumbnail-overlay">
                            <img
                              alt="Remove"
                              src={removeIconRed}
                              width={24}
                              data-holder-rendered="true"
                            />
                          </div>
                          <div className="thumbnail-bin-wrap">
                            <img
                              alt="Remove"
                              src={removeIconGrey}
                              width={24}
                              data-holder-rendered="true"
                            />
                          </div>
                        </div>
                      )}
                      {imageUploadLoading && (
                        <div className="logo-thumbnail-wrap ">
                          <img alt="Loading" src={loadingIcon} width={36} />
                        </div>
                      )}
                      {errors.includes("image") && (
                        <div className="form-feedback-text">
                          Upload preview image
                        </div>
                      )}
                    </div>
                  </Row>
                  <Row className="mb-3 mt-3">
                    <Label
                      htmlFor="subject"
                      className="col-md-2 col-xxl-1 col-form-label"
                    >
                      Name
                    </Label>
                    <div className="col-md-10 col-xxl-11">
                      <Input
                        className="form-control"
                        type="text"
                        name="name"
                        id="templateName"
                        value={inputs.name}
                        onChange={onInputChange}
                        invalid={errors.includes("name")}
                        maxLength={90}
                      />
                      {errors.includes("name") && (
                        <FormFeedback type="invalid">
                          Enter template name
                        </FormFeedback>
                      )}
                    </div>
                  </Row>
                  <Row className="mb-3 mt-3">
                    <Label
                      htmlFor="subject"
                      className="col-md-2 col-xxl-1 col-form-label"
                    >
                      Category
                    </Label>
                    <div className="col-md-10 col-xxl-11">
                      <Select
                        className="select2-selection"
                        value={inputs.category}
                        onChange={onCategoryChange}
                        options={categoryOptions}
                        invalid={errors.includes("category")}
                      />
                      {errors.includes("category") && (
                        <div className="form-feedback-text">
                          Select template category
                        </div>
                      )}
                    </div>
                  </Row>
                  <Row className="mb-3 mt-3">
                    <Label
                      htmlFor="subject"
                      className="col-md-2 col-xxl-1 col-form-label"
                    >
                      Tags
                    </Label>
                    <div className="col-md-10 col-xxl-11">
                      <Select
                        value={tags}
                        isMulti={true}
                        onChange={selection => {
                          handleTagsSelection(selection)
                        }}
                        options={tagSelectOptions}
                        className="select2-selection"
                      />
                      {errors.includes("tags") && (
                        <div className="form-feedback-text">
                          Enter template tags
                        </div>
                      )}
                    </div>
                  </Row>
                  <Row className="mb-3 mt-3">
                    <Label
                      htmlFor="subject"
                      className="col-md-2 col-xxl-1 col-form-label"
                    >
                      Ecommerce
                    </Label>
                    <div className="col-md-10 col-xxl-11 d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={inputs.ecommerce}
                        onChange={() => {
                          setInputs({ ...inputs, ecommerce: !inputs.ecommerce })
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3 mt-3">
                    <Label
                      htmlFor="subject"
                      className="col-md-2 col-xxl-1 col-form-label"
                    >
                      Market URL
                    </Label>
                    <div className="col-md-10 col-xxl-11">
                      <Input
                        className="form-control"
                        type="text"
                        name="marketplaceUrl"
                        id="templateMarketplaceUrl"
                        value={inputs.marketplaceUrl}
                        onChange={onInputChange}
                        invalid={errors.includes("marketplaceUrl")}
                        maxLength={90}
                      />
                      {errors.includes("marketplaceUrl") && (
                        <FormFeedback type="invalid">
                          Enter template marketplace URL address
                        </FormFeedback>
                      )}
                    </div>
                  </Row>
                  <Row className="mb-3 mt-3">
                    <Label
                      htmlFor="subject"
                      className="col-md-2 col-xxl-1 col-form-label"
                    >
                      Market price
                    </Label>
                    <div className="col-md-10 col-xxl-11">
                      <Input
                        className="form-control"
                        type="number"
                        name="price"
                        id="templatePrice"
                        value={inputs.price}
                        onChange={onInputChange}
                        invalid={errors.includes("price")}
                        maxLength={90}
                      />
                      {errors.includes("price") && (
                        <FormFeedback type="invalid">
                          Enter template marketplace price
                        </FormFeedback>
                      )}
                    </div>
                  </Row>
                  <Row className="mb-3 mt-3">
                    <Label
                      htmlFor="subject"
                      className="col-md-2 col-xxl-1 col-form-label"
                    >
                      Preview URL
                    </Label>
                    <div className="col-md-10 col-xxl-11">
                      <Input
                        className="form-control"
                        type="text"
                        name="previewUrl"
                        id="templatePreviewUrl"
                        value={inputs.previewUrl}
                        onChange={onInputChange}
                        invalid={errors.includes("previewUrl")}
                        maxLength={90}
                      />
                      {errors.includes("previewUrl") && (
                        <FormFeedback type="invalid">
                          Enter template preview URL address
                        </FormFeedback>
                      )}
                    </div>
                  </Row>
                  {addError && (
                    <Row className="mb-3">
                      <div style={{ maxWidth: "500px" }}>
                        <UncontrolledAlert
                          color="danger"
                          role="alert"
                          style={{
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          {addError}
                        </UncontrolledAlert>
                      </div>
                    </Row>
                  )}
                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label"></label>
                    <div className="col-md-12">
                      <Button
                        color="primary"
                        className="btn btn-secondary waves-effect waves-light mt-1 mb-3"
                        onClick={() => onCancel()}
                        style={{ marginRight: "0.5rem" }}
                      >
                        Back
                      </Button>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light mt-1 mb-3"
                        onClick={() => onSave()}
                        style={{ minWidth: "60px", marginRight: "0.5rem" }}
                      >
                        {addTemplateLoading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {!addTemplateLoading && "Save"}
                      </Button>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light mt-1 mb-3"
                        onClick={() => onSave(true)}
                        style={{ minWidth: "172px" }}
                      >
                        {addTemplateLoading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {!addTemplateLoading && "Save and Add Another"}
                      </Button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddTemplate
