import React, { useEffect, useState, useCallback } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
  Input,
  UncontrolledAlert,
  Label,
  Accordion,
  UncontrolledAccordion,
  AccordionItem,
  FormFeedback,
  Spinner,
  CardImg,
  Badge,
  Modal,
  ModalBody,
} from "reactstrap"
import { map, filter, debounce } from "lodash"
import Select from "react-select"
import { useNavigate, Link } from "react-router-dom"

//Import Constants
import { awsS3UserUploadBucketURL } from "../../constants/constants"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Images
import removeIconRed from "../../assets/images/icons8-waste-24-red.png"
import removeIconGrey from "../../assets/images/icons/icons8-waste.svg"
import loadingIcon from "../../assets/images/icons8-loading.gif"

//Import Components
import CustomizationFormProgress from "components/Customization/CustomizationFormProgress"
import Section from "./Section"

//Import functions
import { dateShort } from "../../common/functions"

//Import Actions
import {
  getCustomizationData,
  saveStepOneData,
  saveStepTwoData,
  saveStepFourData,
  uploadLogo,
  removeLogo,
  dismissLogoError,
  uploadHomeImage,
  removeHomeImage,
  addSection,
  saveSectionsData,
  stepOneChange,
  stepTwoChange,
  stepFourChange,
  finishSubmission,
} from "store/actions"

//redux
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"

//i18n
import { withTranslation } from "react-i18next"

const CustomizationForm = ({ t }) => {
  //meta title
  document.title = t("Title Submit Project Data")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const customizationData = createSelector(
    state => state.customization,
    customizationData => {
      return {
        companyName: customizationData.companyName,
        domain: customizationData.domain,
        companyDescription: customizationData.companyDescription,
        oldWebsite: customizationData.oldWebsite,
        stepOneComments: customizationData.stepOneComments,
        logo: customizationData.logo || null,
        title: customizationData.title,
        subTitle: customizationData.subTitle,
        mainText: customizationData.mainText,
        homeImages: customizationData.homeImages,
        stepTwoComments: customizationData.stepTwoComments,
        logoUploadLoading: customizationData.logoUploadLoading,
        logoError: customizationData.logoError,
        homeImageUploadLoading: customizationData.homeImageUploadLoading,
        homeImageError: customizationData.homeImageError,
        sections: customizationData.sections,
        addSectionLoading: customizationData.addSectionLoading,
        likedWebsites: customizationData.likedWebsites,
        finalComments: customizationData.finalComments,
        dataLoading: customizationData.loading,
        finishSubmissionLoading: customizationData.finishSubmissionLoading,
        formSubmitted: customizationData.formSubmitted,
      }
    }
  )

  const {
    companyName,
    domain,
    companyDescription,
    oldWebsite,
    stepOneComments,
    logo,
    title,
    subTitle,
    mainText,
    homeImages,
    stepTwoComments,
    logoUploadLoading,
    logoError,
    homeImageUploadLoading,
    homeImageError,
    sections,
    addSectionLoading,
    likedWebsites,
    finalComments,
    dataLoading,
    finishSubmissionLoading,
    formSubmitted,
  } = useSelector(customizationData)

  const accountData = createSelector(
    state => state.account,
    accountData => {
      return {
        project: accountData.project,
      }
    }
  )

  const { project } = useSelector(accountData)

  const [step, setStep] = useState(0)
  const [newSectionSelectVisible, setNewSectionSelectVisible] = useState(false)
  const [newSectionSelected, setNewSectionSelected] = useState(null)
  const [otherSection, setOtherSection] = useState("")
  const [otherSectionWarning, setOtherSectionWarning] = useState(false)
  const [submitModal, setSubmitModal] = useState(false)
  const [sectionOpened, setSectionOpened] = useState("")
  const [sectionBeingAdded, setSectionBeingAdded] = useState(null)

  // redirect if Customization Form project status not "new"
  useEffect(() => {
    if (project?.status !== "new") {
      navigate("/dashboard")
    }
  }, [project])

  // Update step 1  data
  const dispatchStepOneData = (
    companyName,
    companyDescription,
    domain,
    oldWebsite,
    stepOneComments
  ) => {
    dispatch(
      saveStepOneData(
        companyName,
        companyDescription,
        domain,
        oldWebsite,
        stepOneComments
      )
    )
  }

  const updateStepOneData = useCallback(debounce(dispatchStepOneData, 5000), [])

  useEffect(() => {
    if (step === 1) {
      updateStepOneData(
        companyName,
        companyDescription,
        domain,
        oldWebsite,
        stepOneComments
      )
    }
  }, [companyName, companyDescription, domain, oldWebsite, stepOneComments])

  // Update step 2  data
  const dispatchStepTwoData = (
    logo,
    title,
    subTitle,
    mainText,
    homeImages,
    stepTwoComments
  ) => {
    dispatch(
      saveStepTwoData(
        logo,
        title,
        subTitle,
        mainText,
        homeImages,
        stepTwoComments
      )
    )
  }

  const updateStepTwoData = useCallback(debounce(dispatchStepTwoData, 5000), [])

  useEffect(() => {
    if (step === 2) {
      updateStepTwoData(
        logo,
        title,
        subTitle,
        mainText,
        homeImages,
        stepTwoComments
      )
    }
  }, [logo, title, subTitle, mainText, homeImages, stepTwoComments])

  // Update step 4 data
  const dispatchStepFourData = (likedWebsites, finalComments) => {
    dispatch(saveStepFourData(likedWebsites, finalComments))
  }

  const updateStepFourData = useCallback(
    debounce(dispatchStepFourData, 5000),
    []
  )

  useEffect(() => {
    if (step === 4) {
      updateStepFourData(likedWebsites, finalComments)
    }
  }, [likedWebsites, finalComments])

  // Update sections data
  const dispatchSectionsData = sections => {
    dispatch(saveSectionsData(sections))
  }

  const updateSectionsData = useCallback(
    debounce(dispatchSectionsData, 5000),
    []
  )

  useEffect(() => {
    if (step === 3) {
      updateSectionsData(sections)

      if (sectionBeingAdded !== null) {
        for (const section in sections) {
          if (sections[section].section === sectionBeingAdded) {
            setSectionOpened(section)

            break
          }
        }

        setSectionBeingAdded(null)
      }
    }
  }, [sections])

  const stepNavigate = direction => {
    if (step === 1) {
      updateStepOneData.flush()
    }

    if (step === 2) {
      updateStepTwoData.flush()
    }

    if (step === 3) {
      updateSectionsData.flush()
    }

    if (step === 4) {
      updateStepFourData.flush()
    }

    if (direction === "forward") {
      setStep(step + 1)
    }

    if (direction === "back") {
      if (step > 0) setStep(step - 1)
    }

    window.scrollTo(0, 0)
  }

  // load customization data on first render
  useEffect(() => {
    dispatch(getCustomizationData())
  }, [])

  const onStepOneInputChange = e => {
    //setStepOneInputs({ ...stepOneInputs, [e.target.name]: e.target.value })
    dispatch(stepOneChange(e.target.name, e.target.value))
  }

  const onStepTwoInputChange = e => {
    //setStepTwoInputs({ ...stepTwoInputs, [e.target.name]: e.target.value })
    dispatch(stepTwoChange(e.target.name, e.target.value))
  }

  const onStepFourInputChange = e => {
    dispatch(stepFourChange(e.target.name, e.target.value))
  }

  const onLogoInputChange = async e => {
    if (e.target.files[0]) {
      const formData = new FormData()
      formData.append("file", e.target.files[0])

      dispatch(dismissLogoError())
      dispatch(uploadLogo(formData))
    }
  }

  const onRemoveLogo = () => {
    dispatch(removeLogo())
  }

  const onHomeImageInputChange = async e => {
    if (e.target.files[0]) {
      const formData = new FormData()
      formData.append("file", e.target.files[0])

      dispatch(uploadHomeImage(formData))
    }
  }

  const onRemoveHomeImage = image => {
    dispatch(removeHomeImage(image))
  }

  const onOtherSectionChange = e => {
    setOtherSection(e.target.value)
  }

  // Section selection
  const sectionOptions = [
    {
      options: [
        { label: t("About Us", { ns: "shared" }), value: "About Us" },
        { label: t("Services", { ns: "shared" }), value: "Services" },
        { label: t("Portfolio", { ns: "shared" }), value: "Portfolio" },
        { label: t("Products", { ns: "shared" }), value: "Products" },
        { label: t("Features", { ns: "shared" }), value: "Features" },
        { label: t("Blog", { ns: "shared" }), value: "Blog" },
        { label: t("Team", { ns: "shared" }), value: "Team" },
        { label: t("Testimonials", { ns: "shared" }), value: "Testimonials" },
        { label: t("Pricing", { ns: "shared" }), value: "Pricing" },
        { label: t("FAQ", { ns: "shared" }), value: "FAQ" },
        { label: t("Contact", { ns: "shared" }), value: "Contact" },
        { label: t("Other", { ns: "shared" }), value: "Other" },
      ],
    },
  ]

  const toggleNewSectionSelection = () => {
    if (addSectionLoading) return

    setNewSectionSelected(null)
    setOtherSection("")
    setNewSectionSelectVisible(!newSectionSelectVisible)
  }

  const handleSelectNewSection = selected => {
    setNewSectionSelected(selected)
  }

  const onAddSection = () => {
    if (!newSectionSelected || addSectionLoading) return

    if (newSectionSelected.value !== "Other") {
      setSectionBeingAdded(newSectionSelected.value)
      setNewSectionSelectVisible(false)
      dispatch(addSection(newSectionSelected))
    } else {
      if (otherSection.length === 0 || otherSection === "Other") {
        setOtherSectionWarning(true)
      } else {
        setNewSectionSelectVisible(false)
        setSectionBeingAdded(otherSection)
        dispatch(addSection({ value: otherSection }))
        setOtherSection("")
      }
    }
  }

  const onSubmitClick = () => {
    setSubmitModal(true)
  }

  const closeSubmitModal = () => {
    setSubmitModal(false)
  }

  const confirmSubmit = () => {
    updateStepFourData.flush()

    dispatch(finishSubmission())
  }

  // watch for Customization form submission to redirect user back to Dashboard
  useEffect(() => {
    if (formSubmitted === true) {
      window.location = "/dashboard"
    }
  }, [formSubmitted])

  const dateOfPurchase = new Date(project?.created_at)

  // handle Sections accordion
  const sectionToggle = key => {
    // close section if clicked
    if (key === sectionOpened) {
      setSectionOpened("")
      return
    }

    setSectionOpened(key)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("Dashboard")}
            titleLink="/dashboard"
            breadcrumbItems={[{ link: "", item: t("Submit Data") }]}
          />
          <Row className="justify-content-center">
            <Col xl={8}>
              {step === 0 && (
                <Card className="flex-column align-items-center">
                  <CardBody style={{ width: "95%" }}>
                    <div className="text-center">
                      <Row>
                        <Col>
                          <h4 className="mt-4 mb-1 fw-semibold">
                            {t("Submit Website Data")}
                          </h4>
                          <Row
                            className="d-flex flex-column flex-md-row text-start"
                            style={{ maxWidth: "640px", margin: "0 auto" }}
                          >
                            <Col sm="4">
                              <div className="d-flex  mt-3">
                                <div className="avatar-xs me-3">
                                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18 mt-1">
                                    <i className="mdi mdi-monitor-dashboard" />
                                  </span>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-1">
                                    {t("Design")}
                                  </p>
                                  <h6>
                                    {project?.using_template
                                      ? project?.template.name
                                      : t("Custom design")}
                                  </h6>
                                </div>
                              </div>
                            </Col>
                            <Col sm="4">
                              <div className="d-flex  mt-3">
                                <div className="avatar-xs me-3">
                                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18 mt-1">
                                    <i className="mdi mdi-calendar-text" />
                                  </span>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-1">
                                    {t("Date of Purchase")}
                                  </p>
                                  <h6>
                                    {dateShort(dateOfPurchase)},{" "}
                                    {dateOfPurchase.getFullYear()}
                                  </h6>
                                </div>
                              </div>
                            </Col>
                            <Col sm="4">
                              <div className="d-flex  mt-3">
                                <div className="avatar-xs me-3">
                                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18 mt-1">
                                    <i className="mdi mdi-list-status" />
                                  </span>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-1">
                                    {t("Status")}
                                  </p>
                                  <h6>{t("Waiting for data")}</h6>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {project?.using_template && (
                            <Card
                              className="ms-auto me-auto"
                              style={{
                                boxShadow: "0 1rem 3rem rgba(0, 0, 0, 0.175)",
                                maxWidth: "500px",
                                marginTop: "35px",
                              }}
                            >
                              <Row className="no-gutters align-items-center">
                                <Col
                                  style={{
                                    overflow: "hidden",
                                  }}
                                >
                                  <CardImg
                                    className="img-fluid"
                                    src={project?.template.image}
                                    alt={project?.template.name}
                                  />
                                </Col>
                              </Row>
                            </Card>
                          )}
                          <CardText
                            className="text-muted mt-5"
                            style={{ textAlign: "left" }}
                          >
                            {t("Submit data paragraph 1")}
                          </CardText>
                          <Card
                            outline
                            color="info"
                            className="border"
                            style={{
                              margin: "2em auto 2em auto",
                              maxWidth: "540px",
                            }}
                          >
                            <CardBody>
                              <CardTitle
                                className="mb-3 text-info"
                                style={{ textAlign: "left" }}
                              >
                                <i className="mdi mdi-alert-circle-outline me-3" />
                                {t("How We Will Use the Data")}
                              </CardTitle>
                              <CardText
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                {t("Submit data box 1")}
                              </CardText>
                            </CardBody>
                          </Card>
                          <CardText
                            className="text-muted mt-3"
                            style={{ textAlign: "left" }}
                          >
                            {t("Submit data paragraph 2")}
                          </CardText>
                          <Card
                            outline
                            color="warning"
                            className="border"
                            style={{
                              margin: "2em auto 2em auto",
                              maxWidth: "540px",
                            }}
                          >
                            <CardBody>
                              <CardTitle
                                className="mb-3 text-warning"
                                style={{ textAlign: "left" }}
                              >
                                <i className="mdi mdi-email-outline me-3" />
                                {t("If You Need Help")}
                              </CardTitle>
                              <CardText style={{ textAlign: "left" }}>
                                {t("Submit data box 2")}
                              </CardText>
                            </CardBody>
                          </Card>
                          <CardText className="pt-3 mb-3">
                            <Link to="/">
                              <Button
                                color="secondary"
                                className="btn btn-primary waves-effect waves-light mt-1"
                              >
                                {t("Back")}
                              </Button>
                            </Link>
                            <Button
                              color="primary"
                              className="btn btn-primary waves-effect waves-light mt-1 ms-2"
                              onClick={() => stepNavigate("forward")}
                            >
                              {t("Start Customizing Your Website")}
                            </Button>
                          </CardText>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              )}
              {step === 1 && (
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      {t("Basic information")}
                    </CardTitle>
                    <p className="card-title-desc">
                      {t("Please enter information about your company")}
                    </p>
                    {dataLoading && (
                      <div
                        className="w-100 d-flex justify-content-center align-items-center"
                        style={{ height: "400px" }}
                      >
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    )}
                    {!dataLoading && (
                      <>
                        <Row className="mb-3">
                          <label
                            htmlFor="company-name"
                            className="col-form-label"
                          >
                            {t("Company name")}
                          </label>
                          <div>
                            <input
                              className="form-control"
                              type="text"
                              name="companyName"
                              id="company-name"
                              placeholder={`${t("eg")} Mama's Fish Restaurant`}
                              value={companyName}
                              onChange={onStepOneInputChange}
                            />
                            <span className="text-muted">
                              {t(
                                "Enter the name of your company, project or website"
                              )}
                            </span>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label
                            htmlFor="company-description"
                            className="col-form-label"
                          >
                            {t("Company description")}
                          </label>
                          <div>
                            <textarea
                              className="form-control"
                              name="companyDescription"
                              id="company-description"
                              placeholder=""
                              rows="4"
                              value={companyDescription}
                              onChange={onStepOneInputChange}
                            ></textarea>
                            <span className="text-muted">
                              {t("Briefly describe what your company does")}
                            </span>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label htmlFor="domain" className="col-form-label">
                            {t("Domain")}
                          </label>
                          <div>
                            <input
                              className="form-control"
                              type="text"
                              name="domain"
                              id="domain"
                              placeholder={`${t("eg")} mamasfish.com`}
                              value={domain}
                              onChange={onStepOneInputChange}
                            />
                            <span className="text-muted">
                              {t("If you already have a domain name")}
                            </span>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label
                            htmlFor="old-website"
                            className="col-form-label"
                          >
                            {t("Previous website")}
                          </label>
                          <div>
                            <input
                              className="form-control"
                              type="text"
                              name="oldWebsite"
                              id="old-website"
                              placeholder={`${t(
                                "eg"
                              )} https://www.mamasfish.com`}
                              value={oldWebsite}
                              onChange={onStepOneInputChange}
                            />
                            <span className="text-muted">
                              {t("If you plan to replace your old website")}
                            </span>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label
                            htmlFor="step-one-comments"
                            className="col-form-label"
                          >
                            {t("Your comments")}
                          </label>
                          <div>
                            <textarea
                              className="form-control comments-input"
                              name="stepOneComments"
                              id="step-one-comments"
                              placeholder=""
                              rows="4"
                              value={stepOneComments}
                              onChange={onStepOneInputChange}
                            ></textarea>
                            <span className="text-muted">
                              {t(
                                "Optionally, enter any comments you have about the data"
                              )}
                            </span>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-form-label"></label>
                          <div>
                            <Button
                              color="primary"
                              className="btn btn-secondary waves-effect waves-light mt-1 mb-3"
                              onClick={() => stepNavigate("back")}
                              style={{ marginRight: "0.5rem" }}
                            >
                              {t("Back")}
                            </Button>
                            <Button
                              color="primary"
                              className="btn btn-primary waves-effect waves-light mt-1 mb-3"
                              onClick={() => stepNavigate("forward")}
                            >
                              {t("Continue")}
                            </Button>
                          </div>
                        </Row>
                      </>
                    )}
                  </CardBody>
                </Card>
              )}
              {step === 2 && (
                <Card>
                  <CardBody className="d-flex flex-column">
                    <>
                      <CardTitle className="h4">
                        {t("Logo and headlines")}
                      </CardTitle>
                      <p className="card-title-desc">
                        {t("Upload your company logo if you have one")}
                      </p>
                      {dataLoading && (
                        <div
                          className="w-100 d-flex justify-content-center align-items-center"
                          style={{ height: "400px" }}
                        >
                          <Spinner className="ms-2" color="primary" />
                        </div>
                      )}
                      {!dataLoading && (
                        <>
                          <Row className="mb-3">
                            <label className="col-form-label">
                              {t("Logo")}
                            </label>
                            <div>
                              <Input
                                className="form-control"
                                type="file"
                                accept="image/png, image/gif, image/jpeg, image/svg+xml"
                                onChange={onLogoInputChange}
                                disabled={logoUploadLoading}
                              />
                              <span
                                className="text-muted"
                                style={{ display: "block" }}
                              >
                                {t("Upload the logo image")}
                              </span>
                              {logoError && (
                                <UncontrolledAlert
                                  color="danger"
                                  role="alert"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {logoError}
                                </UncontrolledAlert>
                              )}
                              {logo && !logoUploadLoading && (
                                <div
                                  className="logo-thumbnail-wrap"
                                  onClick={() => onRemoveLogo()}
                                >
                                  <img
                                    className="img-thumbnail logo-thumbnail"
                                    alt="Logo"
                                    src={awsS3UserUploadBucketURL + logo}
                                    data-holder-rendered="true"
                                  />
                                  <div className="thumbnail-overlay">
                                    <img
                                      alt="Remove"
                                      src={removeIconRed}
                                      width={24}
                                      data-holder-rendered="true"
                                    />
                                  </div>
                                  <div className="thumbnail-bin-wrap">
                                    <img
                                      alt="Remove"
                                      src={removeIconGrey}
                                      width={24}
                                      data-holder-rendered="true"
                                    />
                                  </div>
                                </div>
                              )}
                              {logoUploadLoading && (
                                <div className="logo-thumbnail-wrap ">
                                  <img
                                    alt="Loading"
                                    src={loadingIcon}
                                    width={36}
                                  />
                                </div>
                              )}
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label htmlFor="title" className="col-form-label">
                              {t("Main title")}
                            </label>
                            <div>
                              <input
                                className="form-control"
                                type="text"
                                name="title"
                                id="title"
                                placeholder={`${t(
                                  "eg"
                                )} Premier Fish Restaurant`}
                                value={title}
                                onChange={onStepTwoInputChange}
                              />
                              <span className="text-muted">
                                {t(
                                  "The main headline is what a visitor sees first"
                                )}
                              </span>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="sub-title"
                              className="col-form-label"
                            >
                              {t("Subtitle")}
                            </label>
                            <div>
                              <input
                                className="form-control"
                                type="text"
                                name="subTitle"
                                id="sub-title"
                                placeholder={`${t(
                                  "eg"
                                )} Good food, great vibes`}
                                value={subTitle}
                                onChange={onStepTwoInputChange}
                              />
                              <span className="text-muted">
                                {t("Insert a subtitle or your company slogan")}
                              </span>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="main-text"
                              className="col-form-label"
                            >
                              {t("Introductory text")}
                            </label>
                            <div>
                              <textarea
                                className="form-control"
                                name="mainText"
                                id="main-text"
                                placeholder=""
                                rows="8"
                                value={mainText}
                                onChange={onStepTwoInputChange}
                              ></textarea>
                              <span className="text-muted">
                                {t("Here you can insert an important message")}
                              </span>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label className="col-form-label">
                              {t("Homepage images")}
                            </label>
                            <div>
                              <Input
                                className="form-control"
                                type="file"
                                accept="image/png, image/gif, image/jpeg, image/svg+xml"
                                onChange={onHomeImageInputChange}
                                disabled={homeImageUploadLoading}
                              />
                              <span
                                className="text-muted"
                                style={{ display: "block" }}
                              >
                                {t(
                                  "If you have images that you want to put on the front page"
                                )}
                              </span>
                              {homeImageError && (
                                <UncontrolledAlert
                                  color="danger"
                                  role="alert"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {homeImageError}
                                </UncontrolledAlert>
                              )}
                              {homeImages.length !== 0 && (
                                <div className="image-thumbnail-list-wrap">
                                  {map(homeImages, image => (
                                    <div
                                      className="logo-thumbnail-wrap"
                                      onClick={() => onRemoveHomeImage(image)}
                                      key={image}
                                    >
                                      <img
                                        className="img-thumbnail logo-thumbnail"
                                        alt="Logo"
                                        src={awsS3UserUploadBucketURL + image}
                                        data-holder-rendered="true"
                                      />
                                      <div className="thumbnail-overlay">
                                        <img
                                          alt="Remove"
                                          src={removeIconRed}
                                          width={24}
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                      <div className="thumbnail-bin-wrap">
                                        <img
                                          alt="Remove"
                                          src={removeIconGrey}
                                          width={24}
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {homeImageUploadLoading && (
                                <div className="logo-thumbnail-wrap ">
                                  <img
                                    alt="Loading"
                                    src={loadingIcon}
                                    width={36}
                                  />
                                </div>
                              )}
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="step-two-comments"
                              className="col-form-label"
                            >
                              {t("Your comments")}
                            </label>
                            <div>
                              <textarea
                                className="form-control comments-input"
                                name="stepTwoComments"
                                id="step-two-comments"
                                placeholder=""
                                rows="4"
                                value={stepTwoComments}
                                onChange={onStepTwoInputChange}
                              ></textarea>
                              <span className="text-muted">
                                {t(
                                  "Optionally, enter any comments you have about the data"
                                )}
                              </span>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label className="col-form-label"></label>
                            <div>
                              <Button
                                color="primary"
                                className="btn btn-secondary waves-effect waves-light mt-1 mb-3"
                                onClick={() => stepNavigate("back")}
                                style={{ marginRight: "0.5rem" }}
                              >
                                {t("Back")}
                              </Button>
                              <Button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light mt-1 mb-3"
                                onClick={() => stepNavigate("forward")}
                              >
                                {t("Continue")}
                              </Button>
                            </div>
                          </Row>
                        </>
                      )}
                    </>
                  </CardBody>
                </Card>
              )}
              {step === 3 && (
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      {t("Website sections")}
                    </CardTitle>
                    <p className="text-muted">
                      {t("Insert the content of each section of the website")}
                    </p>
                    <p className="text-muted">
                      {t("Choose a section and enter the content")}
                    </p>
                    <p className="text-muted">
                      {t("The order of the sections does not matter now")}
                    </p>
                    {dataLoading && (
                      <div
                        className="w-100 d-flex justify-content-center align-items-center"
                        style={{ height: "400px" }}
                      >
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    )}
                    {!dataLoading && (
                      <div
                        className="d-flex flex-column justify-content-between"
                        style={{ minHeight: "400px" }}
                      >
                        <div>
                          <Row className="mt-2">
                            <Accordion
                              defaultOpen="1"
                              toggle={sectionToggle}
                              open={sectionOpened}
                            >
                              {map(sections, (_, key) => {
                                return (
                                  <AccordionItem key={key}>
                                    <Section sectionId={key} />
                                  </AccordionItem>
                                )
                              })}
                            </Accordion>
                          </Row>
                          {!newSectionSelectVisible && !addSectionLoading && (
                            <Row className="mt-2">
                              <div>
                                <Button
                                  color="primary"
                                  className="btn btn-primary waves-effect waves-light mt-1 mb-3"
                                  onClick={() => toggleNewSectionSelection()}
                                >
                                  <i
                                    className="bx bx-plus font-size-16 align-middle me-1"
                                    style={{ paddingBottom: "2px" }}
                                  ></i>{" "}
                                  {t("Add Section")}
                                </Button>
                              </div>
                            </Row>
                          )}
                          {(newSectionSelectVisible || addSectionLoading) && (
                            <Row>
                              <Col>
                                <div className="mb-3 mt-4">
                                  <Label>{t("Add new section")}</Label>
                                  <div className="d-flex flex-column">
                                    <Col>
                                      {newSectionSelected?.value !==
                                        "Other" && (
                                        <Select
                                          className="select2-selection"
                                          value={newSectionSelected}
                                          onChange={handleSelectNewSection}
                                          options={[
                                            {
                                              options: filter(
                                                sectionOptions[0].options,
                                                option => {
                                                  for (const exitingSection in sections) {
                                                    if (
                                                      sections[exitingSection]
                                                        .section ===
                                                      option.value
                                                    ) {
                                                      return false
                                                    }
                                                  }

                                                  return true
                                                }
                                              ),
                                            },
                                          ]}
                                          isDisabled={addSectionLoading}
                                        />
                                      )}
                                      {newSectionSelected?.value ===
                                        "Other" && (
                                        <>
                                          <Input
                                            className="form-control"
                                            type="text"
                                            placeholder={t(
                                              "Enter section name"
                                            )}
                                            value={otherSection}
                                            onChange={onOtherSectionChange}
                                            maxLength={30}
                                            invalid={
                                              otherSectionWarning ? true : false
                                            }
                                          />
                                          {otherSectionWarning ? (
                                            <FormFeedback type="invalid">
                                              {t(
                                                "Please enter the section name"
                                              )}
                                            </FormFeedback>
                                          ) : null}
                                        </>
                                      )}
                                    </Col>

                                    <div className="d-flex mt-2">
                                      <Button
                                        color="primary"
                                        className="btn btn-primary waves-effect waves-light"
                                        style={{ width: "64px" }}
                                        onClick={onAddSection}
                                      >
                                        {addSectionLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm"
                                            style={{ marginBottom: "-2px" }}
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        {!addSectionLoading && t("Add")}
                                      </Button>
                                      <Button
                                        color="primary"
                                        className="btn btn-secondary waves-effect waves-light ms-2"
                                        style={{ width: "64px" }}
                                        onClick={() =>
                                          toggleNewSectionSelection()
                                        }
                                      >
                                        {t("Close")}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </div>
                        <Row className="mb-3 mt-2">
                          <div>
                            <Button
                              color="primary"
                              className="btn btn-secondary waves-effect waves-light mt-1 mb-3"
                              onClick={() => stepNavigate("back")}
                              style={{ marginRight: "0.5rem" }}
                            >
                              {t("Back")}
                            </Button>
                            <Button
                              color="primary"
                              className="btn btn-primary waves-effect waves-light mt-1 mb-3"
                              onClick={() => stepNavigate("forward")}
                            >
                              {t("Continue")}
                            </Button>
                          </div>
                        </Row>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
              {step === 4 && (
                <Card className="flex-column align-items-center">
                  <CardBody style={{ width: "95%" }}>
                    <div className="text-center">
                      <Row>
                        <Col>
                          <h4 className="mt-4 fw-semibold">
                            {t("Finish Entering Website Data")}
                          </h4>

                          <CardText
                            className="text-muted mt-3 mb-4"
                            style={{ textAlign: "left" }}
                          >
                            {t("If you have entered all the data")}
                          </CardText>
                          {dataLoading && (
                            <div
                              className="w-100 d-flex justify-content-center align-items-center"
                              style={{ height: "400px" }}
                            >
                              <Spinner className="ms-2" color="primary" />
                            </div>
                          )}
                          {!dataLoading && (
                            <>
                              {!project?.using_template && (
                                <Row className="mb-3">
                                  <label
                                    htmlFor="liked-websites"
                                    className="col-form-label text-start"
                                  >
                                    {t("Websites you like")}
                                  </label>
                                  <div>
                                    <textarea
                                      className="form-control"
                                      name="likedWebsites"
                                      id="liked-websites"
                                      placeholder={`${t(
                                        "eg"
                                      )} https://www.apple.com https://www.spacex.com`}
                                      rows="4"
                                      value={likedWebsites}
                                      onChange={onStepFourInputChange}
                                    ></textarea>
                                    <span className="text-muted text-start">
                                      {t("List a few websites that you like")}
                                    </span>
                                  </div>
                                </Row>
                              )}
                              <Row className="mb-3">
                                <label
                                  htmlFor="final-comments"
                                  className="col-form-label text-start"
                                >
                                  {t("Any additional comments")}
                                </label>
                                <div>
                                  <textarea
                                    className="form-control"
                                    name="finalComments"
                                    id="final-comments"
                                    placeholder=""
                                    rows="8"
                                    value={finalComments}
                                    onChange={onStepFourInputChange}
                                  ></textarea>
                                  <span className="text-muted text-start">
                                    {t("Enter any additional comments")}
                                  </span>
                                </div>
                              </Row>
                            </>
                          )}
                          <Card
                            outline
                            color="info"
                            className="border"
                            style={{
                              margin: "2em auto 2em auto",
                              maxWidth: "540px",
                            }}
                          >
                            <CardBody>
                              <CardTitle
                                className="mb-3 text-info"
                                style={{ textAlign: "left" }}
                              >
                                <i className="mdi mdi-alert-circle-outline me-3" />
                                {t("Not sure the content is complete")}
                              </CardTitle>
                              <CardText
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                {t(
                                  "It is not absolutely necessary to insert all"
                                )}
                              </CardText>
                            </CardBody>
                          </Card>
                          <CardText
                            className="text-muted mt-3"
                            style={{ textAlign: "left" }}
                          >
                            {t("Once the form has been submitted")}
                          </CardText>
                        </Col>
                      </Row>
                      <Row className="mt-4 b-3">
                        <div>
                          <Button
                            color="primary"
                            className="btn btn-secondary waves-effect waves-light mt-1 mb-3"
                            onClick={() => stepNavigate("back")}
                            style={{ marginRight: "0.5rem" }}
                          >
                            {t("Back")}
                          </Button>
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light mt-1 mb-3"
                            onClick={onSubmitClick}
                          >
                            {t("Submit Data")}
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col xl={4}>
              <CustomizationFormProgress active={step + 2} />
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        size="md"
        isOpen={submitModal}
        toggle={closeSubmitModal}
        centered={true}
      >
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              onClick={closeSubmitModal}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-checkbox-marked-circle-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              {t("Have you entered all information")}
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmSubmit}
                style={{ width: "74px" }}
              >
                {finishSubmissionLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    style={{ marginBottom: "-2px" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {!finishSubmissionLoading && t("Submit")}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeSubmitModal}
                style={{ width: "74px" }}
              >
                {t("Cancel")}
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation("customization")(CustomizationForm)
