import {
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILED,
  GET_TEMPLATE_DATA,
  GET_TEMPLATE_DATA_SUCCESS,
  GET_TEMPLATE_DATA_FAILED,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILED,
  REMOVE_IMAGE,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILED,
  ADD_TEMPLATE,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAILED,
  RESET_TEMPLATE_ADDED,
  WEBFLOW_SYNC,
  WEBFLOW_SYNC_SUCCESS,
  WEBFLOW_SYNC_FAILED,
} from "./actionTypes"

export const getTemplates = () => ({
  type: GET_TEMPLATES,
  // payload: { tab, label },
})

export const getTemplatesSuccess = data => ({
  type: GET_TEMPLATES_SUCCESS,
  payload: data,
})

export const getTemplatesFailed = () => ({
  type: GET_TEMPLATES_FAILED,
})

export const getTemplateData = id => ({
  type: GET_TEMPLATE_DATA,
  payload: { id },
})

export const getTemplateDataSuccess = data => ({
  type: GET_TEMPLATE_DATA_SUCCESS,
  payload: data,
})

export const getTemplateDataFailed = () => ({
  type: GET_TEMPLATE_DATA_FAILED,
})

export const uploadImage = file => ({
  type: UPLOAD_IMAGE,
  payload: file,
})

export const uploadImageSuccess = fileName => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: fileName,
})

export const uploadImageFailed = error => ({
  type: UPLOAD_IMAGE_FAILED,
  payload: error,
})

export const removeImage = () => ({
  type: REMOVE_IMAGE,
})

export const updateTemplate = data => ({
  type: UPDATE_TEMPLATE,
  payload: data,
})

export const updateTemplateSuccess = () => ({
  type: UPDATE_TEMPLATE_SUCCESS,
})

export const updateTemplateFailed = error => ({
  type: UPDATE_TEMPLATE_FAILED,
  payload: error,
})

export const addTemplate = data => ({
  type: ADD_TEMPLATE,
  payload: data,
})

export const addTemplateSuccess = () => ({
  type: ADD_TEMPLATE_SUCCESS,
})

export const addTemplateFailed = error => ({
  type: ADD_TEMPLATE_FAILED,
  payload: error,
})

export const resetTemplateAdded = () => ({
  type: RESET_TEMPLATE_ADDED,
})

export const webflowSync = () => ({
  type: WEBFLOW_SYNC,
})

export const webflowSyncSuccess = () => ({
  type: WEBFLOW_SYNC_SUCCESS,
})

export const webflowSyncFailed = error => ({
  type: WEBFLOW_SYNC_FAILED,
  payload: error,
})
