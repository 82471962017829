import React, { useState } from "react"
import {
  Row,
  AccordionBody,
  AccordionHeader,
  Button,
  Modal,
  ModalBody,
} from "reactstrap"
import { map } from "lodash"

import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"

// Import components
import Part from "./Part"

//Import Actions
import { sectionCommentsChange, addPart, removeSection } from "store/actions"

//i18n
import { withTranslation } from "react-i18next"

const Section = ({ sectionId, t }) => {
  const dispatch = useDispatch()

  const [removeModal, setRemoveModal] = useState(false)

  const customizationData = createSelector(
    state => state.customization,
    customizationData => ({
      partImageUploadLoading: customizationData.partImageUploadLoading,
      partImageError: customizationData.partImageError,
      addPartLoading: customizationData.addPartLoading,
    })
  )

  const { partImageUploadLoading, partImageError, addPartLoading } =
    useSelector(customizationData)

  const sectionData = createSelector(
    state => state.customization.sections,
    sections => ({
      sectionName: sections[sectionId]?.section,
      parts: sections[sectionId]?.parts,
      comments: sections[sectionId]?.comments,
    })
  )

  const { sectionName, parts, comments } = useSelector(sectionData)

  const onCommentsChange = e => {
    dispatch(sectionCommentsChange(sectionId, e.target.value))
  }

  let partsCounter = 0

  const onAddPart = () => {
    if (addPartLoading) return

    dispatch(addPart(sectionId))
  }

  const confirmRemove = () => {
    dispatch(removeSection(sectionId))
  }

  const onRemoveSection = () => {
    setRemoveModal(true)
  }

  const closeRemoveModal = () => {
    setRemoveModal(false)
  }

  const sectionNames = {
    "About Us": t("About Us", { ns: "shared" }),
    Services: t("Services", { ns: "shared" }),
    Portfolio: t("Portfolio", { ns: "shared" }),
    Products: t("Products", { ns: "shared" }),
    Features: t("Features", { ns: "shared" }),
    Blog: t("Blog", { ns: "shared" }),
    Team: t("Team", { ns: "shared" }),
    Testimonials: t("Testimonials", { ns: "shared" }),
    Pricing: t("Pricing", { ns: "shared" }),
    FAQ: t("FAQ", { ns: "shared" }),
    Contact: t("Contact", { ns: "shared" }),
    Other: t("Other", { ns: "shared" }),
  }

  return (
    <>
      <AccordionHeader targetId={sectionId}>
        {sectionNames[sectionName] || sectionName}
      </AccordionHeader>
      <AccordionBody accordionId={sectionId}>
        {map(parts || [], (part, key) => {
          partsCounter++

          return (
            <Part
              key={key}
              sectionId={sectionId}
              sectionName={sectionNames[sectionName]}
              partId={key}
              partData={part}
              partNumber={partsCounter}
              partImageUploadLoading={partImageUploadLoading}
              partImageError={partImageError}
            />
          )
        })}
        <Row className="mb-3">
          <div>
            <Button
              color="primary"
              className="btn btn-primary waves-effect waves-light mt-1 mb-3"
              onClick={() => onAddPart()}
              style={{ width: "120px" }}
            >
              {addPartLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  style={{ marginBottom: "-2px" }}
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {!addPartLoading && (
                <>
                  <i
                    className="bx bx-plus font-size-16 align-middle me-1"
                    style={{ paddingBottom: "2px" }}
                  ></i>{" "}
                  {t("Add Part")}
                </>
              )}
            </Button>
          </div>
        </Row>
        <Row className="mb-3">
          <label htmlFor="step-two-comments" className="col-form-label">
            {t("Your comments")}
          </label>
          <div>
            <textarea
              className="form-control comments-input"
              name="comments"
              id="step-two-comments"
              placeholder=""
              rows="4"
              value={comments}
              onChange={onCommentsChange}
            ></textarea>
            <span className="text-muted">
              {t("Optionally, enter any comments you have about the data")}
            </span>
          </div>
        </Row>
        <Row className="mb-3">
          <label className="col-form-label"></label>
          <div
            className="d-inline-flex align-items-center remove-section-link"
            style={{ maxWidth: "160px" }}
            onClick={onRemoveSection}
          >
            <div className="remove-part-icon">
              <i className="bx bx-trash" />
            </div>
            <div
              className="text-muted"
              style={{ paddingLeft: "5px", paddingTop: "5px", height: "20px" }}
            >
              {t("Remove section")}
            </div>
          </div>
        </Row>
        <Modal
          size="md"
          isOpen={removeModal}
          toggle={closeRemoveModal}
          centered={true}
        >
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <button
                type="button"
                onClick={closeRemoveModal}
                className="btn-close position-absolute end-0 top-0 m-3"
              ></button>
              <div className="avatar-sm mb-4 mx-auto">
                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                  <i className="mdi mdi-trash-can-outline"></i>
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4">
                {t("Do you really want to remove section", {
                  section: sectionNames[sectionName],
                })}
              </p>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={confirmRemove}
                >
                  {t("Delete")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeRemoveModal}
                >
                  {t("Close")}
                </button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </AccordionBody>
    </>
  )
}

export default withTranslation("customization")(Section)
