import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Badge,
  Modal,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Constants
import { awsS3SiteScreenshotsBucketURL } from "../../constants/constants"

//i18n
import { withTranslation } from "react-i18next"

//Import functions
import { dateLong, capitalizeFirstLetter } from "../../common/functions"

const CardProject = ({ project, subscription, t }) => {
  const [dnsModal, setDnsModal] = useState(false)

  function toggleDnsModal() {
    setDnsModal(!dnsModal)
  }

  const plan = subscription.plan
  let planText

  if (plan === "starter") {
    planText = " Starter"
  } else if (plan === "premium") {
    planText = "Premium"
  } else if (plan === "ecommerce") {
    planText = "eCommerce"
  }

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <h4 className="card-title mb-3">
              {capitalizeFirstLetter(project.domain)}
            </h4>

            <Row className="gx-4">
              <Col lg="4" className="card-project-image-col">
                <CardImg
                  className="img-fluid"
                  src={awsS3SiteScreenshotsBucketURL + project.screenshot}
                  alt="Certified Pixel"
                />
              </Col>
              <Col lg="8" className="mt-4 mt-lg-0">
                <div className="card-project-grid">
                  <div className="card-project-item">
                    <p className="mb-2">{t("Domain")}</p>
                    <Link
                      to={"https://" + project.domain}
                      target="_blank"
                      className="d-flex align-items-baseline"
                    >
                      <h5>{project.domain}</h5>
                      <i className="mdi mdi-open-in-new ms-1" />
                    </Link>
                  </div>
                  <div className="card-project-item">
                    <p className="mb-2">{t("Plan")}</p>
                    <h5>{planText}</h5>
                  </div>
                  <div className="card-project-item">
                    <p className="mb-2">{t("Content editor")}</p>
                    <Link
                      to={"https://" + project.domain + "?edit"}
                      className="btn btn-primary btn-sm"
                      target="_blank"
                    >
                      {t("Open CMS")} <i className="mdi mdi-open-in-new ms-1" />
                    </Link>
                  </div>
                  <div className="card-project-item">
                    <p className="mb-2">{t("Status")}</p>
                    <h5>
                      {project.status === "finished" && (
                        <Badge className="badge-soft-primary p-2">
                          {t("Finished")}
                        </Badge>
                      )}
                      {project.status === "live" && (
                        <Badge className="badge-soft-success p-2">
                          {t("Active")}
                        </Badge>
                      )}
                    </h5>
                  </div>
                  <div className="card-project-item">
                    <p className="mb-2">{t("Paid until")}</p>
                    <h5>{dateLong(new Date(subscription.expires))}</h5>
                  </div>
                  <div className="card-project-item">
                    <p className="mb-2">{t("Staging version")}</p>
                    <Link
                      to={project.staging_url}
                      className="btn btn-primary btn-sm"
                      target="_blank"
                    >
                      {t("Open")} <i className="mdi mdi-open-in-new ms-1" />
                    </Link>
                  </div>
                  <div className="card-project-item">
                    <p className="mb-2">{t("DNS settings")}</p>
                    <button
                      type="button"
                      onClick={() => {
                        toggleDnsModal()
                      }}
                      className="btn btn-primary btn-sm"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      {t("Show records")}
                    </button>
                  </div>
                  <div className="card-project-item">
                    <p className="mb-2">{t("Manage subscription")}</p>
                    <Link to="/billing" className="btn btn-primary btn-sm">
                      {t("Billing")}
                    </Link>
                  </div>
                  <div className="card-project-item">
                    <p className="mb-2">{t("Email")}</p>
                    <div className="d-flex flex-column align-items-start">
                      <Link
                        to="https://mailadmin.certifiedpixel.cz:2222"
                        className="btn btn-primary btn-sm"
                        target="_blank"
                      >
                        Mailadmin <i className="mdi mdi-open-in-new ms-1" />
                      </Link>
                      <Link
                        to="https://webmail.certifiedpixel.cz"
                        className="btn btn-primary btn-sm mt-2"
                        target="_blank"
                      >
                        Webmail <i className="mdi mdi-open-in-new ms-1" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5">
                  <Row>
                    <Col>
                      <p className="mb-2 mt-4">Doména</p>
                      <Link
                        to="https://nibojs.cz"
                        target="_blank"
                        className="d-flex align-items-baseline"
                      >
                        <h5>nibojs.cz</h5>
                        <i className="mdi mdi-open-in-new ms-1" />
                      </Link>
                    </Col>
                    <Col>
                      <p className="mb-2 mt-4">Plán</p>
                      <h5>Premium</h5>
                    </Col>
                    <Col>
                      <p className="mt-4 mb-2">Správa obsahu</p>
                      <Link to="/billing" className="btn btn-primary btn-sm">
                        Otevřít CMS <i className="mdi mdi-open-in-new ms-1" />
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="mt-4 mb-2">Stav</p>
                      <h5>
                        <Badge className="badge-soft-success p-2">
                          Aktivní
                        </Badge>
                      </h5>
                    </Col>
                    <Col>
                      <p className="mt-4 mb-2">Uhrazeno do</p>
                      <h5>7.10.2024</h5>
                    </Col>
                    <Col>
                      <p className="mt-4 mb-2">Vývojářská verze</p>
                      <Link
                        to="https://nibojs.webflow.io"
                        className="btn btn-primary btn-sm"
                        target="_blank"
                      >
                        Otevřit <i className="mdi mdi-open-in-new ms-1" />
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="mt-4 mb-2">DNS nastavení</p>
                      <Link to="/billing" className="btn btn-primary btn-sm">
                        Zobrazit záznamy
                      </Link>
                    </Col>
                    <Col>
                      <p className="mt-4 mb-2">Správa předplatného</p>
                      <Link to="/billing" className="btn btn-primary btn-sm">
                        Fakturace
                      </Link>
                    </Col>
                    <Col>
                      <p className="mt-4 mb-2">Email</p>
                      <div className="d-flex flex-column align-items-start">
                        <Link to="/billing" className="btn btn-primary btn-sm">
                          Mailadmin
                        </Link>
                        <Link
                          to="/billing"
                          className="btn btn-primary btn-sm mt-2"
                        >
                          Webmail <i className="mdi mdi-open-in-new ms-1" />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div> */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <Modal
        isOpen={dnsModal}
        toggle={() => {
          toggleDnsModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t("DNS records")}
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleDnsModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <Table className="table mb-0 table-hover">
              <thead className="table-light">
                <tr>
                  <th>{t("Type")}</th>
                  <th>
                    {t("Name")} ({t("Host")})
                  </th>
                  <th>{t("Priority")}</th>
                  <th>{t("Value")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">A</th>
                  <td>@</td>
                  <td></td>
                  <td>75.2.70.75</td>
                </tr>
                <tr>
                  <th scope="row">A</th>
                  <td>@</td>
                  <td></td>
                  <td>99.83.190.102</td>
                </tr>
                <tr>
                  <th scope="row">CNAME</th>
                  <td>www</td>
                  <td></td>
                  <td>proxy-ssl.webflow.com</td>
                </tr>
                <tr>
                  <th scope="row">TXT</th>
                  <td>_webflow</td>
                  <td></td>
                  <td>{project.domain_one_time_verification}</td>
                </tr>
                <tr>
                  <th scope="row">MX</th>
                  <td>@</td>
                  <td>10</td>
                  <td>everest.mxrouting.net</td>
                </tr>
                <tr>
                  <th scope="row">MX</th>
                  <td>@</td>
                  <td>20</td>
                  <td>everest-relay.mxrouting.net</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              toggleDnsModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            {t("Close")}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation("dashboard")(CardProject)
