import React, { useState } from "react"
import {
  Row,
  Col,
  Input,
  UncontrolledAlert,
  Modal,
  ModalBody,
} from "reactstrap"
import { map } from "lodash"

//redux
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"

//Import Actions
import {
  partTextChange,
  uploadPartImage,
  removePartImage,
  removePart,
} from "store/actions"

//Import Constants
import { awsS3UserUploadBucketURL } from "../../constants/constants"

//Import Images
import removeIconRed from "../../assets/images/icons8-waste-24-red.png"
import removeIconGrey from "../../assets/images/icons/icons8-waste.svg"
import loadingIcon from "../../assets/images/icons8-loading.gif"

//i18n
import { withTranslation } from "react-i18next"

const Part = ({
  sectionId,
  sectionName,
  partId,
  partData,
  partNumber,
  partImageUploadLoading,
  partImageError,
  t,
}) => {
  const dispatch = useDispatch()

  const [removeModal, setRemoveModal] = useState(false)

  const onTextChange = e => {
    dispatch(partTextChange(sectionId, partId, e.target.value))
  }

  const onImageInputChange = async e => {
    if (e.target.files[0]) {
      const formData = new FormData()
      formData.append("file", e.target.files[0])

      dispatch(uploadPartImage(formData, sectionId, partId))
    }
  }

  const onRemoveImage = image => {
    dispatch(removePartImage(image, sectionId, partId))
  }

  const confirmRemove = () => {
    dispatch(removePart(partId, sectionId))
  }

  const onRemovePart = () => {
    setRemoveModal(true)
  }

  const closeRemoveModal = () => {
    setRemoveModal(false)
  }

  return (
    <div
      className="border card"
      style={{ padding: "20px", marginBottom: "10px" }}
    >
      <Row className="align-items-center mb-4">
        <Col className="col-9" style={{ paddingTop: "5px", height: "20px" }}>
          <h5 style={{ marginBottom: "0", lineHeight: "1" }}>
            {sectionName} {t("Part")} {partNumber}
          </h5>
        </Col>
        <Col className="col-3 d-flex justify-content-end">
          {partNumber !== 1 && (
            <div className="remove-part-icon" onClick={onRemovePart}>
              <i className="bx bx-trash" />
            </div>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <label htmlFor="main-text" className="col-form-label">
          {t("Text")}
        </label>
        <div>
          <textarea
            className="form-control"
            name="mainText"
            id="main-text"
            placeholder=""
            rows="8"
            value={partData.text}
            onChange={onTextChange}
          ></textarea>
        </div>
      </Row>
      <Row className="mb-3">
        <label className="col-form-label">{t("Images")}</label>
        <div>
          <Input
            className="form-control"
            type="file"
            accept="image/png, image/gif, image/jpeg, image/svg+xml"
            onChange={onImageInputChange}
            disabled={Object.keys(partImageUploadLoading).length !== 0}
          />
          <span className="text-muted" style={{ display: "block" }}>
            {t("Optionally upload images for this part")}
          </span>
          {partImageError[partId] && (
            <UncontrolledAlert
              color="danger"
              role="alert"
              style={{ marginTop: "10px", marginBottom: "5px" }}
            >
              {partImageError[partId]}
            </UncontrolledAlert>
          )}
          {partData.images.length !== 0 && (
            <div className="image-thumbnail-list-wrap">
              {map(partData.images, image => (
                <div
                  className="logo-thumbnail-wrap"
                  onClick={() => onRemoveImage(image)}
                  key={image}
                >
                  <img
                    className="img-thumbnail logo-thumbnail"
                    alt="Logo"
                    src={awsS3UserUploadBucketURL + image}
                    data-holder-rendered="true"
                  />
                  <div className="thumbnail-overlay">
                    <img
                      alt="Remove"
                      src={removeIconRed}
                      width={24}
                      data-holder-rendered="true"
                    />
                  </div>
                  <div className="thumbnail-bin-wrap">
                    <img
                      alt="Remove"
                      src={removeIconGrey}
                      width={24}
                      data-holder-rendered="true"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {partImageUploadLoading[partId] && (
            <div className="logo-thumbnail-wrap ">
              <img alt="Loading" src={loadingIcon} width={36} />
            </div>
          )}
        </div>
      </Row>
      {/* <Modal isOpen={removeModal} toggle={closeRemoveModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Delete section part
          </h5>
          <button
            type="button"
            onClick={closeRemoveModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Do you really want to remove {sectionName} Part {partNumber}?
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={closeRemoveModal}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger "
            onClick={confirmRemove}
          >
            Delete
          </button>
        </div>
      </Modal> */}

      <Modal
        size="md"
        isOpen={removeModal}
        toggle={closeRemoveModal}
        centered={true}
      >
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              onClick={closeRemoveModal}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              {t("Do you really want to remove part", {
                section: sectionName,
                part: partNumber,
              })}
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirmRemove}
              >
                {t("Delete")}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeRemoveModal}
              >
                {t("Close")}
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default withTranslation("customization")(Part)
