import React, { useEffect } from "react"

const DocumentDownload = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    let file

    for (const [key] of urlParams.entries()) {
      file = key
      break
    }

    console.log("file", file)

    const fileLocation =
      process.env.REACT_APP_AWS_DOCUMENTS_FOLDER + "/" + file + ".pdf"
    console.log("fileLocation", fileLocation)

    window.location = fileLocation
  }, [])
}

export default DocumentDownload
