import React from "react"
import { Button, Row, Col, Modal, ModalBody } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const Toolbar = ({
  show,
  refetch,
  activeTab,
  deleteModal,
  toggleDeleteModal,
  deleteSelected,
  t,
}) => {
  let categoryName = t("Active Tickets")

  if (activeTab === 2) categoryName = t("Closed Tickets")
  if (activeTab === 3) categoryName = t("Starred")

  return (
    <React.Fragment>
      <div className="p-3 message-list pb-0 pt-1">
        <Row className="justify-content-between mt-2 mb-2">
          <Col xs="10" style={{ minHeight: "36px" }}>
            <div
              className="d-flex align-items-center gap-2"
              style={{ minHeight: "36px" }}
            >
              {!show && <h5 className="m-0">{categoryName}</h5>}
              {show && (
                <div className="btn-toolbar" role="toolbar">
                  <div className="btn-group me-2 mb-0">
                    <Button
                      type="button"
                      color="primary"
                      onClick={toggleDeleteModal}
                    >
                      <i className="far fa-trash-alt" />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col xs="2" className="d-flex align-items-center justify-content-end">
            <div onClick={refetch} className="icon-hover-wrap">
              <i
                className="bx bx-sync font-size-24 align-middle me-1"
                style={{ color: "#555b6d" }}
              ></i>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        size="md"
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        centered={true}
      >
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              onClick={toggleDeleteModal}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              {t("Are you sure you want to delete")}
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteSelected}
              >
                {t("Delete Now")}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={toggleDeleteModal}
              >
                {t("Close")}
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation("tickets")(Toolbar)
