import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

// Redux
import { Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/icons/profile.png"

const ProfileMenu = ({ t }) => {
  const navigate = useNavigate()

  const [menu, setMenu] = useState(false)

  const authUser = JSON.parse(localStorage.getItem("authUser"))

  const [username, setUsername] = useState(() => {
    if (authUser?.username) return authUser.username
    else return "Admin"
  })

  useEffect(() => {
    if (!authUser) {
      navigate("/logout")
    }
  }, [authUser])

  if (!authUser) return null

  const { admin } = authUser

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to={admin ? "/settings-admin" : "/settings"}
            style={{ color: "var(--bs-dropdown-link-color)" }}
          >
            <DropdownItem>
              <i className="bx bx-wrench font-size-16 align-middle me-2" />
              {t("Settings")}
            </DropdownItem>
          </Link>

          {!admin && (
            <Link
              to="/billing"
              style={{ color: "var(--bs-dropdown-link-color)" }}
            >
              {" "}
              <DropdownItem>
                <i className="bx bx-credit-card font-size-16 align-middle me-2" />
                {t("Billing")}
              </DropdownItem>
            </Link>
          )}

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-2 text-danger" />
            <span>{t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   t: PropTypes.any,
// }

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile
//   return { error, success }
// }

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
// )

export default withTranslation("menu")(ProfileMenu)
