import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, loginFailed } from "./actions"

import { postLogin } from "../../../helpers/api_requests"

function* loginUser({ payload: { user, loading } }) {
  try {
    if (loading) {
      // login still loading
      return
    }

    const response = yield call(postLogin, {
      username: user.username,
      password: user.password,
    })

    if (response.status) {
      // login successfull
      localStorage.setItem("authUser", JSON.stringify(response.data))
      if (!response.data.admin) {
        window.location = "/dashboard"
      } else {
        window.location = "/dashboard-admin"
      }
      yield put(loginSuccess(response))
    } else {
      yield put(loginFailed(response.error?.message))
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser")

    window.location.href = "/login"
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
