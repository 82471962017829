import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const activityData = t => {
  return [
    {
      id: 1,
      active: false,
      date: `${t("Step")} 1`,
      activity: t("Select design template"),
    },
    {
      id: 2,
      active: false,
      date: `${t("Step")} 2`,
      activity: t("Prepare and start data submission"),
    },
    {
      id: 3,
      active: false,
      date: `${t("Step")} 3`,
      activity: t("Enter basic website information"),
    },
    {
      id: 4,
      active: true,
      date: `${t("Step")} 4`,
      activity: t("Upload a logo and define the homepage content"),
    },
    {
      id: 5,
      active: false,
      date: `${t("Step")} 5`,
      activity: t("Specify the content of each website section"),
    },
    {
      id: 6,
      active: false,
      date: `${t("Step")} 6`,
      activity: t("Submit data"),
    },
  ]
}

export const CustomizationFormProgress = ({ active, t }) => {
  return (
    <React.Fragment>
      <Card style={{ height: "calc(100% - 24px)" }}>
        <CardBody>
          <CardTitle className="mb-4">{t("Data Submission Process")}</CardTitle>
          <ul className="verti-timeline list-unstyled">
            {(activityData(t) || [])?.map((item, index) => (
              <li
                className={`event-list ${item.id === active && "active"}`}
                key={index}
              >
                <div className="event-timeline-dot">
                  <i
                    className={`font-size-18 bx ${
                      item.id === active
                        ? "bxs-right-arrow-circle bx-fade-right"
                        : "bx-right-arrow-circle"
                    }`}
                  />
                </div>
                <div className="flex-shrink-0 d-flex">
                  <div className="me-3">
                    <h5 className="font-size-14 text-nowrap">
                      {item.date}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>{item.activity}</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withTranslation("customization")(CustomizationFormProgress)
