import {
  GET_PAYMENT_REQUESTS_DATA,
  GET_PAYMENT_REQUESTS_DATA_SUCCESS,
  GET_PAYMENT_REQUESTS_DATA_FAILED,
  MARK_PROFORMA_PAID,
  MARK_PROFORMA_PAID_SUCCESS,
  MARK_PROFORMA_PAID_FAILED,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED,
} from "./actionTypes"

export const getPaymentRequestsData = () => ({
  type: GET_PAYMENT_REQUESTS_DATA,
})

export const getPaymentRequestsDataSuccess = data => ({
  type: GET_PAYMENT_REQUESTS_DATA_SUCCESS,
  payload: data,
})

export const getPaymentRequestsDataFailed = () => ({
  type: GET_PAYMENT_REQUESTS_DATA_FAILED,
})

export const markProformaPaid = id => ({
  type: MARK_PROFORMA_PAID,
  payload: id,
})

export const markProformaPaidSuccess = response => ({
  type: MARK_PROFORMA_PAID_SUCCESS,
  payload: response,
})

export const markProformaPaidFailed = () => ({
  type: MARK_PROFORMA_PAID_FAILED,
})

export const createAccount = id => ({
  type: CREATE_ACCOUNT,
  payload: id,
})

export const createAccountSuccess = response => ({
  type: CREATE_ACCOUNT_SUCCESS,
  payload: response,
})

export const createAccountFailed = () => ({
  type: CREATE_ACCOUNT_FAILED,
})
