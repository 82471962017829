import {
  REQUEST_RESET,
  REQUEST_RESET_SUCCESS,
  REQUEST_RESET_ERROR,
  VERIFY_RESET_LINK,
  VERIFY_RESET_LINK_FAILED,
  VERIFY_RESET_LINK_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from "./actionTypes"

const initialState = {
  requestLoading: false,
  requestError: null,
  requestSuccess: false,
  verifyLoading: false,
  verifyError: null,
  resetLoading: false,
  resetError: null,
  resetSuccess: false,
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_RESET:
      return {
        ...state,
        requestLoading: true,
        requestError: null,
        requestSuccess: false,
      }
    case REQUEST_RESET_SUCCESS:
      return {
        ...state,
        requestLoading: false,
        requestSuccess: true,
      }
    case REQUEST_RESET_ERROR:
      return { ...state, requestLoading: false, requestError: action.payload }
    case VERIFY_RESET_LINK:
      return {
        ...state,
        verifyLoading: true,
        verifyError: null,
      }
    case VERIFY_RESET_LINK_FAILED:
      return {
        ...state,
        verifyLoading: false,
        verifyError: action.payload,
      }
    case VERIFY_RESET_LINK_SUCCESS:
      return {
        ...state,
        verifyLoading: false,
      }
    case RESET_PASSWORD:
      return {
        ...state,
        resetLoading: true,
        resetError: null,
        resetSuccess: false,
      }
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetLoading: false,
        resetError: action.payload,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetLoading: false,
        resetSuccess: true,
      }
    default:
      return { ...state }
  }
}

export default forgetPassword
