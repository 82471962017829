import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardText,
  UncontrolledAlert,
  Button,
  FormFeedback,
} from "reactstrap"
import { map } from "lodash"
import Select from "react-select"

//redux
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"

//Import Actions
import {
  uploadAttachment,
  removeAttachment,
  getAllAccounts,
  closeTicket,
} from "store/actions"

//Import functions
import { fileTypeIcon } from "common/functions"

//Import Constants
import { awsS3UserUploadBucketURL } from "../../constants/constants"

//Import Images
import loadingIcon from "../../assets/images/icons8-loading.gif"

//i18n
import { withTranslation } from "react-i18next"

const TicketForm = ({ type, submitReply, ticketId, status, t }) => {
  const agent = JSON.parse(localStorage.getItem("authUser"))?.agent

  const dispatch = useDispatch()

  const ticketFormData = createSelector(
    state => state.tickets,
    ticketFormData => {
      return {
        attachmentUploadLoading: ticketFormData.attachmentUploadLoading,
        attachmentError: ticketFormData.attachmentError,
        attachments: ticketFormData.attachments,
        submitTicketLoading: ticketFormData.submitTicketLoading,
        submitTickerError: ticketFormData.submitTickerError,
        accountSelection: ticketFormData.accountSelection,
        closeTicketLoading: ticketFormData.closeTicketLoading,
      }
    }
  )

  const {
    attachmentUploadLoading,
    attachmentError,
    attachments,
    submitTicketLoading,
    submitTickerError,
    accountSelection,
    closeTicketLoading,
  } = useSelector(ticketFormData)

  const [inputs, setInputs] = useState({ subject: "", message: "" })
  const [account, setAccount] = useState({})
  const [errors, setErrors] = useState([])
  const [fileLimitError, setFileLimitError] = useState(false)

  const onInputChange = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  // handle attachment upload
  const onFileInputChange = async e => {
    if (e.target.files[0]) {
      // limit max number of attachments
      if (attachments.length >= 20) {
        setFileLimitError(true)
        return
      } else {
        setFileLimitError(false)
      }

      const formData = new FormData()
      formData.append("file", e.target.files[0])

      dispatch(uploadAttachment(formData))
    }
  }

  const onRemoveAttachment = attachment => {
    dispatch(removeAttachment(attachment))
  }

  // handle form submission
  const onSubmitClick = close => {
    if (submitTicketLoading) return

    const { subject, message } = inputs

    let newErrors = []

    if (type === "newTicket" && subject.length < 3) {
      newErrors.push("subject")
    }

    if (message.length < 2) {
      newErrors.push("message")
    }

    if (type === "newTicket" && agent && !account.value) {
      newErrors.push("account")
    }

    if (newErrors.length > 0) {
      setErrors(newErrors)
      return
    }

    setErrors([])

    submitReply(account, type, subject, message, attachments, ticketId, close)
  }

  // close Ticket without message
  const onCloseTicketClick = () => {
    dispatch(closeTicket(ticketId))
  }

  // reset inputs on successful submit
  useEffect(() => {
    if (submitTicketLoading === false && submitTickerError === null) {
      setInputs({ subject: "", message: "" })
    }
  }, [submitTicketLoading])

  // prefetch list of recipinets for admin
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))

    if (user.admin) {
      dispatch(getAllAccounts())
    }
  }, [])

  const handleSelectAccount = selected => {
    setAccount(selected)
  }

  // style inputs on error
  useEffect(() => {
    if (errors.includes("message")) {
      document.getElementById("message").classList.add("textarea-invalid")
    } else {
      document.getElementById("message").classList.remove("textarea-invalid")
    }
  }, [errors])

  return (
    <>
      {type === "newTicket" && (
        <>
          <CardTitle className="h4 mb-md-4">{t("Create New Ticket")}</CardTitle>
          {agent && (
            <Row className="mt-3 mt-md-4">
              <Label htmlFor="account" className="col-form-label">
                {t("Account")}
              </Label>
              <div>
                <Select
                  value={account}
                  onChange={handleSelectAccount}
                  options={accountSelection}
                  className="select2-selection"
                />
                {errors.includes("account") && (
                  <div className="font-size-10 text-danger">
                    {t("Please select account")}
                  </div>
                )}
              </div>
            </Row>
          )}
          <Row className="mb-3 mt-3">
            <Label htmlFor="subject" className="col-form-label">
              {t("Subject")}
            </Label>
            <div>
              <Input
                className="form-control"
                type="text"
                name="subject"
                id="subject"
                value={inputs.subject}
                onChange={onInputChange}
                invalid={errors.includes("subject")}
                maxLength={90}
              />
              {errors.includes("subject") && (
                <FormFeedback type="invalid">
                  {t("Please insert subject")}
                </FormFeedback>
              )}
            </div>
          </Row>
        </>
      )}
      <Row className="mb-3">
        <Label htmlFor="message" className="col-form-label">
          {type === "newTicket" ? t("Message") : t("Reply message")}
        </Label>
        <div>
          <Input
            className="form-control"
            type="textarea"
            name="message"
            id="message"
            rows="8"
            value={inputs.message}
            onChange={onInputChange}
            maxLength={2000}
          />
        </div>
      </Row>
      <Row className="mb-1">
        <label className="col-form-label">{t("Attach files")}</label>
        <div>
          <Input
            className="form-control"
            type="file"
            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            onChange={onFileInputChange}
            disabled={attachmentUploadLoading}
          />
          {fileLimitError && (
            <UncontrolledAlert
              color="danger"
              role="alert"
              style={{ marginTop: "10px", marginBottom: "5px" }}
            >
              {t("You can't attach more than 20 files")}
            </UncontrolledAlert>
          )}
          {attachmentError && (
            <UncontrolledAlert
              color="danger"
              role="alert"
              style={{ marginTop: "10px", marginBottom: "5px" }}
            >
              {attachmentError.error}
            </UncontrolledAlert>
          )}
          <div className="attachments-list-wrap mt-3">
            {map(attachments, attachment => (
              <div
                className="d-flex flex-column mb-2"
                style={{ maxWidth: "138px" }}
              >
                <Link
                  to={awsS3UserUploadBucketURL + attachment.file}
                  target="_blank"
                  download
                >
                  <Card key={attachment} className="me-2 mb-0 attachment-card">
                    <div className="d-flex justify-content-center align-items-center p-3">
                      <CardImg
                        className="img-fluid"
                        src={fileTypeIcon(attachment.file)}
                        alt="Certified Pixel"
                        style={{ maxWidth: "36px" }}
                      />
                    </div>
                    <CardBody className="p-2" style={{ background: "#ededed" }}>
                      <CardText className="text-center">
                        <small className="d-block text-truncate">
                          {attachment.name}
                        </small>
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
                <div
                  className="remove-attachment-link mt-1"
                  onClick={() => onRemoveAttachment(attachment)}
                >
                  <small
                    className="d-inline-flex align-items-center text-truncate"
                    style={{ maxWidth: "120px" }}
                  >
                    <i
                      className="bx bx-x font-size-16"
                      style={{ paddingBottom: "1px" }}
                    ></i>{" "}
                    {t("Remove")} {attachment.name}
                  </small>
                </div>
              </div>
            ))}
            {attachmentUploadLoading && (
              <div className="attachment-loading-wrap">
                <img alt="Loading" src={loadingIcon} width={36} />
              </div>
            )}
          </div>
        </div>
      </Row>
      {submitTickerError && (
        <Row>
          <div>
            <UncontrolledAlert
              color="danger"
              role="alert"
              style={{ marginBottom: "10px" }}
            >
              {t("There was an error when submitting the request")}
            </UncontrolledAlert>
          </div>
        </Row>
      )}
      <Row>
        <label className="col-form-label p-0"></label>
        <div className="d-flex justify-content-between">
          <Button
            color="primary"
            className="btn btn-primary waves-effect waves-light mb-3"
            onClick={() => onSubmitClick()}
            disabled={submitTicketLoading || closeTicketLoading}
          >
            {t("Send")}
          </Button>
          {agent && type === "reply" && (
            <div>
              <Button
                color="primary"
                className="btn btn-secondary waves-effect waves-light mb-3"
                onClick={() => onSubmitClick(true)}
                disabled={submitTicketLoading || closeTicketLoading}
                style={{ marginRight: "0.5rem" }}
              >
                {t("Send and Close")}
              </Button>
              {status !== "closed" && (
                <Button
                  color="primary"
                  className="btn btn-secondary waves-effect waves-light mb-3"
                  onClick={onCloseTicketClick}
                  disabled={submitTicketLoading || closeTicketLoading}
                >
                  {t("Close")}
                </Button>
              )}
            </div>
          )}
        </div>
      </Row>
    </>
  )
}

export default withTranslation("tickets")(TicketForm)
