import axios from "axios"

//pass new generated access token here
const authUserData = localStorage.getItem("authUser") || "{}"

const token = JSON.parse(authUserData).token

const authorizationHeader = token ? `Bearer ${token}` : null

const axiosApi = axios.create({
  //baseURL: API_URL,
  baseURL: process.env.REACT_APP_API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = authorizationHeader

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, data, config = {}) {
  let getUrl = url

  if (data) {
    const queryString = new URLSearchParams(data).toString()
    getUrl += "?" + queryString
  }

  return await axiosApi
    .get(getUrl, { ...config })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function postFile(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}
