import {
  REQUEST_RESET,
  REQUEST_RESET_SUCCESS,
  REQUEST_RESET_ERROR,
  VERIFY_RESET_LINK,
  VERIFY_RESET_LINK_FAILED,
  VERIFY_RESET_LINK_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from "./actionTypes"

export const requestReset = user => {
  return {
    type: REQUEST_RESET,
    payload: { user },
  }
}

export const requestResetSuccess = () => {
  return {
    type: REQUEST_RESET_SUCCESS,
  }
}

export const requestResetFailed = error => {
  return {
    type: REQUEST_RESET_ERROR,
    payload: error,
  }
}

export const verifyResetLink = (x, y) => {
  return {
    type: VERIFY_RESET_LINK,
    payload: { x, y },
  }
}

export const verifyRequestLinkFailed = error => {
  return {
    type: VERIFY_RESET_LINK_FAILED,
    payload: error,
  }
}

export const verifyRequestLinkSuccess = () => {
  return {
    type: VERIFY_RESET_LINK_SUCCESS,
  }
}

export const resetPassword = data => {
  return {
    type: RESET_PASSWORD,
    payload: data,
  }
}

export const resetPasswordFailed = error => {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: error,
  }
}

export const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  }
}
