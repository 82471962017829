import structuredClone from "@ungap/structured-clone"

import {
  GET_CUSTOMIZATION_DATA,
  GET_CUSTOMIZATION_DATA_SUCCESS,
  STEP_ONE_CHANGE,
  SAVE_STEP_ONE_DATA,
  STEP_TWO_CHANGE,
  SAVE_STEP_TWO_DATA,
  STEP_FOUR_CHANGE,
  SAVE_STEP_FOUR_DATA,
  SAVE_CUSTOMIZATION_DATA_SUCCESS,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_FAILED,
  REMOVE_LOGO,
  DISMISS_LOGO_ERROR,
  UPLOAD_HOME_IMAGE,
  UPLOAD_HOME_IMAGE_SUCCESS,
  UPLOAD_HOME_IMAGE_FAILED,
  REMOVE_HOME_IMAGE,
  PART_TEXT_CHANGE,
  SECTION_COMMENTS_CHANGE,
  ADD_SECTION,
  ADD_SECTION_FAILED,
  ADD_SECTION_SUCCESS,
  SAVE_SECTIONS_DATA,
  UPLOAD_PART_IMAGE,
  UPLOAD_PART_IMAGE_SUCCESS,
  UPLOAD_PART_IMAGE_FAILED,
  REMOVE_PART_IMAGE,
  ADD_PART,
  ADD_PART_SUCCESS,
  ADD_PART_FAILED,
  REMOVE_PART,
  REMOVE_SECTION,
  FINISH_SUBMISSION,
  FINISH_SUBMISSION_SUCCESS,
  GET_CUSTOMIZATION_DATA_FAILED,
  GET_TEMPLATE_DESIGNS,
  GET_TEMPLATE_DESIGNS_SUCCESS,
  GET_TEMPLATE_DESIGNS_FAILED,
  SELECT_TEMPLATE_DESIGN,
  SELECT_TEMPLATE_DESIGN_SUCCESS,
  SELECT_TEMPLATE_DESIGN_FAILED,
  SKIP_TEMPLATE,
  SKIP_TEMPLATE_SUCCESS,
  SKIP_TEMPLATE_FAILED,
  RESET_SKIPPED_TEMPLATE,
  REMOVE_TEMPLATE,
  REMOVE_TEMPLATE_SUCCESS,
  REMOVE_TEMPLATE_FAILED,
} from "./actionTypes"

const initialState = {
  companyName: "",
  domain: "",
  companyDescription: "",
  oldWebsite: "",
  stepOneComments: "",
  logo: "",
  title: "",
  subTitle: "",
  mainText: "",
  stepTwoComments: "",
  sections: {},
  likedWebsites: "",
  finalComments: "",
  loading: false,
  saveLoading: false,
  logoUploadLoading: false,
  logoError: null,
  homeImages: [],
  homeImageUploadLoading: false,
  homeImageError: null,
  addSectionLoading: false,
  addSectionError: null,
  partImageUploadLoading: {},
  partImageError: {},
  addPartLoading: false,
  finishSubmissionLoading: false,
  formSubmitted: false,
  loadingTemplates: false,
  templates: [],
  selectTemplateLoading: false,
  selectTemplateError: null,
  selectedTemplate: null,
  categories: [],
  numOfResults: 0,
  skipTemplateLoading: false,
  skippedTemplate: false,
  removeTemplateLoading: false,
}

// move sections data into object
const parseSections = sections => {
  let parsedSections = {}

  for (const section of sections) {
    let parsedParts = {}

    for (const part of section.parts) {
      parsedParts[part.id] = {
        text: part.text,
        images: part.images,
      }
    }

    parsedSections[section.id] = {
      section: section.section,
      parts: parsedParts,
      comments: section.comments,
    }
  }

  return parsedSections
}

const customization = (state = initialState, action) => {
  let updatedSections = structuredClone(state.sections)

  switch (action.type) {
    case GET_CUSTOMIZATION_DATA:
      return {
        ...state,
        loading: true,
      }

    case GET_CUSTOMIZATION_DATA_SUCCESS:
      return {
        ...state,
        companyName: action.payload.data.company_name,
        companyDescription: action.payload.data.company_description,
        domain: action.payload.data.domain,
        oldWebsite: action.payload.data.old_website,
        //contents: action.payload.data.contents,
        stepOneComments: action.payload.data.step_one_comments,
        logo: action.payload.data.logo,
        title: action.payload.data.title,
        subTitle: action.payload.data.sub_title,
        mainText: action.payload.data.main_text,
        homeImages: action.payload.data.home_images,
        stepTwoComments: action.payload.data.step_two_comments,
        sections: parseSections(action.payload.data.sections),
        likedWebsites: action.payload.data.liked_websites,
        finalComments: action.payload.data.final_comments,
        loading: false,
      }

    case GET_CUSTOMIZATION_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }

    case STEP_ONE_CHANGE:
      return {
        ...state,
        [action.payload.input]: action.payload.value,
      }

    case SAVE_STEP_ONE_DATA:
      return {
        ...state,
        saveLoading: true,
      }

    case STEP_TWO_CHANGE:
      return {
        ...state,
        [action.payload.input]: action.payload.value,
      }

    case SAVE_STEP_TWO_DATA:
      return {
        ...state,
        saveLoading: true,
        logoError: null,
      }

    case STEP_FOUR_CHANGE:
      return {
        ...state,
        [action.payload.input]: action.payload.value,
      }

    case SAVE_STEP_FOUR_DATA:
      return {
        ...state,
        saveLoading: true,
      }

    case SAVE_CUSTOMIZATION_DATA_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      }

    case UPLOAD_LOGO:
      return {
        ...state,
        logoUploadLoading: true,
        logoError: null,
      }

    case UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        logoUploadLoading: false,
        logo: action.payload,
      }

    case UPLOAD_LOGO_FAILED:
      return {
        ...state,
        logoUploadLoading: false,
        logoError: action.payload,
      }

    case DISMISS_LOGO_ERROR:
      return {
        ...state,
        logoError: null,
      }

    case REMOVE_LOGO:
      return {
        ...state,
        logo: "",
      }

    case UPLOAD_HOME_IMAGE:
      return {
        ...state,
        homeImageUploadLoading: true,
        homeImageError: null,
      }

    case UPLOAD_HOME_IMAGE_SUCCESS:
      return {
        ...state,
        homeImageUploadLoading: false,
        homeImages: [...state.homeImages, action.payload],
      }

    case UPLOAD_HOME_IMAGE_FAILED:
      return {
        ...state,
        homeImageUploadLoading: false,
        homeImageError: action.payload,
      }

    case REMOVE_HOME_IMAGE:
      const newHomeImages = state.homeImages.filter(
        image => image !== action.payload
      )

      return {
        ...state,
        homeImages: newHomeImages,
      }

    case PART_TEXT_CHANGE:
      const { partId, text } = action.payload

      updatedSections[action.payload.sectionId].parts[partId].text = text

      return {
        ...state,
        sections: updatedSections,
      }

    case SECTION_COMMENTS_CHANGE:
      updatedSections[action.payload.sectionId].comments =
        action.payload.comments

      return {
        ...state,
        sections: updatedSections,
      }

    case ADD_SECTION:
      return {
        ...state,
        addSectionLoading: true,
      }

    case ADD_SECTION_FAILED:
      return {
        ...state,
        addSectionLoading: false,
        addSectionError: action.payload,
      }

    case ADD_SECTION_SUCCESS:
      updatedSections[action.payload.sectionId] = {
        section: action.payload.sectionName,
        parts: { [action.payload.partId]: { text: "", images: [] } },
        comments: "",
      }

      return {
        ...state,
        addSectionLoading: false,
        sections: updatedSections,
      }

    case SAVE_SECTIONS_DATA:
      return {
        ...state,
        saveLoading: true,
      }

    case UPLOAD_PART_IMAGE:
      return {
        ...state,
        partImageUploadLoading: { [action.payload.partId]: true },
        partImageError: {},
      }

    case UPLOAD_PART_IMAGE_SUCCESS:
      updatedSections[action.payload.sectionId].parts[
        action.payload.partId
      ].images = [
        ...updatedSections[action.payload.sectionId].parts[
          action.payload.partId
        ].images,
        action.payload.fileName,
      ]

      return {
        ...state,
        sections: updatedSections,
        partImageUploadLoading: {},
      }

    case UPLOAD_PART_IMAGE_FAILED:
      return {
        ...state,
        partImageUploadLoading: {},
        partImageError: { [action.payload.partId]: action.payload.error },
      }

    case REMOVE_PART_IMAGE:
      updatedSections[action.payload.sectionId].parts[
        action.payload.partId
      ].images = updatedSections[action.payload.sectionId].parts[
        action.payload.partId
      ].images.filter(image => image !== action.payload.image)

      return {
        ...state,
        sections: updatedSections,
      }

    case ADD_PART:
      return {
        ...state,
        addPartLoading: true,
      }

    case ADD_PART_SUCCESS:
      updatedSections[action.payload.sectionId].parts[action.payload.partId] = {
        text: "",
        images: [],
      }

      return {
        ...state,
        addPartLoading: false,
        sections: updatedSections,
      }

    case ADD_PART_FAILED:
      return {
        ...state,
        addPartLoading: false,
      }

    case REMOVE_PART:
      let sectionParts = updatedSections[action.payload.sectionId].parts

      sectionParts = Object.keys(sectionParts)
        .filter(part => part !== action.payload.partId)
        .reduce((newObj, key) => {
          newObj[key] = sectionParts[key]
          return newObj
        }, {})

      updatedSections[action.payload.sectionId].parts = sectionParts

      return {
        ...state,
        sections: updatedSections,
      }

    case REMOVE_SECTION:
      updatedSections = Object.keys(updatedSections)
        .filter(section => section !== action.payload.sectionId)
        .reduce((newObj, key) => {
          newObj[key] = updatedSections[key]
          return newObj
        }, {})

      return {
        ...state,
        sections: updatedSections,
      }

    case FINISH_SUBMISSION:
      return {
        ...state,
        finishSubmissionLoading: true,
      }

    case FINISH_SUBMISSION_SUCCESS:
      return {
        ...state,
        finishSubmissionLoading: false,
        formSubmitted: true,
      }

    case GET_TEMPLATE_DESIGNS:
      return {
        ...state,
        loadingTemplates: true,
      }

    case GET_TEMPLATE_DESIGNS_FAILED:
      return {
        ...state,
        loadingTemplates: false,
      }

    case GET_TEMPLATE_DESIGNS_SUCCESS:
      return {
        ...state,
        loadingTemplates: false,
        templates: action.payload.templates,
        categories: action.payload.categories,
        selectedTemplate: action.payload.selectedTemplate,
        numOfResults: action.payload.numOfResults,
      }

    case SELECT_TEMPLATE_DESIGN:
      return {
        ...state,
        selectTemplateLoading: true,
      }

    case SELECT_TEMPLATE_DESIGN_FAILED:
      return {
        ...state,
        selectTemplateLoading: false,
        selectTemplateError: action.payload,
      }

    case SELECT_TEMPLATE_DESIGN_SUCCESS:
      return {
        ...state,
        selectTemplateLoading: false,
      }

    case SKIP_TEMPLATE:
      return {
        ...state,
        skipTemplateLoading: true,
        skippedTemplate: false,
      }

    case SKIP_TEMPLATE_SUCCESS:
      return {
        ...state,
        skipTemplateLoading: false,
        skippedTemplate: true,
      }

    case SKIP_TEMPLATE_FAILED:
      return {
        ...state,
        skipTemplateLoading: false,
      }

    case RESET_SKIPPED_TEMPLATE:
      return {
        ...state,
        skippedTemplate: false,
      }

    case REMOVE_TEMPLATE:
      return {
        ...state,
        removeTemplateLoading: true,
      }

    case REMOVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        removeTemplateLoading: false,
      }

    case REMOVE_TEMPLATE_FAILED:
      return {
        ...state,
        removeTemplateLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default customization
