import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  Button,
  Alert,
  Table,
  Badge,
  Modal,
  Spinner,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

//i18n
import { withTranslation } from "react-i18next"

//Import functions
import { dateShort, dateLong } from "../../common/functions"

// Import Actions
import { getRequests, getRequestDetail } from "store/actions"

const Requests = ({ t }) => {
  //meta title
  document.title = t("Title Requests")

  const dispatch = useDispatch()

  const [requestModal, setRequestModal] = useState(false)

  const requestsData = createSelector(
    state => state.requests,
    requestsData => {
      return {
        loadingRequests: requestsData.loadingRequests,
        requests: requestsData.requests,
        loadingRequestDetail: requestsData.loadingRequestDetail,
        requestDetail: requestsData.requestDetail,
      }
    }
  )

  const { loadingRequests, requests, loadingRequestDetail, requestDetail } =
    useSelector(requestsData)

  useEffect(() => {
    dispatch(getRequests())
  }, [])

  const openRequestDetail = id => {
    dispatch(getRequestDetail(id))
    setRequestModal(true)
  }

  const closeDetail = () => {
    setRequestModal(false)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const request = urlParams.get("request")

    if (request) {
      openRequestDetail(request)
    }
  }, [])

  const getStatusBadge = status => {
    let statusText
    let statusType

    switch (status) {
      case "waiting":
        statusText = t("Waiting for payment")
        statusType = "badge-soft-warning"
        break
      case "inprogress":
        statusText = t("In progress")
        statusType = "badge-soft-primary"
        break
      case "finished":
        statusText = t("Finished")
        statusType = "badge-soft-success"
        break
    }

    return (
      <Badge style={{ fontSize: "12px" }} className={`${statusType} p-2`}>
        {statusText}
      </Badge>
    )
  }

  const list = (requests || []).map(
    ({ id, created_at, name, price, paid, status }) => {
      return (
        <tr
          key={id}
          onClick={() => openRequestDetail(id)}
          className="requests-small-table-row"
        >
          <td className="requests-table-cell">
            {dateShort(new Date(created_at))}
          </td>
          <td className="requests-table-cell">{name}</td>
          <td className="requests-table-cell">{price.toFixed(2)} Kč</td>
          <td className="requests-table-cell">
            <Badge
              style={{ fontSize: "12px" }}
              className={`${
                paid ? "badge-soft-success" : "badge-soft-danger"
              } p-2`}
            >
              {paid ? t("Yes") : t("No")}
            </Badge>
          </td>
          <td className="requests-table-cell" style={{ textAlign: "right" }}>
            {getStatusBadge(status)}
          </td>
        </tr>
      )
    }
  )

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("Dashboard")}
            titleLink="/dashboard"
            breadcrumbItems={[{ link: "", item: t("Requests") }]}
          />
          <Row>
            <Col>
              <Card style={{ maxWidth: "900px" }}>
                <CardBody>
                  <h4 className="card-title mb-3">{t("Update requests")}</h4>
                  <p className="card-title-desc">
                    {t("If you need to make any changes")}
                  </p>
                  <div
                    style={{ minHeight: "250px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {!loadingRequests && (
                      <>
                        {list.length !== 0 && (
                          <div className="table-responsive">
                            <Table
                              className="table mb-0 table-hover requests-table"
                              style={{ minWidth: "700px" }}
                            >
                              <thead className="table-light">
                                <tr>
                                  <th style={{ width: "100px" }}>
                                    {t("Date")}
                                  </th>
                                  <th>{t("Request name")}</th>
                                  <th style={{ width: "100px" }}>
                                    {t("Price")}
                                  </th>
                                  <th style={{ width: "100px" }}>
                                    {t("Paid")}
                                  </th>
                                  <th
                                    style={{
                                      width: "160px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {t("Status")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{list}</tbody>
                            </Table>
                          </div>
                        )}
                        {list.length === 0 && (
                          <h5 className="text-center m-0">
                            {t("No update requests found")}
                          </h5>
                        )}
                      </>
                    )}

                    {loadingRequests && (
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </div>
                  <div className="w-100 text-center mt-4">
                    <Link to="/tickets?form=open" className="btn btn-primary">
                      {t("Create request")}
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={requestModal}
        toggle={() => {
          closeDetail()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t("Update request")}
          </h5>
          <button
            type="button"
            onClick={() => {
              closeDetail()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {!loadingRequestDetail && (
          <div className="modal-body">
            <Row className="p-2">
              <label className="col-4 request-detail-label">
                {t("Date of creation")}
              </label>
              <div className="col-8 ps-2 pe-2">
                <p className="m-0">
                  {dateLong(new Date(requestDetail?.created_at))}
                </p>
              </div>
            </Row>
            <Row className="p-2">
              <label className="col-4 request-detail-label">{t("Name")}</label>
              <div className="col-8 ps-2 pe-2">
                <p className="m-0">{requestDetail?.name}</p>
              </div>
            </Row>
            <Row className="p-2">
              <label className="col-4 request-detail-label">
                {t("Description")}
              </label>
              <div className="col-8 ps-2 pe-2">
                <p className="m-0">{requestDetail?.request}</p>
              </div>
            </Row>
            <Row className="p-2">
              <label className="col-4 request-detail-label">{t("Price")}</label>
              <div className="col-8 ps-2 pe-2">
                <p className="m-0">{requestDetail?.price.toFixed(2)} Kč</p>
              </div>
            </Row>
            <Row className="p-2">
              <label className="col-4 request-detail-label d-flex align-items-center">
                {t("Paid")}
              </label>
              <div className="col-8 ps-2 pe-2">
                <p className="m-0">
                  <Badge
                    style={{ fontSize: "12px" }}
                    className={`${
                      requestDetail?.paid
                        ? "badge-soft-success"
                        : "badge-soft-danger"
                    } p-2`}
                  >
                    {requestDetail?.paid ? t("Yes") : t("No")}
                  </Badge>
                </p>
              </div>
            </Row>
            <Row className="p-2">
              <label className="col-4 request-detail-label d-flex align-items-center">
                {t("Status")}
              </label>
              <div className="col-8 ps-2 pe-2">
                {getStatusBadge(requestDetail?.status)}
              </div>
            </Row>
            <Row className="p-2">
              <label className="col-4 request-detail-label d-flex align-items-center">
                {t("Ticket")}
              </label>
              <div className="col-8 ps-2 pe-2">
                {requestDetail?.ticket && (
                  <Link
                    to={`/tickets?ticket=${requestDetail?.ticket.id}`}
                    className="btn btn-primary btn-sm"
                  >
                    {t("Open ticket")}
                  </Link>
                )}
              </div>
            </Row>
          </div>
        )}
        {loadingRequestDetail && (
          <div
            className="w-100 d-flex justify-content-center"
            style={{ height: "300px", paddingTop: "140px" }}
          >
            <Spinner className="ms-2" color="primary" />
          </div>
        )}
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              closeDetail()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            {t("Close")}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default withTranslation("requests")(Requests)
