import React, { useEffect, useState, useCallback, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
  Input,
  UncontrolledAlert,
  Label,
  UncontrolledAccordion,
  AccordionItem,
  FormFeedback,
  Spinner,
  CardImg,
  Badge,
  Modal,
  ModalBody,
  Alert,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select"
import { debounce } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import CustomizationFormProgress from "components/Customization/CustomizationFormProgress"
import InputSearch from "components/Forms/InputSearch"

//Import Actions
import {
  getTemplateDesigns,
  selectTemplateDesign,
  removeTemplate,
  getAccountData,
  skipTemplate,
  updateAccountDataAfterSkip,
  resetSkippedTemplate,
} from "store/actions"

//Import Images
import newTab from "../../assets/images/icons/icons8-external-link.svg"
import searchIcon from "../../assets/images/icons/icons8-search.svg"
import crossIcon from "../../assets/images/icons/icons8-cross.svg"
import backIcon from "../../assets/images/icons/icons8-back.svg"
import nextIcon from "../../assets/images/icons/icons8-forward.svg"
import doubleBackIcon from "../../assets/images/icons/icons8-double-left.svg"

//redux
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"

//i18n
import { withTranslation } from "react-i18next"

const SelectDesign = ({ t }) => {
  //meta title
  document.title = t("Title Select Design Template")

  const dispatch = useDispatch()
  const listRef = useRef(null)
  const navigate = useNavigate()

  const customizationData = createSelector(
    state => state.customization,
    customizationData => {
      return {
        loadingTemplates: customizationData.loadingTemplates,
        templates: customizationData.templates,
        selectTemplateError: customizationData.selectTemplateError,
        selectedTemplate: customizationData.selectedTemplate,
        selectTemplateLoading: customizationData.selectTemplateLoading,
        categories: customizationData.categories,
        numOfResults: customizationData.numOfResults,
        removeTemplateLoading: customizationData.removeTemplateLoading,
        skipTemplateLoading: customizationData.skipTemplateLoading,
        skippedTemplate: customizationData.skippedTemplate,
      }
    }
  )

  const {
    loadingTemplates,
    templates,
    selectTemplateError,
    selectedTemplate,
    selectTemplateLoading,
    categories,
    numOfResults,
    removeTemplateLoading,
    skipTemplateLoading,
    skippedTemplate,
  } = useSelector(customizationData)

  const accountData = createSelector(
    state => state.account,
    accountData => {
      return {
        project: accountData.project,
      }
    }
  )

  const { project } = useSelector(accountData)

  const [category, setCategory] = useState({
    label: t("All categories"),
    value: "All",
  })
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  // redirect if project status is not "waiting" or "new"
  useEffect(() => {
    if (project?.status !== "waiting" && project?.status !== "new") {
      navigate("/dashboard")
    }
  }, [project])

  const limit = 20
  const offset = (page - 1) * limit
  const maxPage = Math.ceil(numOfResults / limit)

  const onSelect = id => {
    if (selectTemplateLoading) return
    dispatch(selectTemplateDesign(id))
  }

  // get templates data on load
  // useEffect(() => {
  //   dispatch(getTemplateDesigns(category.value))
  // }, [])

  // get templates data on load and update UI when selected template changes
  useEffect(() => {
    if (!selectTemplateLoading && !removeTemplateLoading) {
      dispatch(getTemplateDesigns({ category: category.value, search, offset }))
      window.scrollTo(0, 0)
    }
  }, [selectTemplateLoading, removeTemplateLoading])

  // handle Category selection
  const categoryNames = {
    Accounting: t("Accounting", { ns: "shared" }),
    Agency: t("Agency", { ns: "shared" }),
    Agriculture: t("Agriculture", { ns: "shared" }),
    App: t("App", { ns: "shared" }),
    Blog: t("Blog", { ns: "shared" }),
    Book: t("Book", { ns: "shared" }),
    Business: t("Business", { ns: "shared" }),
    Consulting: t("Consulting", { ns: "shared" }),
    Designer: t("Designer", { ns: "shared" }),
    Finance: t("Finance", { ns: "shared" }),
    Health: t("Health", { ns: "shared" }),
    Hotel: t("Hotel", { ns: "shared" }),
    "Landing page": t("Landing page", { ns: "shared" }),
    Logistics: t("Logistics", { ns: "shared" }),
    Marketing: t("Marketing", { ns: "shared" }),
    News: t("News", { ns: "shared" }),
    Photography: t("Photography", { ns: "shared" }),
    Portfolio: t("Portfolio", { ns: "shared" }),
    "Real estate": t("Real estate", { ns: "shared" }),
    Restaurant: t("Restaurant", { ns: "shared" }),
    SaaS: t("SaaS", { ns: "shared" }),
    School: t("School", { ns: "shared" }),
    Therapy: t("Therapy", { ns: "shared" }),
  }

  // Section selection
  let categoryOptions = categories.map(cat => {
    return { label: categoryNames[cat], value: cat }
  })

  function compare(a, b) {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  }

  categoryOptions.sort(compare)

  categoryOptions.unshift({
    label: t("All categories"),
    value: "All",
  })

  const handleSelectCategory = selected => {
    setPage(1)
    setCategory(selected)
    dispatch(getTemplateDesigns({ category: selected.value, search, offset }))
  }

  // handle Search
  const updateSearch = text => {
    setPage(1)

    dispatch(
      getTemplateDesigns({
        category: category.value,
        search: text,
        offset,
      })
    )
  }
  const updateSearchDebounced = useCallback(debounce(updateSearch, 1000), [])

  const onSearchChange = e => {
    setSearch(e.target.value)

    updateSearchDebounced(e.target.value)
  }

  const deleteSearch = () => {
    setSearch("")

    updateSearch("")
  }

  // handle Pagination
  const onPagiClick = clicked => {
    switch (clicked) {
      case "first":
        if (page > 2) setPage(1)
        break
      case "previous":
        if (page > 1) setPage(page - 1)
        break
      case "next":
        if (page < maxPage) setPage(page + 1)
        break
    }

    //window.scrollTo(0, 0)
    listRef.current.scrollIntoView({
      behavior: "smooth",
    })
  }

  useEffect(() => {
    dispatch(getTemplateDesigns({ category: category.value, search, offset }))
  }, [page])

  // handle selected template removal
  const onRemoveTemplate = () => {
    dispatch(removeTemplate())
  }

  useEffect(() => {
    if (!removeTemplateLoading) {
      dispatch(getAccountData())
    }
  }, [removeTemplateLoading])

  // handle skipping template
  const onSkipTemplate = () => {
    if (!skipTemplateLoading) {
      dispatch(skipTemplate())
    }
  }

  useEffect(() => {
    if (!skipTemplateLoading && skippedTemplate) {
      dispatch(updateAccountDataAfterSkip())
      dispatch(resetSkippedTemplate())
      navigate("/submit-data")
    }
  }, [skipTemplateLoading])

  const list = templates.map(template => {
    return (
      <div key={template.id} className="d-flex flex-column mt-3 mb-3 mw-100">
        <a
          href={template.preview_url}
          target="_blank"
          rel="noreferrer"
          style={{ border: "1px solid #ebebeb" }}
        >
          <img
            src={template.image}
            alt={template.name}
            style={{ maxWidth: "100%" }}
          />
        </a>
        <div className="d-flex justify-content-between mt-3">
          <h2>{template.name}</h2>
          <div>
            <a
              href={template.preview_url}
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              {t("Preview")}{" "}
              <img src={newTab} alt="Open new tab" style={{ width: "15px" }} />
            </a>
          </div>
        </div>
        <div className="select-design-template-content-bottom">
          <div className="d-flex select-design-tabs-flex flex-wrap">
            {template.template_tags.map(tag => {
              const bg = tag.tag.tag !== "CMS" ? "bg-light" : "bg-danger"
              const color = tag.tag.tag !== "CMS" ? "#343a40" : "#fff"

              return (
                <div
                  key={tag.tag.tag}
                  className={`${bg} ps-2 pe-2 d-flex align-items-center`}
                  style={{ color, height: "40px" }}
                >
                  {tag.tag.tag}
                </div>
              )
            })}
          </div>
          <div>
            <Button
              color="primary"
              className="btn btn-primary waves-effect waves-light mt-1"
              onClick={() => onSelect(template.id)}
            >
              {t("Select")}
            </Button>
          </div>
        </div>
      </div>
    )
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={t("Dashboard")}
            titleLink="/dashboard"
            breadcrumbItems={[{ link: "", item: t("Design Template") }]}
          />
          <Row>
            <Col xl={8}>
              {selectedTemplate && (
                <Card>
                  <Row className="no-gutters align-items-strech h-100">
                    <Col xl={6} className="overflow-hidden">
                      <CardImg
                        className="img-fluid cardimg-template-preview"
                        src={selectedTemplate.image}
                        alt="Certified Pixel"
                      />
                    </Col>
                    <Col xl={6}>
                      <CardBody className="h-100">
                        <div className="d-flex flex-column justify-content-between h-100">
                          <div className="d-flex flex-column">
                            <CardTitle>{t("Website design")}</CardTitle>
                            <CardText className="mt-3">
                              {t("Your currently selected design")}:{" "}
                              <span style={{ fontWeight: 500 }}>
                                {selectedTemplate.name}
                              </span>
                            </CardText>
                            <CardText>
                              <a
                                href={selectedTemplate.preview_url}
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary"
                              >
                                {t("Open a preview of selected design here")}{" "}
                                <img
                                  src={newTab}
                                  alt="Open new tab"
                                  style={{ width: "15px" }}
                                />
                              </a>
                            </CardText>
                            <CardText>
                              {t("Selected design template info")}
                            </CardText>
                            <Link to="/submit-data">
                              <Button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light mt-1"
                              >
                                {t("Submit project data")}
                              </Button>
                            </Link>
                            <Alert
                              color="success"
                              role="alert"
                              className="mt-3"
                            >
                              <i className="mdi mdi-autorenew me-2"></i>
                              {t("You can still make a change")}
                            </Alert>
                            <CardText>
                              <Button
                                color="secondary"
                                className="btn btn-primary waves-effect waves-light mt-1"
                                onClick={() => onRemoveTemplate()}
                                style={{ width: "190px" }}
                              >
                                {removeTemplateLoading && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    style={{ marginBottom: "-2px" }}
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                                {!removeTemplateLoading &&
                                  t("Remove selected design")}
                              </Button>
                            </CardText>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              )}
              <div ref={listRef}>
                <Card className="flex-column ">
                  <CardBody>
                    <CardTitle>{t("Select design template")}</CardTitle>
                    <CardText className="mt-3">
                      {t("Select design template paragraph 1")}
                    </CardText>
                    <CardText>
                      {t("Select design template paragraph 2")}
                    </CardText>
                    {project?.using_template === null && (
                      <CardText style={{ marginBottom: "-39px" }}>
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light mt-3"
                          onClick={() => onSkipTemplate()}
                          style={{ width: "165px" }}
                        >
                          {skipTemplateLoading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              style={{ marginBottom: "-2px" }}
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          {!skipTemplateLoading && t("Dont use a template")}
                        </Button>
                      </CardText>
                    )}
                    <div className="w-100 d-flex mt-5 justify-content-between flex-column flex-sm-row">
                      <div style={{ width: "200px" }}>
                        <Select
                          className="select2-selection w-100"
                          value={category}
                          onChange={handleSelectCategory}
                          options={categoryOptions}
                        />
                      </div>
                      <div className="mt-2 mt-sm-0">
                        <InputSearch
                          type="text"
                          name="search"
                          value={search}
                          onChange={onSearchChange}
                          width="200px"
                          placeholder={t("Search")}
                          icon={search === "" ? searchIcon : crossIcon}
                          onIconClick={deleteSearch}
                        />
                      </div>
                      {/* <Input
                      className="form-control w-25"
                      type="text"
                      placeholder="Search template"
                      value={search}
                      onChange={onSearchChange}
                      maxLength={30}
                    /> */}
                    </div>
                    {category?.value !== "All" && (
                      <Alert color="info" role="alert" className="mt-3">
                        <i className="mdi mdi-information-outline me-2"></i>
                        {t(
                          "The division into categories is merely a guideline"
                        )}
                      </Alert>
                    )}
                    {selectTemplateError && (
                      <UncontrolledAlert
                        color="danger"
                        role="alert"
                        style={{
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        {selectTemplateError}
                      </UncontrolledAlert>
                    )}
                    {/* {!selectTemplateLoading && (
                      <div
                        className="d-flex mt-4 select-design-templates-flex flex-wrap"
                        style={{ opacity: !loadingTemplates ? 1 : 0.5 }}
                      >
                        {list}
                      </div>
                    )} */}
                    <div
                      className="d-flex mt-4 select-design-templates-flex flex-wrap"
                      style={{
                        opacity:
                          !loadingTemplates && !selectTemplateLoading ? 1 : 0.5,
                      }}
                    >
                      {list}
                    </div>
                    {(loadingTemplates || selectTemplateLoading) &&
                      templates.length === 0 && (
                        <div
                          className="w-100 d-flex justify-content-center align-items-center"
                          style={{ height: "500px" }}
                        >
                          <Spinner className="ms-2" color="primary" />
                        </div>
                      )}
                    {numOfResults === 0 && !loadingTemplates && (
                      <Alert color="warning" className="mb-5">
                        No templates found for selected criteria.
                      </Alert>
                    )}
                    {!loadingTemplates && numOfResults > limit && (
                      <div className="w-100 d-flex justify-content-center mt-3 mb-3">
                        {page > 2 && (
                          <Button
                            color="info"
                            className="btn btn-primary waves-effect waves-light me-1"
                            onClick={() => onPagiClick("first")}
                          >
                            <img
                              src={doubleBackIcon}
                              alt="Back"
                              style={{ width: "15px" }}
                            />{" "}
                            First
                          </Button>
                        )}
                        {page > 1 && (
                          <Button
                            color="info"
                            className="btn btn-primary waves-effect waves-light ms-1 me-1"
                            onClick={() => onPagiClick("previous")}
                          >
                            <img
                              src={backIcon}
                              alt="Back"
                              style={{ width: "15px" }}
                            />{" "}
                            Previous
                          </Button>
                        )}
                        {page < maxPage && (
                          <Button
                            color="info"
                            className="btn btn-primary waves-effect waves-light ms-1"
                            onClick={() => onPagiClick("next")}
                          >
                            Next{" "}
                            <img
                              src={nextIcon}
                              alt="Next"
                              style={{ width: "15px" }}
                            />
                          </Button>
                        )}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xl={4}>
              <CustomizationFormProgress active={1} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation("customization")(SelectDesign)
