//AUTH
export const POST_AUTH = "/auth"
export const POST_ADMIN_AUTH = "/auth-admin"

//LOGIN
export const POST_LOGIN = "/login"
export const REQUEST_RESET = "/request-reset"
export const VERIFY_RESET_LINK = "/verify-reset-link"
export const RESET_PASSWORD = "/reset-password"

//ACCOUNT
export const ACCOUNT_DATA = "/account-data"
export const ADMIN_DATA = "/account-data/admin"
export const GET_SETTINGS_DATA = "/account-data/get-settings-data"
export const GET_ADMIN_SETTINGS_DATA = "/account-data/get-admin-settings-data"
export const CHANGE_PASSWORD = "/account-data/change-password"
export const UPDATE_NOTIFICATIONS = "/account-data/update-notifications"
export const GET_BILLING_DATA = "/account-data/get-billing-data"
export const CANCEL_SUBSCRIPTION = "/account-data/cancel-subscription"

//CUSTOMIZATION
export const GET_CUSTOMIZATION = "/customization/get-customization-data"
export const POST_CUSTOMIZATION = "/customization/save-customization-data"
export const POST_ADD_SECTION = "/customization/add-section"
export const POST_SECTIONS = "/customization/save-sections-data"
export const POST_ADD_PART = "/customization/add-part"
export const POST_REMOVE_PART = "/customization/remove-part"
export const POST_REMOVE_SECTION = "/customization/remove-section"
export const POST_FINISH_SUBMISSION = "/customization/finish-submission"
export const GET_TEMPLATE_DESIGNS = "/customization/get-template-designs"
export const SELECT_TEMPLATE_DESIGN = "/customization/select-template-design"
export const SKIP_TEMPLATE = "/customization/skip-template"
export const REMOVE_TEMPLATE = "/customization/remove-template"

//TICKETS
export const GET_TICKET_LIST = "/tickets/ticket-list"
export const GET_AGENT_TICKET_LIST = "/tickets-admin/ticket-list"
export const GET_TICKET_DETAIL = "/tickets/ticket-detail"
export const GET_AGENT_TICKET_DETAIL = "/tickets-admin/ticket-detail"
export const POST_SUBMIT_MESSAGE = "/tickets/submit-message"
export const POST_AGENT_SUBMIT_MESSAGE = "/tickets-admin/submit-message"
export const POST_TOGGLE_STAR = "/tickets/toggle-star"
export const POST_AGENT_TOGGLE_STAR = "/tickets-admin/toggle-star"
export const POST_DELETE_TICKETS = "/tickets/delete-tickets"
export const POST_AGENT_DELETE_TICKETS = "/tickets-admin/delete-tickets"
export const GET_ALL_ACCOUNTS = "/tickets-admin/all-accounts"
export const POST_CLOSE_TICKET = "/tickets-admin/close-ticket"
export const POST_CHANGE_TICKET_LABEL = "/tickets-admin/change-ticket-label"
export const GET_UNREAD_COUNT = "/tickets/get-unread-count"

//FILE UPLOAD
export const POST_UPLOAD_FILE = "/upload"
export const POST_UPLOAD_FILE_WEBFLOW = "/upload/webflow"

//TEMPLATES
export const GET_TEMPLATES = "/management/get-templates"
export const GET_TEMPLATE_DATA = "/management/get-template-data"
export const POST_UPDATE_TEMPLATE = "/management/update-template"
export const POST_ADD_TEMPLATE = "/management/add-template"
export const GET_WEBFLOW_SYNC = "/management/sync-templates-webflow"

//ADMIN
export const GET_PAYMENT_REQUESTS_PROFORMA_DATA =
  "/admin/get-payment-requests-proforma-data"
export const POST_MARK_PROFORMA_PAID = "/admin/mark-proforma-paid"
export const POST_CREATE_ACCOUNT = "/admin/create-account-manually"

//REQUESTS
export const GET_REQUESTS = "/requests/get-requests-data"
export const GET_REQUEST_DETAIL = "/requests/get-request-detail"
