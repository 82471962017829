import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Import Language dropdown
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { verifyResetLink, resetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo/logo-symbol-black-space.png"

//i18n
import { withTranslation } from "react-i18next"

const ResetPasswordPage = ({ t }) => {
  //meta title
  document.title = "Reset Password | Back-office | Certified Pixel"

  const dispatch = useDispatch()

  const forgotPasswordData = createSelector(
    state => state.ForgetPassword,
    forgotPassword => ({
      verifyLoading: forgotPassword.verifyLoading,
      verifyError: forgotPassword.verifyError,
      resetLoading: forgotPassword.resetLoading,
      resetError: forgotPassword.resetError,
      resetSuccess: forgotPassword.resetSuccess,
    })
  )

  const { verifyLoading, verifyError, resetLoading, resetError, resetSuccess } =
    useSelector(forgotPasswordData)

  const [codes, setCodes] = useState(null)

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t("Please enter new password"))
        .min(8, t("Password must be at least 8 characters long"))
        .matches(/[a-z]+/, t("Password must include at least one lowercase"))
        .matches(/[A-Z]+/, t("Password must include at least one uppercase"))
        .matches(/^\S*$/, t("Whitespace in password is not allowed"))
        .matches(
          /[0123456789@$!%*#?&_-]+/,
          t("Password must include at least")
        ),
      passwordConfirm: Yup.string()
        .required(t("Please enter the new password again"))
        .oneOf([Yup.ref("password"), null], t("Passwords do not match")),
    }),
    onSubmit: values => {
      dispatch(resetPassword({ password: values.password, codes }))
    },
  })

  // logout on load
  useEffect(() => {
    localStorage.removeItem("authUser")
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const x = urlParams.get("x")
    const y = urlParams.get("y")

    setCodes({ x, y })

    dispatch(verifyResetLink(x, y))
  }, [])

  useEffect(() => {
    if (resetSuccess) form.resetForm()
  }, [resetSuccess])

  return (
    <React.Fragment>
      <div className="home-btn">
        <LanguageDropdown />
      </div>
      <div className="account-pages my-5 pt-4 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t("Reset Password")}</h5>
                        <p>
                          {t("Create new password to your back office account")}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {!verifyError && (
                      <>
                        <p>
                          {t("Enter new password. It must contain at least")}
                        </p>
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            form.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">
                              {t("New password")}
                            </Label>
                            <Input
                              name="password"
                              className="form-control"
                              placeholder={t("Enter new password")}
                              type="password"
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              value={form.values.password || ""}
                              invalid={
                                form.touched.password && form.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {form.touched.password && form.errors.password ? (
                              <FormFeedback type="invalid">
                                {form.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              {t("Confirm new password")}
                            </Label>
                            <Input
                              name="passwordConfirm"
                              className="form-control"
                              placeholder={t("Enter new password again")}
                              type="password"
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              value={form.values.passwordConfirm || ""}
                              invalid={
                                form.touched.passwordConfirm &&
                                form.errors.passwordConfirm
                                  ? true
                                  : false
                              }
                            />
                            {form.touched.passwordConfirm &&
                            form.errors.passwordConfirm ? (
                              <FormFeedback type="invalid">
                                {form.errors.passwordConfirm}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-primary w-md "
                                type="submit"
                              >
                                {resetLoading && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    style={{ marginBottom: "-2px" }}
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                                {!resetLoading && t("Save")}
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    )}
                    {verifyError && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(verifyError)}
                      </Alert>
                    )}
                    {resetError && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(resetError)}
                      </Alert>
                    )}
                    {resetSuccess && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {t("Your password has been successfully changed")}
                      </Alert>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("Go back to")}{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {t("Login")}
                  </Link>
                </p>
                <p>© {new Date().getFullYear()} Certified Pixel</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation("authentication")(withRouter(ResetPasswordPage))
