import { takeEvery, call, put } from "redux-saga/effects"

import {
  GET_TICKET_LIST,
  GET_TICKET_DETAIL,
  UPLOAD_ATTACHMENT,
  SUBMIT_MESSAGE,
  TOGGLE_STAR,
  DELETE_TICKETS,
  GET_ALL_ACCOUNTS,
  CLOSE_TICKET,
  CHANGE_TICKET_LABEL,
  GET_UNREAD_COUNT,
} from "./actionTypes"

import {
  getTicketList,
  getAgentTicketList,
  getTicketDetail,
  getAgentTicketDetail,
  postSubmitMessage,
  postAgentSubmitMessage,
  postToggleStar,
  postAgentToggleStar,
  postDeleteTickets,
  postAgentDeleteTickets,
  getAllAccounts,
  postCloseTicket,
  postUpdateTicketLabel,
  getUnreadCount,
} from "../../helpers/api_requests"

import {
  getTicketListSuccess,
  getTicketListFailed,
  getTicketDetailFailed,
  getTicketDetailSuccess,
  uploadAttachmentFailed,
  uploadAttachmentSuccess,
  submitMessageFailed,
  submitMessageSuccess,
  fetchTicketList,
  addMessage,
  getAllAccountsSuccess,
  closeTicketSuccess,
  closeTicketFailed,
  getUnreadCountSuccess,
} from "./actions"

import { postUploadFile } from "../../helpers/api_requests"

function* onGetTicketList({ payload: { tab, label } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))

  if (!user) {
    yield put(getTicketListFailed())
    return
  }

  const apiRequest = user.agent ? getAgentTicketList : getTicketList

  const response = yield call(apiRequest, { tab, label })
  if (response.error) {
    yield put(getTicketListFailed())
  } else {
    yield put(getTicketListSuccess(response))
  }
}

function* onGetTicketDetail({ payload: { id } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))

  if (!user) {
    yield put(getTicketDetailFailed())
    return
  }

  const apiRequest = user.agent ? getAgentTicketDetail : getTicketDetail

  const response = yield call(apiRequest, { id })
  if (response.error) {
    yield put(getTicketDetailFailed())
  } else {
    yield put(getTicketDetailSuccess(response))
  }
}

function* onUploadAttachment({ payload: { file } }) {
  const response = yield call(postUploadFile, file)

  if (response.error) {
    yield put(uploadAttachmentFailed(response.error))
  } else if (response.fileName) {
    yield put(uploadAttachmentSuccess(response.fileName, response.originalName))
  }
}

function* onSubmitMessage({
  payload: { account, ticketId, type, subject, message, attachments, close },
}) {
  try {
    const user = JSON.parse(localStorage.getItem("authUser"))

    if (!user) {
      yield put(submitMessageFailed())
      return
    }

    const response = user.agent
      ? yield call(postAgentSubmitMessage, {
          account,
          ticketId,
          type,
          subject,
          message,
          attachments,
          close,
        })
      : yield call(postSubmitMessage, {
          ticketId,
          type,
          subject,
          message,
          attachments,
        })

    if (response.error) {
      yield put(submitMessageFailed(response.error))
    } else {
      yield put(submitMessageSuccess(close))
      yield put(fetchTicketList(1, "none"))

      if (response.message) {
        yield put(addMessage(ticketId, response.message))
      }
    }
  } catch (err) {
    yield put(submitMessageFailed(err.message))
  }
}

function* onToggleStar({ payload: { id, star } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))

  if (!user) {
    return
  }

  const apiRequest = user.agent ? postAgentToggleStar : postToggleStar

  yield call(apiRequest, { id, star })
}

function* onDeleteTickets({ payload }) {
  const user = JSON.parse(localStorage.getItem("authUser"))

  if (!user) {
    return
  }

  const apiRequest = user.agent ? postAgentDeleteTickets : postDeleteTickets

  yield call(apiRequest, { tickets: payload })
}

function* onGetAllAccounts() {
  const response = yield call(getAllAccounts)

  if (response.error) {
  } else {
    yield put(getAllAccountsSuccess(response.data))
  }
}

function* onCloseTicket({ payload }) {
  try {
    const response = yield call(postCloseTicket, { id: payload })

    if (response.error) {
      yield put(closeTicketFailed())
    } else {
      yield put(closeTicketSuccess())
      yield put(fetchTicketList(1, "none"))
    }
  } catch (err) {
    yield put(closeTicketFailed(closeTicketFailed))
  }
}

function* onUpdateTicketLabel({ payload: { ticketId, label } }) {
  yield call(postUpdateTicketLabel, { id: ticketId, label })
}

function* onGetUnreadCount() {
  try {
    const response = yield call(getUnreadCount)

    if (!response.error) {
      yield put(getUnreadCountSuccess(response))
    }
  } catch {}
}

function* ticketsSaga() {
  yield takeEvery(GET_TICKET_LIST, onGetTicketList)
  yield takeEvery(GET_TICKET_DETAIL, onGetTicketDetail)
  yield takeEvery(UPLOAD_ATTACHMENT, onUploadAttachment)
  yield takeEvery(SUBMIT_MESSAGE, onSubmitMessage)
  yield takeEvery(TOGGLE_STAR, onToggleStar)
  yield takeEvery(DELETE_TICKETS, onDeleteTickets)
  yield takeEvery(GET_ALL_ACCOUNTS, onGetAllAccounts)
  yield takeEvery(CLOSE_TICKET, onCloseTicket)
  yield takeEvery(CHANGE_TICKET_LABEL, onUpdateTicketLabel)
  yield takeEvery(GET_UNREAD_COUNT, onGetUnreadCount)
}

export default ticketsSaga
