import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import dashboardCZ from "./locales/cz/dashboard.json"
import customizationCZ from "./locales/cz/customization.json"
import sharedCZ from "./locales/cz/shared.json"
import menuCZ from "./locales/cz/menu.json"
import settingsCZ from "./locales/cz/settings.json"
import authenticationCZ from "./locales/cz/authentication.json"
import ticketsCZ from "./locales/cz/tickets.json"
import requestsCZ from "./locales/cz/requests.json"
import dashboardENG from "./locales/eng/dashboard.json"
import customizationENG from "./locales/eng/customization.json"
import sharedENG from "./locales/eng/shared.json"
import menuENG from "./locales/eng/menu.json"
import settingsENG from "./locales/eng/settings.json"
import authenticationENG from "./locales/eng/authentication.json"
import ticketsENG from "./locales/eng/tickets.json"
import requestsENG from "./locales/eng/requests.json"

// the translations
const resources = {
  cz: {
    dashboard: dashboardCZ,
    customization: customizationCZ,
    shared: sharedCZ,
    menu: menuCZ,
    settings: settingsCZ,
    authentication: authenticationCZ,
    tickets: ticketsCZ,
    requests: requestsCZ,
  },
  en: {
    dashboard: dashboardENG,
    customization: customizationENG,
    shared: sharedENG,
    menu: menuENG,
    settings: settingsENG,
    authentication: authenticationENG,
    tickets: ticketsENG,
    requests: requestsENG,
  },
}

// check for language preference in URL
const urlParams = new URLSearchParams(window.location.search)
const urlLang = urlParams.get("lang")

if (["en", "cz"].includes(urlLang)) {
  console.log("language in url found", urlLang)
  localStorage.setItem("I18N_LANGUAGE", urlLang)
} else {
  const language = localStorage.getItem("I18N_LANGUAGE")

  // set default language if not saved in local storage
  if (!language) {
    localStorage.setItem("I18N_LANGUAGE", "en")
    //localStorage.setItem("I18N_LANGUAGE", "en")
  }
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: [
      "dashboard",
      "customization",
      "shared",
      "menu",
      "settings",
      "authentication",
      "tickets",
      "requests",
    ],
    defaultNS: "dashboard",
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    //lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "en", // use en if detected lng is not available
    returnObjects: true,
    keySeparator: ".", // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
