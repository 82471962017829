export const GET_TEMPLATES = "GET_TEMPLATES"
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS"
export const GET_TEMPLATES_FAILED = "GET_TEMPLATES_FAILED"
export const GET_TEMPLATE_DATA = "GET_TEMPLATE_DATA"
export const GET_TEMPLATE_DATA_SUCCESS = "GET_TEMPLATE_DATA_SUCCESS"
export const GET_TEMPLATE_DATA_FAILED = "GET_TEMPLATE_DATA_FAILED"
export const UPLOAD_IMAGE = "UPLOAD_IMAGE"
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS"
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED"
export const REMOVE_IMAGE = "REMOVE_IMAGE"
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE"
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS"
export const UPDATE_TEMPLATE_FAILED = "UPDATE_TEMPLATE_FAILED"
export const ADD_TEMPLATE = "ADD_TEMPLATE"
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS"
export const ADD_TEMPLATE_FAILED = "ADD_TEMPLATE_FAILED"
export const RESET_TEMPLATE_ADDED = "RESET_TEMPLATE_ADDED"
export const WEBFLOW_SYNC = "WEBFLOW_SYNC"
export const WEBFLOW_SYNC_SUCCESS = "WEBFLOW_SYNC_SUCCESS"
export const WEBFLOW_SYNC_FAILED = "WEBFLOW_SYNC_FAILED"
